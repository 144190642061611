<template>
  <div class="appView tasks purpleView">
    <Details v-if="$route.params.id"/>
    <List/>
    <img :src="require('@/assets/img/cat_dance.gif')" class="taskSuccessAnimation" :class="animClass" v-if="showAnim"/>
  </div>
</template>

<script>
import List from "./List.vue";
import Details from "@/routing/core/Tasks/Details.vue";

export default {
  name: 'TasksOverview',
  data() {
    return {
      tasks: [],
      animClass: '',
      showAnim: false,
      debounceTimerChecked: null,
      debounceTimerUnchecked: null,
    }
  },
  components: {
    Details,
    List
  },
  methods: {
    showTaskAnimation() {
      this.showAnim = true
      setTimeout(() => {
        this.animClass = 'active';
      }, 100);
      setTimeout(() => {
        this.animClass = '';
        this.showAnim = false; // Ensure the animation state is reset
      }, 2500);
    },
    getTaskCountData() {
      const defaultData = { count: 0, lastUpdated: new Date().toDateString() };
      const storedData = localStorage.getItem('taskCheckedCountData');
      return storedData ? JSON.parse(storedData) : defaultData;
    },
    updateTaskCountData(count) {
      const data = JSON.stringify({ count: count, lastUpdated: new Date().toDateString() });
      localStorage.setItem('taskCheckedCountData', data);
    },
    isNewDay(lastUpdated) {
      const today = new Date().toDateString();
      return lastUpdated !== today;
    },
    incrementTaskCount() {
      const data = this.getTaskCountData();
      if (this.isNewDay(data.lastUpdated)) {
        data.count = 0;
      }
      data.count++;
      this.updateTaskCountData(data.count);
      this.checkCount(data.count);
    },
    decrementTaskCount() {
      const data = this.getTaskCountData();
      if (this.isNewDay(data.lastUpdated)) {
        data.count = 0;
      }
      data.count = Math.max(data.count - 1, 0);
      this.updateTaskCountData(data.count);
      this.checkCount(data.count);
    },
    handleTaskChecked() {
      clearTimeout(this.debounceTimerChecked);
      this.debounceTimerChecked = setTimeout(() => {
        this.incrementTaskCount();
      }, 300);
    },
    handleTaskUnchecked() {
      clearTimeout(this.debounceTimerUnchecked);
      this.debounceTimerUnchecked = setTimeout(() => {
        this.decrementTaskCount();
      }, 300);
    },
    checkCount(currentCount) {
      if (currentCount === 10) {
        this.updateTaskCountData(0); // Reset the count after showing animation
        this.showTaskAnimation();
      }
    },
  },
  mounted() {
    const data = this.getTaskCountData();
    if (this.isNewDay(data.lastUpdated)) {
      this.updateTaskCountData(0); // Reset if it's a new day
    }
    this.checkCount(data.count);
    window.addEventListener('task-checked', this.handleTaskChecked);
    window.addEventListener('task-unchecked', this.handleTaskUnchecked);
  },
  beforeUnmount() {
    window.removeEventListener('task-checked', this.handleTaskChecked);
    window.removeEventListener('task-unchecked', this.handleTaskUnchecked);
    clearTimeout(this.debounceTimerChecked);
    clearTimeout(this.debounceTimerUnchecked);
  },
}
</script>
