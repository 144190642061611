<template>
  <template v-if="$route && $route.matched && $route.matched.length">


    <Modal v-if="modalsList.mail">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <component :is="'Mail'"/>
        </div>
      </div>
    </Modal>

    <template v-if="$route.matched[0].name === 'customers'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.customer')" position="left"
              @click="$store.commit('openModal', 'customer'); $store.state.activeItems.customer = {}"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.customer">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'Edit'" />
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigAccountingKeys'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigAccountingKeys')" position="left"
              @click="$store.commit('openModal', 'ConfigAccountingKeys'); $store.state.activeItems.ConfigAccountingKeys = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigAccountingKeys">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigAccountingKeys'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigBankAccounts'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigBankAccounts')" position="left"
              @click="$store.commit('openModal', 'ConfigBankAccounts'); $store.state.activeItems.ConfigBankAccounts = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigBankAccounts">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigBankAccounts'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCostNumbers'">
      <button class="btn btn-outline-primary red btn-sm me-3" :tooltip="$t('add.ConfigCostNumbers')" position="left"
              @click="$store.commit('openModal', 'ConfigCostNumbers'); $store.state.activeItems.ConfigCostNumbers = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCostNumbers">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCostNumbers'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCustomerTaxes'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCustomerTaxes')" position="left"
              @click="$store.commit('openModal', 'ConfigCustomerTaxes'); $store.state.activeItems.ConfigCustomerTaxes = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCustomerTaxes">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCustomerTaxes'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCustomerAcquisitions'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCustomerAcquisitions')"
              position="left"
              @click="$store.commit('openModal', 'ConfigCustomerAcquisitions'); $store.state.activeItems.ConfigCustomerAcquisitions = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCustomerAcquisitions">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCustomerAcquisitions'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCustomerState'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCustomerState')" position="left"
              @click="$store.commit('openModal', 'ConfigCustomerState'); $store.state.activeItems.ConfigCustomerState = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCustomerState">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCustomerState'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCustomerPrio'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCustomerPrio')" position="left"
              @click="$store.commit('openModal', 'ConfigCustomerPrio'); $store.state.activeItems.ConfigCustomerPrio = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCustomerPrio">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCustomerPrio'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCustomerStructure'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCustomerStructure')" position="left"
              @click="$store.commit('openModal', 'ConfigCustomerStructure'); $store.state.activeItems.ConfigCustomerStructure[$route.params.no] = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCustomerStructure">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCustomerStructure'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCustomerLead'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCustomerLead')" position="left"
              @click="$store.commit('openModal', 'ConfigCustomerLead'); $store.state.activeItems.ConfigCustomerLead = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCustomerLead">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCustomerLead'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigYearplanConfigs'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigYearplan')" position="left"
              @click="$store.commit('openModal', 'ConfigYearplan'); $store.state.activeItems.ConfigYearplan = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigYearplan">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsYearPlan'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCategoriesEmployee'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigEmployeeCategory')" position="left"
              @click="$store.commit('openModal', 'ConfigEmployeeCategory'); $store.state.activeItems.ConfigEmployeeCategory = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigEmployeeCategory">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsEmployeeCategories'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCustomerType'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCustomerType')" position="left"
              @click="$store.commit('openModal', 'ConfigCustomerType'); $store.state.activeItems.ConfigCustomerType = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCustomerType">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCustomerType'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCustomerTags'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCustomerTags')" position="left"
              @click="$store.commit('openModal', 'ConfigCustomerTags'); $store.state.activeItems.ConfigCustomerTags = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCustomerTags">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCustomerTags'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCountryConfigs'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCountryConfigs')" position="left"
              @click="$store.commit('openModal', 'ConfigCountryConfigs'); $store.state.activeItems.ConfigCountryConfigs = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCountryConfigs">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCountryConfigs'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigCustomerTitle'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigCustomerTitle')" position="left"
              @click="$store.commit('openModal', 'ConfigCustomerTitle'); $store.state.activeItems.ConfigCustomerTitle = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigCustomerTitle">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigCustomerTitle'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigWorkflowTracker'">
      <button class="btn btn-outline-primary purple btn-sm me-3" :tooltip="$t('add.ConfigWorkflowTracker')"
              position="left"
              @click="$store.commit('openModal', 'ConfigWorkflowTracker'); $store.state.activeItems.ConfigWorkflowTracker = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigWorkflowTracker">
        <div class="modal-dialog modal-xl purpleView">
          <div class="modal-content">
            <component :is="'DetailsConfigWorkflowTracker'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigWorkflowStates'">
      <button class="btn btn-outline-primary purple btn-sm me-3" :tooltip="$t('add.ConfigWorkflowStates')"
              position="left"
              @click="$store.commit('openModal', 'ConfigWorkflowStates'); $store.state.activeItems.ConfigWorkflowStates = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigWorkflowStates">
        <div class="modal-dialog modal-xl purpleView">
          <div class="modal-content">
            <component :is="'DetailsConfigWorkflowStates'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigWorkflowPrio'">
      <button class="btn btn-outline-primary purple btn-sm me-3" :tooltip="$t('add.ConfigWorkflowPrio')"
              position="left"
              @click="$store.commit('openModal', 'ConfigWorkflowPrio'); $store.state.activeItems.ConfigWorkflowPrio = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigWorkflowPrio">
        <div class="modal-dialog modal-xl purpleView">
          <div class="modal-content">
            <component :is="'DetailsConfigWorkflowPrio'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigWorkflowActivities'">
      <button class="btn btn-outline-primary purple btn-sm me-3" :tooltip="$t('add.ConfigWorkflowActivities')"
              position="left"
              @click="$store.commit('openModal', 'ConfigWorkflowActivities'); $store.state.activeItems.ConfigWorkflowActivities = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigWorkflowActivities">
        <div class="modal-dialog modal-xl purpleView">
          <div class="modal-content">
            <component :is="'DetailsConfigWorkflowActivities'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'products'">
      <button class="btn btn-outline-primary btn-sm  me-3" :tooltip="$t('add.product')" position="left"
              @click="$store.commit('openModal', 'product'); $store.state.activeItems.product = {}"><i
        class="fa-duotone fa-plus"></i></button>
      <Modal v-if="modalsList.product">
        <div class="modal-dialog modal-xl mt-3">
          <div class="modal-content">
        <component :is="'Details'" :inPopup="true"/></div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'drafts'">
      <button class="btn btn-outline-primary btn-sm  me-3" :tooltip="$t('add.draft')" position="left"
              @click="$store.commit('openModal', 'draft'); $store.state.activeItems.draft = {}"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.draft">
        <div class="modal-dialog modal-xl purpleView">
          <div class="modal-content">
        <component :is="'DetailsD'"/></div></div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'employees' && getUser && getUser.acl && getUser.acl.employees &&  getUser.acl.employees.create === 'all'">
      <button class="btn btn-outline-success btn-sm me-3" :tooltip="$t('add.employee')" position="left"
              @click="handleButtonClick"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.employee">
        <div class="modal-dialog modal-xl greenView">
          <div class="modal-content">
            <component :is="'EditEmployee'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'ConfigTimeConfigs'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.ConfigTimeConfigs')" position="left"
              @click="$store.commit('openModal', 'ConfigTimeConfigs'); $store.state.activeItems.ConfigTimeConfigs = {}">
        <i
          class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigTimeConfigs">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <component :is="'DetailsConfigTimeConfigs'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'Legals'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.legal')" position="left"
              @click="$store.commit('openModal', 'legal'); $store.state.activeItems.legal = {}"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.legal">
        <component :is="'DetailsL'"/>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'Sepa'">
      <button class="btn btn-outline-primary red btn-sm me-3" :tooltip="$t('add.sepa')" position="left"
              @click="$store.commit('openModal', 'sepa'); $store.state.activeItems.sepa = {}"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.sepa">
        <div class="modal-dialog modal-lg redView">
          <div class="modal-content">
            <component :is="'DetailsS'"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'Tasks'">
      <button class="btn btn-outline-primary purple btn-sm me-3" :tooltip="$t('add.task')" position="left"
              @click="$store.dispatch('setActiveCustomer', null);$store.commit('openModal', 'task'); $store.state.activeItems.task = {}"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.task">

            <component :is="'DetailsT'"/>

      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'Events'">
      <button class="btn btn-outline-primary purple btn-sm me-3" :tooltip="$t('add.event')" position="left"
              @click="$store.commit('openModal', 'event'); $store.state.activeItems.event = {}"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.event">
        <div class="modal-dialog modal-fullscreen purpleView">
          <div class="modal-content">
            <component :is="'DetailsE'" :inPopup="true"/>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'Documents'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.document')" position="left"
              @click="$store.state.activeItems.customer = {};$store.commit('openModal', 'document'); $store.state.activeItems.document = {}"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.document">
        <div class="modal fade show" id="modalOrder" tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true" style="display: block;">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-body">
            <component :is="'DetailsDocument'" :modal="true" :document="{}"  />
              </div></div>
          </div>
        </div>
      </Modal>
    </template>
    <template v-if="$route.matched[0].name === 'LangOverrides'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.override')" position="left"
              @click="$store.state.activeItems.customer = {};$store.commit('openModal', 'ConfigLangOverride'); $store.state.activeItems.ConfigLangOverride = {}"><i
        class="fa-duotone fa-plus fa-swap-opacity"></i></button>
      <Modal v-if="modalsList.ConfigLangOverride">
        <div class="modal fade show" id="modalOrder" tabindex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true" style="display: block;">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                <component :is="'DetailsLangOverrides'" :modal="true" :document="{}"  />
              </div></div>
          </div>
        </div>
      </Modal>
    </template>
  </template>
</template>
<script>
import Mail from "@/components/Mail.vue";
import Edit from "@/routing/core/Customers/Edit.vue";
import Details from "@/routing/core/Products/Details.vue";
import DetailsD from "@/routing/core/Drafts/Details.vue";
import {mapGetters} from "vuex";
import EditEmployee from "@/routing/core/Employees/Details.vue";
import DetailsL from "@/routing/core/Legals/Details.vue";
import DetailsS from "@/routing/core/Sepa/Details.vue";
import DetailsT from "@/routing/core/Tasks/Create.vue";
import DetailsE from "@/routing/core/Events/Details.vue";
import DetailsConfigAccountingKeys from "@/routing/core/Config/Accounting/AccountingKeys/Details.vue";
import DetailsConfigBankAccounts from "@/routing/core/Config/Accounting/BankAccounts/Details.vue";
import DetailsConfigCostNumbers from "@/routing/core/Config/Accounting/CostNumbers/Details.vue";
import DetailsConfigCustomerTaxes from "@/routing/core/Config/Accounting/CustomerTaxes/Details.vue";
import DetailsConfigCustomerAcquisitions from "@/routing/core/Config/Basic/CustomerAcquisitions/Details.vue";
import DetailsConfigCustomerState from "@/routing/core/Config/Basic/CustomerState/Details.vue";
import DetailsConfigCustomerPrio from "@/routing/core/Config/Basic/CustomerPrio/Details.vue";
import DetailsConfigCustomerLead from "@/routing/core/Config/Basic/CustomerLead/Details.vue";
import DetailsConfigCustomerStructure from "@/routing/core/Config/Basic/CustomerStructure/Details.vue";

import DetailsConfigCustomerType from "@/routing/core/Config/Basic/CustomerType/Details.vue";
import DetailsConfigCustomerTags from "@/routing/core/Config/Basic/CustomerTags/Details.vue";
import DetailsConfigCustomerTitle from "@/routing/core/Config/Basic/CustomerTitle/Details.vue";
import DetailsConfigTimeConfigs from "@/routing/core/Config/Employee/TimeConfigs/Details.vue";
import DetailsConfigWorkflowTracker from "@/routing/core/Config/Workflow/IssueTracker/Details.vue";
import DetailsConfigWorkflowPrio from "@/routing/core/Config/Workflow/IssuePrio/Details.vue";
import DetailsConfigWorkflowStates from "@/routing/core/Config/Workflow/IssueStates/Details.vue";
import DetailsConfigWorkflowActivities from "@/routing/core/Config/Workflow/IssueActivities/Details.vue";
import DetailsLangOverrides from "@/routing/core/Config/Basic/LangOverrides/Details.vue";
import DetailsYearPlan from "@/routing/core/Config/Accounting/YearPlan/Details.vue";
import DetailsEmployeeCategories from "@/routing/core/Config/Employee/CategoriesEmployee/Details.vue";
import DetailsDocument from "@/routing/core/Documents/Edit.vue";

export default {
  name: "plusbutton",


  data() {
    return {
      activeComponent: 'Edit'
    };
  },
  components: {
    Mail,
    Edit,
    Details,
    DetailsD,
    EditEmployee,
    DetailsL,
    DetailsS,
    DetailsT,
    DetailsE,
    DetailsConfigAccountingKeys,
    DetailsConfigBankAccounts,
    DetailsConfigCostNumbers,
    DetailsConfigCustomerTaxes,
    DetailsConfigCustomerAcquisitions,
    DetailsConfigCustomerState,
    DetailsConfigCustomerPrio,
    DetailsConfigCustomerLead,
    DetailsConfigCustomerType,
    DetailsConfigCustomerTags,
    DetailsConfigWorkflowTracker,
    DetailsConfigWorkflowPrio,
    DetailsConfigCustomerStructure,
    DetailsConfigWorkflowStates,
    DetailsConfigWorkflowActivities,
    DetailsConfigCustomerTitle,
    DetailsConfigTimeConfigs,
    DetailsDocument,
    DetailsLangOverrides,
    DetailsYearPlan,
    DetailsEmployeeCategories
  },
  computed: {
    ...mapGetters(["modalsList", 'getUser'])
  },
  methods: {
    handleButtonClick() {
      // Clear everything first
      this.$router.push({name: 'employees'})
      this.$store.state.activeItems.employee = {};
      // Delay the store commit action using setTimeout
      setTimeout(() => {
        this.$store.commit('openModal', 'employee');
      }, 500); // Set the desired delay in milliseconds
    },
    closeGlobal() {
      this.$store.commit('closeAllModals');
    }
  },

}
</script>
