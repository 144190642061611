<template>
  <div class="card-body">
    <Details :file="activeFile" @close-file="closeFile" />
    <div class="file-item" v-for="file in files.data"
         :key="file.id">
      <Headline :file="file" :fileActive="activeFile" @close-file="closeFile" @set-file="setFile(file)"/>
    </div>
  </div>
</template>
<script>
import Headline from "@/routing/core/Files/Layouts/Headline.vue";
import Details from "@/routing/core/Files/Details.vue";
export default {
  name: "List",
  components: {Headline, Details},
  props: {
    files: Object
  },
  data() {
    return {
      activeFile: null
    }
  },
  methods: {
    setFile(file) {
      this.activeFile = file;
      this.scrollToTop();
    },
    closeFile() {
      this.activeFile = null;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

