<template>
<div class="appView">
  <div class="reports" v-if="!statSingle">
    <button class="btn btn-outline-primary me-3" @click="$router.push('/stats/newCustomers')"><i class="fa-duotone fa-user"></i> Neue Kunden</button>
    <button class="btn btn-outline-primary me-3"  v-if="getPlugins && getPlugins.extension && getPlugins.extension.bdfl" @click="$router.push('/stats/birthdays')"><i class="fa-duotone fa-birthday-cake"></i> Runde Geburtstage</button>
    <button class="btn btn-outline-primary"  @click="$router.push('/stats/reports')" ><i class="fa-duotone fa-file-circle-info"></i> Berichte</button>
  </div>
  <div class="reportWindow" v-if="statSingle">
    <component :is="statSingle" />
  </div>
</div>
</template>

<script>

import newCustomersStats from "./Statistics/newCustomersStats.vue";
import birthdaysStats from "./Statistics/birthdays.vue";
import reportsStats from "./Statistics/reports.vue";
import {mapGetters} from "vuex";

export default {

  name: 'StatsOverview',
  components: {
    newCustomersStats, birthdaysStats, reportsStats
  },
  data() {
    return {
      statSingle: null,
    }
  },
  computed: {
    ...mapGetters(["getPlugins"])
  },
  methods: {
    setStat(stat) {
      if (stat === undefined ||!stat) {
        this.statSingle = null;
        return;
      }
      this.statSingle = stat+"Stats";
    }
  },
  created() {
    if (this.$route.params.type) {
      this.setStat(this.$route.params.type);
    }
  },
  watch: {
    '$route.params.type': function (val) {

        this.setStat(val);

    }
  }

}

</script>
