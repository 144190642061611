<template>
  <div class="pluginLineWrap">
    <div class="lineitem l-checkbox">
      <div class="checkbox"><input type="checkbox" :checked="sepaSelected[sepa.id]" @change="emitSelectedItems(sepa.id)" :id="'id_'+sepa.id" :value="sepa.id"/><label :for="'id_'+sepa.id"></label></div>
    </div>
      <div class="lineitem l-number">{{ sepa.customerNumber }}</div>
      <div class="lineitem l-title" @click="setSepa(sepa);">{{ sepa.title }}</div>
      <div class="lineitem l-title">{{ sepa.owner }}</div>
      <div class="lineitem l-iban">{{ sepa.iban }}</div>
      <div class="lineitem l-date ml-auto">{{ $filters.formatDate(sepa.crm_modified_date) }}</div>
      <template v-if="sepaActive.id === sepa.id">
        <div class="lineitem last ms-3" @click="setSepa(false)">
          <i class="fa-duotone fa-x"></i>
        </div>
      </template>
      <template v-else>
        <div class="lineitem last ms-3" @click="setSepa(sepa)">
          <i class="fa-duotone fa-chevrons-up"></i>
        </div>
      </template>

  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'SEPAHeadline',
  props: {
    sepa: {
      type: Object,
      required: true
    },

  },
  data() {
    return {
      sepas: [],
      selectedItem: false,
      checked: false,
    }
  },
  methods: {
    getSEPA() {
      this.$store.dispatch("sepasFilter");
    },
    setSepa(sepa) {
      this.$store.commit("setSepa", sepa);
      this.scrollToTop();
    },
    emitSelectedItems(id) {
      this.$store.commit('toggleSelected', {type: "sepas", id: id }  )
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },

  },
  created() {
    this.getSEPA();
  },
  computed: {
    ...mapGetters(["sepaList", "sepaActive", "sepaSelected" ]),
  },
}
</script>

