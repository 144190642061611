<template>
  <div class="addNewCommentWrap">
    <div class="row">
      <div class="col-1">
        <p class="text-end pt-3">
          <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, getUser._isEmployee, 'id')"
               class="sketchAvatar">
        </p>
      </div>
      <div class="col-11">
        <div class="commentWrap mt-3">
          <div class="editorWrap mb-3">
          <Editor2 v-model="comment.comment" ></Editor2></div>
          <template v-if="loading">
            <button  class="btn btn-outline-primary me-3" disabled><i class="fa-duotone fa-spinner fa-spin"></i>
              Senden
            </button>
            <button class="btn btn-outline-primary me-3" disabled><i class="fa-duotone fa-spinner fa-spin"></i>
              Senden & Kunde informieren
            </button>
          </template>
          <template v-else>
          <button @click.once="postComment(false)" class="btn btn-outline-primary me-3"><i class="fa-duotone fa-paper-plane"></i>
            Speichern
          </button>
          <button @click.once="postComment(true)" v-if="comment.official === 'official'" class="btn btn-outline-primary me-3"><i class="fa-duotone fa-envelope"></i> Speichern & Kunde informieren
          </button>
          </template>
          <button @click="$emit('close');" class="btn btn-outline-secondary"><i class="fa-duotone fa-x"></i>
            Abbrechen
          </button>

          <div class="btn-group ms-3 float-end">
            <input type="radio" class="btn-check" id="radio-2"  value="official" v-model="comment.official">
            <label for="radio-2" class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-comments"></i> Kommentar</label>
            <input type="radio" class="btn-check" id="radio-1"  value="not_official" v-model="comment.official">
            <label for="radio-1" class="btn btn-outline-secondary btn-sm"><i class="fa-duotone fa-note-sticky"></i> Interne Notiz</label>

          </div>
          <div class="form-group float-end me-3 ms-3 mb-2">
            <label class="color" @click="editExtFollower=true;"  style="cursor: pointer;">{{ $t("tasks.details.contactPerson") }} <i class="fa-duotone fa-edit"></i></label>

            <p @click="editExtFollower = true;"
               style="cursor: pointer;">
              <template v-if="taskActive && taskActive.followers && taskActive.followers.outer && taskActive.followers.outer.length">

                <template v-for="follower in taskActive.followers.outer">
                  <span style="display: block;">{{ follower._customerName }}</span>
                </template>
              </template>
              <template v-else>
                <template v-if="taskActive && taskActive.customer">
                  <i class="fa-duotone fa-edit"
                     v-if="taskActive.customer.c_firstname && taskActive.customer.c_lastname"></i>&nbsp;
                  <template v-if="taskActive.customer.c_firstname">{{ taskActive.customer.c_firstname }}&nbsp;</template>
                  <template v-if="taskActive.customer.c_lastname">{{ taskActive.customer.c_lastname }}</template>
                </template>
              </template>
            </p></div>
        </div>
      </div>
    </div>
    <hr>
  </div>
  <Modal v-if="editExtFollower">
    <div class="modal fade show " tabindex="-1"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg purpleView">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ansprechpartner ändern</h5>
            <button type="button" class="btn-close" style="margin-left: 24px;" @click="
      this.updateTask();editExtFollower = false"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="follower in addresses">
                <div class="col-6" v-if="follower.c_email">
                  <div class="prioSetter">
                    <!-- check if button is in array, if true then set it active with btn-primary -->
                    <template v-if="taskActive.followers && taskActive.followers.outer.some(item => item.id === follower.id)">
                      <button type="button" class="btn mb-2 w-100 btn-primary btn-sm  "
                              @click="setExtFollower(follower)"><span class="longName">{{ follower.c_title }} {{
                          follower.c_firstname
                        }} {{ follower.c_lastname }}<br><small>{{ follower.c_email }}</small></span></button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn mb-2 w-100 btn-outline-primary btn-sm  "
                              @click="setExtFollower(follower)"><span class="longName">{{ follower.c_title }} {{
                          follower.c_firstname
                        }} {{ follower.c_lastname }}<br><small>{{ follower.c_email }}</small></span></button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import {mapGetters} from "vuex";

export default {
  name: "Createcomment",
  emits: ['close'],
  props: {
    taskId: ""
  },
  components: {
    Editor2
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getUser", "commentActive"]),
  },
  data() {
    return {
      comment: {
        task_id: this.taskId,
        comment: "",
        official: 'not_official'
      },
      editExtFollower: false,
      loading: false,
      taskItem: '',
      showCommentNew: false,
      addresses: [],
    };
  },
  methods: {
    postComment(sendToCustomer) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.loading = true;
      let postContent = {
        data: this.comment,
        sendToCustomer: sendToCustomer
      }
      axios.post(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment", postContent, {headers: headers})
        .then((response) => {
          this.taskItem = response.data.result.data;
          this.$store.dispatch('setTask', this.taskItem);
          this.clearComment();
          this.loading = false;
          this.$emit('close');
          this.$store.commit('setComment', {});
        })
        .catch((error) => {
          this.$store.dispatch("handleApiResponse", error.response);

        });
    },
    setExtFollower(follower) {
      if(!this.taskActive.followers){
        this.taskActive.followers = {};
      }
      if(!this.taskActive.followers.outer){
        this.taskActive.followers.outer = [];
      }
      const index = this.taskActive.followers.outer.findIndex(item => item.id === follower.id);
      if (index !== -1) {
        // If the follower is already in the array, remove them
        this.taskActive.followers.outer.splice(index, 1);
      } else {
        // If the follower is not in the array, add them
        this.taskActive.followers.outer.push(follower);
      }
    },
    updateTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.taskActive.customer.hash;
      let postContent = {
        data: this.taskActive,
        hash: hash
      }
      axios.put(this.$store.state.apiUrl + 'issue/' + this.taskActive.id, postContent, {headers})
        .then(response => {

          this.editExtFollower = false;

        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e);
        });
    },
    getAddresses() {
      if (this.taskActive.crm_customer_id > 0) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.get(this.$store.state.apiUrl + "customer/" + this.taskActive.crm_customer_id + "/addresses", {headers}).then((r) => {
          this.addresses = r.data.result.data;
        });
      }
    },
    clearComment() {
      this.comment = {
        task_id: this.taskId,
        comment: "",
      };
      this.$store.commit('setComment', {});
    }
  },
  created() {
    this.getAddresses();
    if(this.commentActive && this.commentActive.comment){
      this.comment = this.commentActive;
    }
  },
  // watch comment and write in setComment
  watch: {
    comment: {
      handler: function (newVal) {
        this.$store.commit('setComment', newVal);
      },
      deep: true
    }
  }

}
</script>
