<template>

  <p>Test</p>

</template>

<script>

export default {

  name: 'OposOverview',

}

</script>