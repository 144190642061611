<template>
  <div class="appView plugin-list subs">
    <List/>
  </div>
</template>
<script>
import axios from "axios";
import List from "@/routing/core/Subscriptions/List.vue";

export default {
  name: "Subscriptions",
  components: {
    List,
  },
  methods: {
    setAbonnement(abonnement) {
      this.activeAbonnement = abonnement;
      //this.$router.push({name: "AbonnementDetails", params: {id: product.id}});
    },
    getAbonnements() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "subscription/search?limit=20", {headers}).then((r) => {
        this.abonnements = r.data.result.data;
        this.loading = false;
      });
    }
  },
  data() {
    return {
      abonnements: [],
      activeAbonnement: null
    };
  },
  created() {

    this.getAbonnements();
  },
};
</script>
<style lang="scss">
.product-item {
  cursor: pointer;
}
</style>
