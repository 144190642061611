<template>

   <template v-if="$route && $route.matched && $route.matched.length">


    <template v-if="$route.matched[0].name === 'Queue'">
      <button class="btn btn-outline-primary btn-sm  me-3" :tooltip="$t('cronjob.restart')" position="left"
              @click="restartCronjob"><i class="fa-duotone fa-clock-desk"></i></button>
      <button class="btn btn-outline-danger btn-sm  me-3" :tooltip="$t('globalSetting.emails.stop')" position="left"
              @click="stopSendMail"><i class="fa-duotone fa-mail-bulk"></i></button>

    </template>
     </template>
</template>
<script>

import axios from "axios";
export default {
  name: "restartCronjob",

  methods: {
    restartCronjob() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "queue/restartScripts", {},{headers}).then((r) => {
        this.$store.dispatch("toastSuccess", "Cronjob neugestartet. Dies kann bis zu 15 Minuten dauern.");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    stopSendMail(){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "queue/stopMails", {},{headers}).then((r) => {
        this.$store.dispatch("toastSuccess", "Versenden von E-Mails wurde gestoppt.");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    }
  }
};
</script>
