<template>
  <div class="sketch_content_wrap">
    <div class="sketch-row">
      <div class="columns large-12 medium-12 small-12"><h2 class="red">Einstellungen <span>Buchhaltung</span></h2></div>
    </div>
    <div class="configWrap red">
      <div class="sketch-row">
        <div class="columns large-12 medium-12 small-12"><h3><i class="fa-duotone fa-chart-bar"></i> Buchhaltung </h3></div>
      </div>
      <div class="sketch-row">
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/accounting/bankaccounts" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-piggy-bank fa-3x mb-3"></i>
                <h3>Bankkonten</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/accounting/keys" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-hand-holding-dollar fa-3x mb-3"></i>
                <h3>Sachkonten</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/accounting/costnumbers" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-chart-pie fa-3x mb-3"></i>
                <h3>Kostenstellen</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/accounting/customertaxes" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-book-section fa-3x mb-3"></i>
                <h3>Steuer-Regeln</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/accounting/yearplans" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-calendar-days fa-3x mb-3"></i>
                <h3>Jahresplan</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/accounting/importaccounts" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-dollar-sign fa-3x mb-3"></i>
                <h3>Konten importieren</h3>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfigAccounting"
}
</script>

