<template>
  <div class="card">
    <div class="card-header">
      <h6>Verkäufe Funnel</h6>
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-lg-6 col">
          <div class="chart">
            <PieChart :chartData="chartData" :options="options"/>
          </div>
        </div>
        <div class="col-lg-6 col">
          <strong>Interessenten auflisten:</strong><br>
          <ul class="list-group ms-0 mt-3 cursorlist" >
            <li   class="list-group-item" v-for="label in salesData.labels" @click="filterCustomers('filter_holzkraft', label)">{{ $t('funnel.'+label) }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {PieChart} from 'vue-chart-3';

export default {
  name: 'sales',
  components: {
    PieChart
  },
  data() {
    return {
      salesData:{},
      chartData: {
        labels: ['a', 'b', 'c'],
        datasets: [{
          label: 'Sales by Region',
          data: [120, 100, 80 ], // Example sales data for each region
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)'
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          }
        }
      }
    }
  },

  props: {
    filter: {
      type: String,
      default: ""
    },

  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  methods: {
    getTurnover() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/projects/sorted" + this.filter, {headers}).then((r) => {
        VueInner.salesData = r.data.result.data;
        let a;
        let b = [];
        let c = [];
        for (a in VueInner.salesData.labels) {
          b.push(VueInner.$t('funnel.'+VueInner.salesData.labels[a]));
        }
        for (a in VueInner.salesData.data) {
          c.push(VueInner.salesData.data[a]);
        }
        VueInner.chartData.labels = b;
        VueInner.chartData.datasets[0].data = c;

        VueInner.loading = false;
      });
    },
    filterCustomers($filter, $type) {
      let $route = '/customers';
      // add getParam

      $route += '?'+$filter+'='+$type;

      this.$router.push($route);
    }
  },
  watch: {
    filter: function (newVal, oldVal) {
      this.getTurnover();
    },
  },
  created() {
    this.getTurnover();
  },
}
</script>
