<template>

  <div class="productItem activeProduct">
    <div class="card mb-3">
      <div class="card-header ">
         <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{$t("countryConfigs.details.editCountry")}}</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeConfigCountryConfigs');$store.commit('closeModal', 'ConfigCountryConfigs');">
        </button>
          <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
            class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
          </button>

          <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
            class="fa-duotone fa-trash"></i> {{$t("globals.delete")}}
          </button>
      </div>



      <div class="card-body">
        <div class="row">
        <div class="col-2">
          <div class="form-group">
            <label> {{$t("countryConfigs.details.code")}} </label>
            <input class="form-control" type="text" v-model="d.lang_code" />
          </div>
        </div>
          <div class="col-2">
            <div class="form-group">
              <label>Farbe</label>
              <input class="form-control" type="color" v-model="d.color" />
            </div>
          </div>


          <div class="col-4">
            <div class="form-group">
              <label>{{$t("countryConfigs.details.countryName")}}</label>
              <input class="form-control" type="text" v-model="d.lang_name"/>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label>{{$t("countryConfigs.details.taxRate")}}</label>
              <input class="form-control" type="number" v-model="d.tax"  step="0.01" min="0"/>
            </div>
          </div>


            <div class="row">
              <div class="col-4">
                <div class="btn-group me-3">
                  <input type="radio" value="1" v-model="d.state" class="btn-check" id="state0" autocomplete="off"/>
                  <label class="btn btn-outline-primary" for="state0">{{$t("countryConfigs.details.inUse")}}</label>
                  <input type="radio" value="0" v-model="d.state" class="btn-check" id="state1" autocomplete="off"/>
                  <label class="btn btn-outline-primary" for="state1">{{$t("countryConfigs.details.deactivated")}}</label>
                </div>
                <label>{{$t("countryConfigs.details.status")}}</label>
              </div>



          <div class="col-4">
            <div class="btn-group me-3">
              <input type="radio" value="1" v-model="d.default" class="btn-check" id="default0" autocomplete="off"/>
              <label class="btn btn-outline-primary" for="default0">{{$t("globals.yes")}}</label>
              <input type="radio" value="0" v-model="d.default" class="btn-check" id="default1" autocomplete="off"/>
              <label class="btn btn-outline-primary" for="default1">{{$t("globals.no")}}</label>
            </div>
            <label>{{$t("countryConfigs.details.standardOptions")}}</label>
          </div>

          <div class="col-4">

            <div class="btn-group me-3">
              <input type="radio" value="1" v-model="d.lang_in_eu" class="btn-check" id="euCountry0" autocomplete="off">
              <label class="btn btn-outline-primary" for="euCountry0">{{$t("globals.yes")}}</label>
              <input type="radio" value="0" v-model="d.lang_in_eu" class="btn-check" id="euCountry1" autocomplete="off">
              <label class="btn btn-outline-primary" for="euCountry1">{{$t("globals.no")}}</label>
            </div>
            <label>{{$t("countryConfigs.details.euCountry")}}</label>
          </div>






          </div>
        </div>
      </div>
    </div>
  </div>



</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Basic/CountryConfigs/Layouts/Headline.vue";
import $ from "jquery";

export default {
  name: "ConfigCountryDetails",
  components: {
    Headline,
    Editor2
  },


  computed: {
    ...mapGetters(["getEmployees", "getConfigCountryConfigs", "getConfigs"])
  },
  data() {
    return {
      d: {},
      isNew: true,
    };
  },
  methods: {
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "config/countryConfigs/" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess

          // Load Products
          this.$store.dispatch("loadConfig", {type: "countries", percent: 70, message: 'Kohle wird in den Server geschaufelt'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Land wurde erfolgreich gespeichert');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigCountryConfigs');
          });
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl +  "config/countryConfigs/", VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {type: "countries", percent: 70, message: 'Kohle wird in den Server geschaufelt'}).then(() => {
            VueInner.$store.commit('closeModal', 'ConfigCountryConfigs');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.dispatch('toastSuccess', 'Land wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeConfigCountryConfigs');
          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll das Land wirklich gelöscht werden?', 'Land löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "config/countryConfigs/" + VueInner.d.id, {headers}).then(() => {
          // close
          VueInner.$store.commit('closeConfigCountryConfigs');
          // dispatch toastSuccess
          this.$store.dispatch("loadConfig",{type: "countries", percent: 70, message: 'Kohle wird in den Server geschaufelt'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Land wurde erfolgreich gelöscht');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigCountryConfigs');

          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id) {
        this.d = this.getConfigCountryConfigs

        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>

