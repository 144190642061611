<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
         <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{$t("ConfigYearplan.details.Yearplan")}}</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeConfigYearplan');$store.commit('closeModal', 'ConfigYearplan');">
        </button>
          <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
            class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
          </button>

          <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
            class="fa-duotone fa-trash"></i> {{$t("globals.delete")}}
          </button>
      </div>



      <div class="card-body">

        <div class="row">



          <div class="col-3">
            <div class="form-group">
              <label>{{$t("ConfigYearplan.details.year")}}</label>
              <input class="form-control" type="number" step="1" v-model="d.year">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label>{{$t("ConfigYearplan.details.turnover")}}</label>
              <input class="form-control" type="number" v-model="d.turnover"  step="1" min="0">
            </div>
          </div>





        </div>

      </div>
    </div>
  </div>



</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Accounting/YearPlan/Layouts/Headline.vue";
import $ from "jquery";

export default {
  name: "YearPlanDetails",
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigs"])
  },
  data() {
    return {
      d: {},
      isNew: true
    };
  },
  methods: {
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "config/companyPlanAccounting/" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess

          // Load Products
          this.$store.dispatch("loadConfig", {type: "companyPlanAccounting", percent: 35, message: 'Fahrstuhlmusik wird geladen'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Jahrespläne wurden erfolgreich gespeichert');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigYearplan');
          });

        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl +  "config/companyPlanAccounting/", VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {type: "companyPlanAccounting", percent: 35, message: 'Fahrstuhlmusik wird geladen'}).then(() => {
            VueInner.$store.commit('closeModal', 'ConfigYearplan');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.dispatch('toastSuccess', 'Jahresplan wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeConfigYearplan');
          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll der Jahresplan wirklich gelöscht werden?', 'Jahresplan löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#b21f28',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl +  "config/companyPlanAccounting/" + VueInner.d.id, {headers}).then(() => {
          // close
          // dispatch toastSuccess
          this.$store.dispatch("loadConfig",{type: "companyPlanAccounting", percent: 35, message: 'Fahrstuhlmusik wird geladen'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Jahresplan wurde erfolgreich gelöscht');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigYearplan');

          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id && this.getConfigs && this.getConfigs.companyPlanAccounting) {

        this.d = this.getConfigs.companyPlanAccounting.find((draft) => {
          return draft.id === parseInt(this.$route.params.id);
        });

        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>

