<template>
  <div class="appView drafts config">
    <Details v-if="$route.params.id" @update="getOverrides"/>
    <List/>
  </div>
</template>
<script>
import List from "./List.vue";
import Details from "./Details.vue";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "LangOverrides",
  components: {
    Details,
    List
  },
  computed: {
    ...mapGetters(["getConfigs"]),
  },
  methods: {
    getOverrides() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "config/overrides", {headers}).then((r) => {
        this.$store.commit('setLanguageOverrides', r.data.result.data);
      }).catch((error) => {
        this.$store.dispatch("handleApiResponse", error.response);
      });
    },
  },
  created(){

    this.getOverrides();
  },

};
</script>
<style lang="scss">
.product-item {
  cursor: pointer;
}
</style>
