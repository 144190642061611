<template>
  <div class="item-page vueApp">
    <div class="addCustomer" v-if="csvResults">
      <h6>Neuen Kundeneintrag</h6>
      <input type="text" class="form-control mb-1 small" placeholder="Kundennamen" v-model="formData.c_company">
      <input type="text" class="form-control mb-1 small" placeholder="Kundennummer" v-model="formData.c_lastname">
      <Multiselect
        class="small mb-1"
        label="title"
        :can-clear="false"
        open-direction="top"
        :can-deselect="false"
        valueProp="id"
        placeholder="Bitte wählen"
        v-model="selectedPack" :options="packs" />
      <button  v-show="formData.c_company != '' || formData.c_lastname != ''" class="btn btn-outline-primary btn-sm btn-add me-2"
              @click="addCustomer">Kunde erstellen
      </button>
      <button class="btn-small btn btn-success me-2" v-if="activatedBookingsCounter > 0 && selectedPack" @click="sendBookings">
        <span>{{ activatedBookingsCounter }}</span>
        Buchungen starten
      </button>
    </div>
    <table class="table-hover table-sm table vueTable" v-if="csvResults">
      <thead>
      <tr>
        <th class="actions">Aktionen</th>
        <th class="dateSum">Datum & Betrag</th>
        <th class="bookingText">Buchungstext</th>
        <th class="customer">Kundenzuweisung</th>
        <th class="acc">Sachkonto</th>
      </tr>
      </thead>
      <tbody>
      <tr :class="result.isCopied" :data-unique=" (result.uniqueCheck) ? '' : 'not_unique'" :key="'uLines_'+key"
          v-for="(result, key) in csvResults">
        <td class="actions">
          <span v-if="result.isCopied ==='copied'" title="Kopie von oben"><i class="fa-duotone fa-arrow-up"></i></span>
          <button type="button" class="btn btn-sm btn-outline-danger" v-if="result.isCopied !=='root'"
                  @click="removeRow(key)"><i
            class="fa-duotone fa-xmark-circle"></i></button>
          <button type="button" class="btn btn-sm btn-outline-warning" @click="splitLine(key)"><i
            class="fa-duotone fa-plus-circle"></i></button>
          <button type="button" class="btn btn-sm btn-outline-primary "
                  :class="(result.activatedBooking) ? 'btn-success' : ''"
                  @click="result.activatedBooking = !result.activatedBooking; (result.activatedBooking) ? activatedBookingsCounter++ : activatedBookingsCounter--">
            <i
              class="fa-duotone fa-check-circle"></i></button>
        </td>
        <td class="dateSum">
          {{ result.pay_date }}
          <input type="number" step="0.01" @input="lookForChanges" v-model="result.price">
          <p class="differenceReporter" v-if="(result.isCopied == 'root' && result.differenceReporter)"
             :class="result.differenceReporter.classes">{{ result.differenceReporter.text }}</p>
        </td>
        <td class="bookingText"><textarea class="form-control" v-model="result.booking_text"></textarea></td>
        <td class="customer"><label class="d-block" tooltip="Auftraggeber">{{ result.customer }}</label>
          <button @click="result.selectedCustomer = customer.id; result.changedCustomerSelection = true"
                  :class="(result.selectedCustomer === customer.id) ? 'btn-primary' : 'btn-outline-primary'"
                  type="button"
                  class="btn btn-sm mb-1 me-1 " v-for="customer in result.searchResults">{{
              (customer.c_company) ?
                customer.c_company : customer.c_lastname
            }}
          </button>

          <customersearch v-model="customerSelection[result.hash]" :small="true"
                          @changed-customer="resetId(customerSelection[result.hash],key)"></customersearch>
          <input v-if="result.changedCustomerSelection" type="text" v-model="result.customerAssociation"
                 placeholder="Assoziation anlegen">
        </td>
        <td class="acc">
          <Multiselect
            class="small mb-2"
            label="name"
            v-model="result.type"
            :value="result.type"
            :can-clear="false"
            :can-deselect="false"
            :options="selects.type"
          ></Multiselect>
          <template v-if="result.type !== 'opo'">
            <Multiselect v-if="selects.accounting_key.length > 0"
                         class="small mb-2"
                         :can-clear="false"
                         :can-deselect="false"
                         label="title"
                         v-model="result.accounting_key"
                         :value="result.accounting_key"
                         valueProp="id"
                         :searchable="true"
                         :options="selects.accounting_key"
            ></Multiselect>
            <input type="number" step="1" min="2019" class="mb-2 form-control" v-model="result.accounting_year">
            <Multiselect
              class="small mb-2"
              label="name"
              :can-clear="false"
              :can-deselect="false"
              v-model="result.accounting_period"
              :value="result.accounting_period"
              :options="selects.accounting_period"
            ></Multiselect>
          </template>
          <template v-else>
            <Multiselect
              label="name"
              class="small mb-2"
              :can-clear="false"
              :can-deselect="false"
              v-model="result.fillBillMethod"
              :value="result.fillBillMethod"
              :options="selects.fillBill"
            ></Multiselect>
          </template>
        </td>

      </tr>
      </tbody>
    </table>
    <template v-else>
      <div class="row">
        <div class="cols-6 cols">
          <div class="card">
            <h6 class="card-header">CSV Datei auswählen</h6>
            <div class="card-body">
              <p class="card-text">Bitte wählen Sie eine CSV Datei aus, die Sie importieren möchten.</p>
              <Multiselect class="small" :multiple="false" label="title" v-model="account_id" valueProp="id"
                           :options="getConfigs.bankAccounts" placeholder="Konto wählen"/>
              <div class="form-group">
                <label class="d-block mt-3 mb-2">CSV Datei</label>
                <input type="file" @change="getCsvResults" accept=".csv"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import axios from "axios";
import Multiselect from '@vueform/multiselect'
import customersearch from "@/filter/components/customersearch.vue";
import {mapGetters} from "vuex";

export default {
  name: "import",
  data() {
    return {
      csvFile: false,
      activatedBookingsCounter: 0,
      account: {},
      customerSelection: {},
      selectedPack: false,
      account_id: false,
      csvResults: false,
      customers: {},
      packs: [],
      result: {},
      formData: {
        c_company: "",
        c_lastname: "",
      },
      selects: {
        type: [
          {value: "opo", name: "Offener Posten (Rechnung fehlt noch)"},
          {value: "dir", name: "Direktbuchung"},
          {value: "loh", name: "Lohnbuchung ohne Beleg"}
        ],
        fillBill: [
          {value: "all", name: "Mit allen Rechnungen abgleichen"},
          {value: "once", name: "Nur mit exakten Rechnungsbeträgen abgleichen"}
        ],
        accounting_key: {},
        accounting_period: [
          {value: "year_complete", name: "Komplettes Geschäftsjahr"},
          {value: "year_rest", name: "Restliches Geschäftsjahr"},
          {value: "month", name: "Aktueller Monat"},
          {value: "quartal_1st", name: "1. Quartal"},
          {value: "quartal_2nd", name: "2. Quartal"},
          {value: "quartal_3rd", name: "3. Quartal"},
          {value: "quartal_4th", name: "4. Quartal"},
          {value: "half_1st", name: "1. Halbjahr"},
          {value: "half_2nd", name: "2. Halbjahr"}
        ],
        accounting_year: new Date().getFullYear()
      }
    }
  },
  computed: {
    ...mapGetters(["customerList", "getPlugins", "modalsList", "getApiUrl", "getConfigs"])
  },
  components: {
    Multiselect, customersearch
  },
  methods: {
    sendBookings: function () {
      if (this.activatedBookingsCounter > 0) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        var app = this;
        this.csvResults.forEach(function (value) {
          if (value.activatedBooking) {
            value.pack_id = app.selectedPack;
            axios.post(app.getApiUrl + "accounting/booking", value, {headers}).then(function (feedback) {
              if (feedback.data.result.success === true) {
                app.removeRow(value.hash, "hash");
                app.activatedBookingsCounter--;
              } else {
                alert("error");
              }
            });
            // replace above function with axios post
          }
        });
      }
    },
    getPacks() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/packs", {headers}).then((r) => {
        this.packs = r.data.result.data;
        this.loading = false;
      });
    },
    getCsvResults(event) {
      let formData = new FormData();
      formData.append("csv", event.target.files[0]);
      formData.append("account_id", this.account_id);
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.getApiUrl + "accounting/import", formData, {headers}).then((r) => {
        this.csvResults = r.data.result.data.data;
      });
    },
    getCustomers() {
      if (this.customerList) {
        this.customers = this.customerList;
      }
    },
    resetId(data, hash) {
      if(this.csvResults[hash] === undefined){
        this.csvResults[hash] = {};
      }
      console.log(data);
      console.log(hash);

      this.csvResults[hash].selectedCustomer = data.id;
      this.csvResults[hash].changedCustomerSelection = true;
      console.log(this.csvResults);
    },
    getAccountingKey() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "accounting/accountsExpense", {headers}).then((r) => {
        this.selects.accounting_key = r.data.result.data;
      });
    },
    lookForChanges: function () {
      var resultsUsed = [];
      this.csvResults.forEach(function (value, inkey) {
        if (resultsUsed[value.hash] === undefined) {
          resultsUsed[value.hash] = {counter: 1, price: parseFloat(value.price)};
          if (value.isCopied === "root") {
            resultsUsed[value.hash]["rootElement"] = inkey;
          }
        } else {
          resultsUsed[value.hash].counter += 1;
          resultsUsed[value.hash].price += parseFloat(value.price);
        }
      });
      var key;
      for (key in resultsUsed) {
        if (resultsUsed[key].counter > 1) {
          // CHECK Root vs. price
          var rootElement = resultsUsed[key].rootElement;
          var calcPrice = resultsUsed[key].price.toFixed(2);
          var originalPrice = parseFloat(this.csvResults[rootElement].originalPrice).toFixed(2);
          if (calcPrice !== originalPrice) {

            var diff = originalPrice - calcPrice;
            this.csvResults[rootElement].differenceReporter = {
              classes: "fail",
              text: "Die Werte ergeben nicht die Summe von " + originalPrice + ". Es fehlen " + diff
            }
          } else {
            this.csvResults[rootElement].differenceReporter = null;
          }
        }
      }
    }
    ,
    addCustomer: function () {
      let payload = this.formData;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "customer", payload, {headers}).then((r) => {
        this.customers.push(value.data);
      });
    }
    ,
    removeRow: function (key, looker) {
      if (looker === undefined) {
        looker = "key";
      }
      var returner = [];
      this.csvResults.forEach(function (value, inkey) {
        if (looker === "key") {
          if (inkey !== key) {
            returner.push((Object.assign({}, value)));
          }
        }
        if (looker === "hash") {
          if (value.hash !== key) {
            returner.push((Object.assign({}, value)));
          }
        }
      });
      this.csvResults = returner;
    }
    ,
    splitLine: function (key) {
      var returner = [];
      this.csvResults.forEach(function (value, inkey) {
        if (inkey !== key) {
          returner.push((Object.assign({}, value)));
        } else {
          value['isCopied'] = "root";
          returner.push((Object.assign({}, value)));
          value['isCopied'] = "copied";
          value['price'] = 0;
          returner.push((Object.assign({}, value)));
        }
      });
      this.csvResults = returner;
    }
  },
  created() {
    this.getAccountingKey();
    this.getCustomers();
    this.getPacks();
    // prefill account_id
    if (this.getConfigs && this.getConfigs.bankAccounts) {
      const defaultAccount = this.getConfigs.bankAccounts.find((account) => account.default === '1');

      if (defaultAccount) {
        this.account_id = defaultAccount.id;
      }
    }
  },
  watch: {
    'csvResults':
      function () {
        this.lookForChanges();
      },
    'result.selectedCustomer'(newVal, oldVal) {
      // Trigger your function whenever result.selectedCustomer changes
      this.resetId(newVal);
    },
  }
}
</script>


