<template>
  <div class="card-body draft-list">
    <div class="mainscroller"
                       :class="(categoryActive && Object.keys(categoryActive).length > 0) ? 'active' : ''">
      <p class="text-sm text-secondary" v-if="!categoryList || categoryList.length === 0">Es wurden keine Kategorien
        gefunden.</p>
      <template v-for="categoryTree in categoryList">
        <div class="accordionWrap">
          <h5>{{ categoryTree.root.title }}</h5>
          <div class="draft-item" v-for="category in categoryTree.items"
               :key="category.id">
            <Headline :category="category"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Details from "@/routing/core/Categories/Details";
import Headline from "@/routing/core/Categories/Layouts/Headline.vue";
import axios from "axios";

export default {
  name: "CategoryList",
  computed: {
    ...mapGetters(["getEmployees", "categoryList", "getConfigs", "categoryActive"])
  },
  data: function () {
    return {
      categoryList: [],
      categoryActive: null,
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
    }
  },
  components: {
    Headline,
    Details,
  },
  created() {
    this.getCategories()
    if (!this.$route.params.id) {
      this.$store.commit("closeCategory");
    }
  },
  methods: {
    getCategories() {
      let headers = this.headers;
      axios.get(this.$store.state.apiUrl + 'config/categories', {headers}).then(response => {
        this.categoryList = response.data.result.data;
      }).catch(error => {
        this.$store.dispatch("handleApiResponse", error.response);
      })
    }
  },
  watch: {
    $route(to, from) {
      this.getCategories()
      if (!to.params.id) {
        this.$store.commit("closeCategory");
      }
    },
    draftActive() {
    }
  }
};
</script>
