<template>

    <div class="appView events purpleView">
      <Details v-if="$route.params.id"/>
      <List />
    </div>


</template>
<script>
import List from "./List.vue";
import Details from "@/routing/core/Events/Details.vue";
export default {
  name: 'Events',
  data() {
    return {
      events: [],
    }
  },
  components: {
    Details,
    List
  },

}
</script>
