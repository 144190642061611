<template>
  <div class="appView config purpleView">
    <div class="productItem activeProduct">
      <div class="card">
        <h6 class="card-header ">Events Einstellungen


          <div class="btn-group float-end">
            <button @click="saveConfig"
                    type="button" class=" btn btn-sm btn-outline-primary">
              <i class="fa-duotone fa-floppy-disk"></i> Speichern
            </button>
            <button type="button" class="btn btn-outline-secondary btn-sm"
                    @click="$router.push({name: 'ConfigWorkflow'})"><i
              class="fa-duotone fa-xmark"></i> Abbrechen
            </button>
          </div>

        </h6>
        <ul class="tabNav">
          <li v-for="cat in configTabs" :key="cat.name"
              @click="changeTab(cat.name)"
              :class="(activeSubtab === cat.name) ? 'active' : ''"><i class="fa-duotone"
                                                                      :class="cat.icon"></i><br><small>{{
              cat.label
            }}</small>
          </li>
        </ul>
        <div class="card-body" v-if="activeSubtab === 'general'">
          <div class="form-group">
            <div class="btn-group me-3">
              <input type="radio" value="1" class="btn-check" id="events_highlight_first-yes" autocomplete="off"
                     v-model.number="options.sort_highlight">
              <label class="btn btn-outline-primary" for="events_highlight_first-yes">Ja</label>
              <input type="radio" value="0" class="btn-check" id="events_highlight_first-no" autocomplete="off"
                     v-model.number="options.sort_highlight">
              <label class="btn btn-outline-primary" for="events_highlight_first-no">Nein</label>
            </div>
            <label>Highlight zuerst darstellen</label>
          </div>
          <div class="form-group">
            <div class="btn-group me-3">
              <input type="radio" value="1" class="btn-check"
                     id="events_crm_guest_order_block-yes" autocomplete="off"
                     v-model.number="options.dates_let_guests">
              <label class="btn btn-outline-primary" for="events_crm_guest_order_block-yes">Ja</label>
              <input type="radio" value="0" class="btn-check"
                     id="events_crm_guest_order_block-no" autocomplete="off"
                     v-model.number="options.dates_let_guests">
              <label class="btn btn-outline-primary" for="events_crm_guest_order_block-no">Nein</label>
            </div>
            <label>CRM-Gastbestellung blockieren?</label>
          </div>
          <div class="form-group">
            <div class="btn-group me-3">
              <input type="radio" value="1" class="btn-check"
                     id="sendmail_on_upload-yes" autocomplete="off"
                     v-model.number="options.sendmail_on_upload">
              <label class="btn btn-outline-primary" for="sendmail_on_upload-yes">Ja</label>
              <input type="radio" value="0" class="btn-check"
                     id="sendmail_on_upload-no" autocomplete="off"
                     v-model.number="options.sendmail_on_upload">
              <label class="btn btn-outline-primary" for="sendmail_on_upload-no">Nein</label>
            </div>
            <label>Email Information bei Upload</label>
          </div>
          <div class="form-group">
            <label>Link zu AGB</label>
            <input v-model="options.link_terms" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Cronjob Secret</label>
            <input v-model="options.cronjob_secret" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Cronjob Tage zum Löschen</label>
            <input v-model="options.cronjob_days" type="number" class="form-control" min="1" step="1"/>
          </div>
          <div class="form-group">
            <div class="btn-group me-3">
              <input type="radio" value="1" class="btn-check"
                     id="events_google_maps-yes" autocomplete="off"
                     v-model.number="options.use_google_maps">
              <label class="btn btn-outline-primary" for="events_google_maps-yes">Ja</label>
              <input type="radio" value="0" class="btn-check"
                     id="events_google_maps-no" autocomplete="off"
                     v-model.number="options.use_google_maps">
              <label class="btn btn-outline-primary" for="events_google_maps-no">Nein</label>
            </div>
            <label>Google Maps verwenden</label>
          </div>
          <div class="form-group">
            <div class="btn-group me-3">
              <input type="radio" value="1" class="btn-check"
                     id="events_captcha-yes" autocomplete="off"
                     v-model.number="options.use_captcha">
              <label class="btn btn-outline-primary" for="events_captcha-yes">Ja</label>
              <input type="radio" value="0" class="btn-check"
                     id="events_captcha-no" autocomplete="off"
                     v-model.number="options.use_captcha">
              <label class="btn btn-outline-primary" for="events_captcha-no">Nein</label>
            </div>
            <label>Captcha verwenden</label>
          </div>
          <div class="form-group">
            <label>GoogleMap Farben</label>
            <p class="mb-0"><small>Eigene Styles wählbar von snazzymaps.com</small></p>
            <textarea v-model="options.google_map_style" class="form-control"></textarea>
          </div>
          <div class="form-group">
            <div class="btn-group me-3">
              <input type="radio" value="1" class="btn-check"
                     id="events_google_maps-yes" autocomplete="off"
                     v-model.number="options.use_location_toggler">
              <label class="btn btn-outline-primary" for="events_google_maps-yes">Ja</label>
              <input type="radio" value="0" class="btn-check"
                     id="events_google_maps-no" autocomplete="off"
                     v-model.number="options.use_location_toggler">
              <label class="btn btn-outline-primary" for="events_google_maps-no">Nein</label>
            </div>
            <label>Standort in Karte</label>
          </div>
          <div class="form-group">
            <label>Google Api Key</label>
            <input v-model="options.google_api_key" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>DSGVO Checkbox</label>
            <p class="mb-0"><small>Text wird bei Checkbox bei Anmeldung angezeigt. Wenn leer erscheint auch keine
              Checkbox</small>
            </p>
            <textarea v-model="options.use_dsgvo_checkbox" class="form-control"></textarea>
          </div>
          <div class="form-group">
            <label>AGB Checkbox</label>
            <p class="mb-0"><small>Text wird bei Checkbox bei Anmeldung angezeigt. Wenn leer erscheint auch keine
              Checkbox</small>
            </p>
            <textarea v-model="options.use_agb_checkbox" class="form-control"></textarea>
          </div>
        </div>
        <div class="card-body" v-if="activeSubtab === 'theme'">
          <div class="form-group">
            <label>Hintergrund (ungerade)</label>
            <input v-model="options.box_odd" type="color" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Hintergrund (gerade)</label>
            <input v-model="options.box_even" type="color" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Überschrift-Farbe</label>
            <input v-model="options.heading_color" type="color" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Hintergrund Button</label>
            <input v-model="options.button_bg" type="color" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Schriftfarbe Button</label>
            <input v-model="options.button_color" type="color" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Highlight Farbe</label>
            <input v-model="options.highlight_color" type="color" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Highlight Border Breite</label>
            <input v-model="options.highlight_border" type="number" min="1" max="10" step="1" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Schriftgröße Listenansicht</label>
            <input v-model="options.listing_font_size" type="number" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Custom CSS-Code</label>
            <EditorHtml v-model="options.custom_css" :languages="[['css','CSS']]"/>
          </div>
        </div>
        <div class="card-body" v-if="activeSubtab === 'mailing'">
          <div class="form-group">
            <label>Absendemail</label>
            <input v-model="options.mailfrom" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Absendername</label>
            <input v-model="options.fromname" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Reply To</label>
            <input v-model="options.replyto" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>BCC</label>
            <p class="mb-0"><small>Kommasepariert, ohne Leerzeichen mehrere Emails möglich</small></p>
            <input v-model="options.bcc" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Lastschrift Adressen</label>
            <p class="mb-0"><small>Kommasepariert, ohne Leerzeichen mehrere Emails möglich</small></p>
            <input v-model="options.debit_mail" type="text" class="form-control"/>
          </div>
          <hr>
          <h5>Registrierung</h5>
          <div class="form-group">
            <label>Betreff Registration</label>
            <input v-model="options.registration_hl" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Text Registration</label>
            <Editor2 v-model="options.registration_bd"/>
          </div>
          <h5>Aktivierungsmail</h5>
          <div class="form-group">
            <label>Betreff Aktivierung</label>
            <input v-model="options.waitingToActive_hl" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Text Aktivierung</label>
            <Editor2 v-model="options.waitingToActive_bd"/>
          </div>
          <h5>Warteliste</h5>
          <div class="form-group">
            <label>Betreff Warteliste</label>
            <input v-model="options.waiting_hl" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Text Warteliste</label>
            <Editor2 v-model="options.waiting_bd"/>
          </div>
          <h5>Änderungshinweise</h5>
          <div class="form-group">
            <label>Betreff Änderungshinweis</label>
            <input v-model="options.sendChanges_hl" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Text Änderungshinweis</label>
            <Editor2 v-model="options.sendChanges_bd"/>
          </div>
        </div>
        <div class="card-body" v-if="activeSubtab === 'storno'">
<div class="row">
  <div class="col-lg-6 cols"><div class="form-group">
    <label>Storno Betreff</label>
    <input v-model="options.storno_hl" type="text" class="form-control"/>
  </div></div>
  <div class="col-lg-6 cols">
    <div class="form-group">
      <label>x Tage vor Start stornierbar, wenn 0 keine Storno-Daten</label>
      <input type="number" v-model="options.storno_deadline_before_start" class="form-control" step="1" min="0"/>
    </div>
  </div>
</div>

          <div class="form-group">
            <label>Storno Text</label>
            <Editor2 v-model="options.storno_bd"/>
          </div>
          <div class="form-group">
            <label>Storno Betreff 2</label>
            <input v-model="options.storno2_hl" type="text" class="form-control"/>
          </div>
          <div class="form-group">
            <label>Storno Text 2</label>
            <Editor2 v-model="options.storno2_bd"/>
          </div>
          <div class="form-group">
            <label>Storno Betreff 3</label>
            <input v-model="options.storno3_hl" type="text" class="form-control"/>
            </div>
          <div class="form-group">
            <label>Storno Text 3</label>
            <Editor2 v-model="options.storno3_bd"/></div>
          <div class="form-group">
            <label>Storno Betreff NoShow</label>
            <input v-model="options.storno_noshow_hl" type="text" class="form-control"/>
            </div>
          <div class="form-group">
            <label>Storno Text NoShow</label>
            <Editor2 v-model="options.storno_noshow_bd"/>
            </div>

        </div>
        <div class="card-body" v-if="activeSubtab === 'pdf'">
          <div class="form-group">
            <label>Briefkopf</label>
            <EditorHtml v-model="options.pdf_header" :languages="[['html','HTML']]"/>
          </div>
          <div class="form-group">
            <label>Brieffuß</label>
            <EditorHtml v-model="options.pdf_footer" :languages="[['html','HTML']]"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import EditorHtml from "@/components/EditorHtml.vue";
import Editor2 from "@/components/Editor2";
import axios from "axios";
import IndexedDbFunctions from "@/assets/js/indexedDbFunctions";

export default {
  name: "EventsConfig",
  data() {
    return {
      activeSubtab: 'general',
      configTabs: [
        {name: 'general', label: "Global", icon: 'fa-cog'},
        {name: 'theme', label: "Theme", icon: 'fa-palette'},
        {name: 'mailing', label: "Mailing", icon: 'fa-envelope'},
        {name: 'storno', label: "Storno", icon: 'fa-times'},
        {name: 'pdf', label: "PDF", icon: 'fa-file-pdf'}
      ],
      options: {
        sort_highlight: 0,
        dates_let_guests: 0,
        sendmail_on_upload: 0,
        link_terms: '',
        cronjob_secret: '',
        cronjob_days: 1,
        use_google_maps: 0,
        use_captcha: 0,
        google_map_style: '',
        use_location_toggler: 0,
        google_api_key: '',
        use_dsgvo_checkbox: '',
        use_agb_checkbox: '',
        box_odd: '#f9f9f9',
        box_even: '#ffffff',
        heading_color: '#000000',
        button_bg: '#007bff',
        button_color: '#ffffff',
        highlight_color: '#f9f9f9',
        highlight_border: 1,
        listing_font_size: 14,
        custom_css: '',
        mailfrom: '',
        fromname: '',
        replyto: '',
        bcc: '',
        debit_mail: '',
        registration_hl: '',
        registration_bd: '',
        waitingToActive_hl: '',
        waitingToActive_bd: '',
        waiting_hl: '',
        waiting_bd: '',
        sendChanges_hl: '',
        sendChanges_bd: '',
        storno_deadline_before_start: 0,
        storno_hl: '',
        storno_bd: '',
        storno2_hl: '',
        storno2_bd: '',
        storno3_hl: '',
        storno3_bd: '',
        storno_noshow_hl: '',
        storno_noshow_bd: '',
        pdf_header: '',
        pdf_footer: ''
      }
    }
  },
  components: {
    EditorHtml, Editor2
  },
  methods: {
    getConfigLocal() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let getUrl = this.$store.getters.getApiUrl + "config/sketchdatesParams";


      axios.get(getUrl, {headers}).then(async (response) => {
        let shortResponse = response.data.result;


        this.options = shortResponse.data;
      }).catch(function () {
      });
    },
    changeTab(tab) {
      this.activeSubtab = tab;
    },
    saveConfig() {
        let VueInner = this;
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
          axios.put(this.$store.state.apiUrl + "config/sketchdatesParams", this.options, {headers}).then((r) => {
            VueInner.$store.dispatch('toastSuccess', 'Einstellung wurde erfolgreich gespeichert');
            this.options = r.data.result.data;
            VueInner.$router.push({name: 'ConfigWorkflow'});
      }).catch((error) => {
            this.$store.dispatch("handleApiResponse", error.response);
            VueInner.$router.push({name: 'ConfigWorkflow'});
      });
    }
  },
  beforeMount() {
    this.getConfigLocal();
  }
}
</script>

