<template>
  <div class="productItem activeProduct">
    <div class="card">
      <h6 class="card-header"><i class="fa-duotone fa-square-info"></i> Produktdetails
        <button class="btn-close float-end" @click="closeProduct"></button>
        <div class="btn-group float-end">
          <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteProduct()" v-if="p.id"><i
            class="fa-duotone fa-trash"></i> Löschen
          </button>
          <button type="button" class="btn btn-outline-primary me-3 btn-sm" @click="updateProduct()"><i
            class="fa-duotone fa-floppy-disk"></i> Speichern
          </button>
        </div>
      </h6>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <label>Produktname</label>
              <input v-model="p.p_title" type="text" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label>Kategorie</label>
              <Multiselect
                :options="Object.values(getConfigs.categories_products)"
                v-if="getConfigs.categories_products"
                :multiple="false"
                :label="'title'"
                v-model="p.p_catid"
                :can-clear="false"
                :valueProp="'id'"
                :searchable="false"
              ></Multiselect>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label>Status</label>
              <Multiselect
                :options="[{
                  label: 'Veröffentlicht',
                  value: '1'
                }, {
                  label: 'Versteckt',
                  value: '0'
                }, {
                  label: 'Archiviert',
                  value: '2'
                }, {
                  label: 'Papierkorb',
                  value: '-2'
                }]"
                :multiple="false"
                :label="'label'"
                :can-clear="false"
                :valueProp="'value'"
                :track-by="'value'"
                v-model="p.p_state"
                :searchable="false"></Multiselect>
            </div>
          </div>
        </div>
        <hr>
        <h6>Preis</h6>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Standard Menge</label>
              <input v-model="p.p_default_quantity" type="number" class="form-control"></div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Standardpreis</label>
              <input v-model="p.p_default_price" type="text" class="form-control"/>
              <template v-if="!inPopup">
                <label>Preis</label>
                <input type="text" readonly :value="p.p_base_price" class="form-control">
              </template>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Standard Einheit</label>
              <input v-model="p.p_default_unit" type="text" class="form-control">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Einkaufspreis pro Einheit</label>
              <input v-model="p.p_ek_price" type="text" class="form-control"/>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Durchlaufposten</label>
              <Multiselect
                :options="[{
                  label: 'Nein',
                  value: '0'
                }, {
                  label: 'Ja',
                  value: '1'
                }]"
                :multiple="false"
                :label="'label'"
                v-model="p.p_dlp"
                :can-clear="false"
                :valueProp="'value'"
                :searchable="false"
              ></Multiselect>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Rabattierbar</label>
              <Multiselect
                :options="[{label: 'Nein', value:'not_discountable'}, {label: 'Ja', value:'discountable'}]"
                :multiple="false"
                :label="'label'"
                v-model="p.discountable"
                :can-clear="false"
                :valueProp="'value'"
                :searchable="false"
              ></Multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Produktnummer</label>
              <input v-model="p.p_number" type="text" class="form-control">
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>EAN</label>
              <input v-model="p.p_ean" type="text" class="form-control">
            </div>
          </div>
        </div>
        <hr>
        <h6>Beschreibungen</h6>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Produktbeschreibung</label>
              <Editor2 v-model="p.p_desc" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label>Interne Informationen</label>
              <Editor2 v-model="p.p_intern_info"/>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label>Öffentliche Informationen</label>
              <Editor2 v-model="p.p_order_info"/>
            </div>
          </div>
        </div>
        <hr>
        <h6>Buchhaltung</h6>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Kostenstelle </label>
              <Multiselect v-model="p.p_cost_number_id" v-if="getConfigs"
                           :options="Object.values(getConfigs.costNumbers)"
                           :searchable="true"
                           :label="'cost_title'"
                           :valueProp="'id'"
                           :closeOnSelect="true"
                           :placeholder="'Kostenstelle wählen'"
                           class="small"
              ></Multiselect>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Erlöskonto</label>
              <input type="text" class="form-control small" v-model="p.account_number">
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="form-group">
              <label>Zzgl. Umsatzsteuer-Gruppe</label>
              <Multiselect
                :options="[{label: 'Bitte wählen', value: '0'}, {label: 'Normal (19%)', value: '3'}, {label: 'Ermäßigt (7%)', value: '2'}, {label: 'Keine (0%)', value: '1'}]"
                :multiple="false"
                :label="'label'"
                :can-clear="false"
                :valueProp="'value'"
                :track-by="'value'"
                v-model="p.tax_group_id"
                :searchable="false" class="small"></Multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Products/Layouts/Headline.vue";
import Multiselect from '@vueform/multiselect'

export default {
  name: "ProductDetails",
  components: {
    Multiselect,
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "productActive", "getConfigs"])
  },
  props: {
    product: {},
    inPopup: false
  },
  data() {
    return {
      p: {
        p_title: '',
        p_catid: '',
        p_state: '',
        p_default_quantity: '',
        p_default_price: '',
        p_default_unit: '',
        p_ek_price: '',
        p_dlp: '',
        discountable: '',
        p_number: '',
        p_ean: '',
        p_desc: '',
        p_intern_info: '',
        p_order_info: '',
        p_cost_number_id: '',
        account_number: '',
        tax_group_id: '',
      },
      isNew: true,
      selectedId: null,
    };
  },
  methods: {
    updateProduct() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.isNew) {
        axios.post(this.$store.state.apiUrl + "product", VueInner.p, {headers}).then(() => {
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Produkt wurde erfolgreich gespeichert');
          // Load Products
          VueInner.closeProduct();
          VueInner.$store.dispatch('loadProducts');
        }).catch((r) => {
        });
      } else {
        axios.put(this.$store.state.apiUrl + "product/" + VueInner.p.id, VueInner.p, {headers}).then(() => {
          // close
          VueInner.closeProduct();
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Produkt wurde erfolgreich gespeichert');
          // Load Products
          VueInner.$store.dispatch('loadProducts');
        }).catch((r) => {
        });
      }
    },
    deleteProduct() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "product/" + VueInner.p.id, {headers}).then(() => {
        // close
        VueInner.closeProduct();
        // dispatch toastSuccess
        VueInner.$store.dispatch('toastSuccess', 'Produkt wurde erfolgreich gelöscht');
        // Load Products
        VueInner.$store.dispatch('loadProducts');
      }).catch((r) => {
      });
    },
    closeProduct() {
      this.$store.commit('closeProduct');
      this.$store.commit('closeModal', 'product');
    },
    checkIfIsNew() {
      if (this.productActive && this.productActive.id) {
        this.p = this.productActive;

        this.isNew = false;
      } else {
        this.p = {};
        this.isNew = true;
      }
    },
  },
  created() {
    this.checkIfIsNew();
  },
};
</script>
