<template>
  <div class="card">
    <div class="card-header card-header-inline">
      <template
        v-if="getConfigs && getConfigs.params_values && taskActive &&  (taskActive.state_id === getConfigs.params_values.issue_default_close)">
        <button class="checkCircleBtn btn-primary btn btn-sm" @click="uncheckTask(taskActive.id)"><i
          class="fa-duotone fa-check fa-xl"></i></button>
      </template>
      <template v-else>
        <button class="checkCircleBtn btn-outline-primary btn btn-sm" @click="checkTask(taskActive.id)"><i
          class="fa-duotone fa-check fa-xl"></i></button>
      </template>
      <h6 class="editLine" v-if="taskActive">{{
          taskActive.title
        }}</h6>
      <div class="btn-group float-end">
        <button class="btn  btn-sm" v-if="!showCommentNew" :tooltip='$t("tasks.comments.showEditDates")'
                @click="showEditDates = !showEditDates"
                :class="(showEditDates) ? 'btn-secondary' : 'btn-outline-secondary'"><i
          class="fa-duotone fa-history"></i></button>
        <button class="btn btn-outline-primary btn-sm" @click="showCommentNew = true;showEditDates = false;"
                v-if="!showCommentNew"><i
          class="fa-duotone fa-comment"></i> {{ $t("tasks.comments.newComment") }}
        </button>
      </div>
      <button class="btn btn-close float-end" @click="showCommentNew = false" v-if="showCommentNew"></button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <Createcomment :task-id="taskId" @close="closeComment"
                         v-if="showCommentNew || (commentActive && commentActive.comment)"/>
        </div>
      </div>
      <div class="comments" v-if="taskActive &&  taskActive.history && Object.keys(taskActive.history).length > 0">
        <div class="row">
          <div class="col">
            <div class="list-group">
              <template v-for="(times, time) in taskActive.history">
                <template v-for="(comment, historyType) in times">
                  <div class="list-group-item" :key="comment.id" v-if="historyType === 'comment' && comment.comment"
                       :class="(getUser.id === comment.crm_created_user_id) ? 'own' : 'other'">
                    <template v-if="( comment.id === setComment.id ) && editComment">
                      <Editor2 v-model="setComment.comment"></Editor2>
                      <div class="row">
                        <div class="col text-start mt-2">
                          <button @click="updateComment" class="btn btn-outline-primary btn-sm me-3"><i
                            class="fa-duotone fa-paper-plane"></i> {{ $t("globals.send") }}
                          </button>
                          <button @click="editComment = false; setComment = {};"
                                  class="btn btn-outline-secondary btn-sm me-3"><i
                            class="fa-duotone fa-x"></i> {{ $t("globals.cancel") }}
                          </button>
                        </div>
                        <div class="col">
                          <div class="btn-group ms-3 float-end mt-2">
                            <input type="radio" class="btn-check" id="radio-2" value="official"
                                   v-model="comment.official">
                            <label for="radio-2" class="btn btn-outline-primary btn-sm"><i
                              class="fa-duotone fa-comments"></i> Kommentar</label>
                            <input type="radio" class="btn-check" id="radio-1" value="not_official"
                                   v-model="comment.official">
                            <label for="radio-1" class="btn btn-outline-secondary btn-sm"><i
                              class="fa-duotone fa-note-sticky"></i> Interne Notiz</label>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="inlineCommentWrap" :class="(comment.official === 'official') ? 'official': ''">
                        <div class="infoLine w-100">
                          <template v-if="comment.crm_created_employee_id">
                            <small class="avatar"><img style="max-width: 40px"
                                                       :src="$filters.employeeIcon(getEmployees, comment.crm_created_employee_id, 'id')"
                                                       class="sketchAvatar">
                              {{
                                $filters.employeeName(getEmployees, comment.crm_created_employee_id)
                              }}</small>&nbsp;
                            <template v-if="comment.official === 'official'"><span class="indicatorIcon"
                                                                                   tooltip="Öffentlich"><i
                              class="ms-2 fa-duotone fa-comment-exclamation" style="color: #6f42c1;"></i></span>
                            </template>
                            <small class="ms-2">{{
                                $filters.formatDateTime(comment.crm_created_date)
                              }}</small>
                          </template>
                          <template v-else>
                            <small class="avatar"><img style="max-width: 40px"
                                                       :src="$filters.generateSVGFromName(comment.author)"
                                                       class="sketchAvatar">{{ comment.author }}</small>&nbsp;<span
                            class="indicatorIcon" style="color: #6f42c1;" tooltip="Vom Kunden"><i
                            class="fa-duotone fa-comments"></i></span><small class="ms-2">{{
                              $filters.formatDateTime(comment.crm_created_date)
                            }}</small>&nbsp;
                          </template>
                          <div class="btn-group ms-3" v-if="getUser.id === comment.crm_created_user_id">
                            <button class="btn btn-outline-primary btn-xs "
                                    :tooltip="this.$t('tasks.comments.editComment')" position="left"
                                    @click="showCommentNew = false;setUpdateComment(comment)" style="margin-top: -3px;">
                              <i
                                class="fa-duotone fa-edit"></i></button>
                            <button class="btn btn-outline-secondary btn-xs "
                                    :tooltip="this.$t('tasks.comments.deleteComment')" position="left"
                                    @click="deleteComment(comment.id)" style="margin-top: -3px;"><i
                              class="fa-duotone fa-trash"></i></button>
                          </div>
                        </div>
                        <div class="isComment" v-html="modifiedHtml(comment.comment)"></div>
                      </div>
                    </template>
                  </div>
                  <template v-else>
                    <div class="list-group-item onlyState" v-if="comment.change_type === 'customFile'">
                      <div class="inlineCommentWrap " style="cursor: pointer;"
                           @click="$router.push({name: 'TasksTabs', params:{ id: comment.issue_id, tab: 'attachments'}})">
                        <div class="infoLine w-100">
                          <template v-if="comment.crm_created_employee_id">
                            <small class="avatar me-2"><img style="max-width: 40px"
                                                            :src="$filters.employeeIcon(getEmployees, comment.crm_created_employee_id, 'id')"
                                                            class="sketchAvatar">
                              {{ $filters.employeeName(getEmployees, comment.crm_created_employee_id) }}</small>&nbsp;
                          </template>
                          <template v-else>
                            <small class="avatar"><img style="max-width: 40px"
                                                       :src="$filters.generateSVGFromName(comment.author)"
                                                       class="sketchAvatar">
                              {{ comment.author }}</small>&nbsp;
                          </template>
                          <template v-if="comment.change_type === 'customFile'"><i class="fa-duotone fa-paperclip"></i>
                          </template>
                          <span class="inline-comment"
                                v-html="modifiedHtml(comment.comment)"></span><small>{{ $filters.formatDateTime(comment.crm_created_date) }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item onlyState" v-if="showEditDates">
                      <div class="inlineCommentWrap">
                        <div class="infoLine w-100">
                          <template v-if="comment.crm_created_employee_id">
                            <small class="avatar me-2"><img style="max-width: 40px"
                                                            :src="$filters.employeeIcon(getEmployees, comment.crm_created_employee_id, 'id')"
                                                            class="sketchAvatar">
                              {{ $filters.employeeName(getEmployees, comment.crm_created_employee_id) }}</small>&nbsp;
                          </template>
                          <template v-else>
                            <small class="avatar"><img style="max-width: 40px"
                                                       :src="$filters.generateSVGFromName(comment.author)"
                                                       class="sketchAvatar">
                              {{ comment.author }}</small>&nbsp;
                          </template>
                          <template v-if="comment.change_type === 'issue'"><i class="fa-duotone fa-edit"></i></template>
                          <template v-if="comment.change_type === 'customFile'"><i class="fa-duotone fa-paperclip"></i>
                          </template>
                          <template v-if="comment.change_type === 'time'"><i class="fa-duotone fa-stopwatch"></i>
                          </template>
                          <span class="inline-comment"
                                v-html="modifiedHtml(comment.comment)"></span><small>{{ $filters.formatDateTime(comment.crm_created_date) }}</small>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Createcomment from "@/routing/core/Tasks/Layouts/Createcomment.vue";
import {mapGetters} from "vuex";
import Editor2 from "@/components/Editor2.vue";
import axios from "axios";

export default {
  name: "Comments",
  props: {
    taskId: ""
  },
  emits: ["update", "checkTask", "uncheckTask"],
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getUser", "getConfigs", "commentActive"]),
  },
  components: {
    Editor2,
    Createcomment
  },
  data() {
    return {
      editComment: false,
      setComment: {},
      showCommentNew: false,
      showEditDates: false
    }
  },
  methods: {
    modifiedHtml(description) {
      if (description) {
        description = description.replace(/\r\n/g, "<br>").replace(/\n/g, "<br>");
        description = description.replace(
          /\{attach:(.*?)\}/g,
          `<span onclick="handleClick('$1')" class="btn btn-outline-primary btn-sm">Datei öffnen $1</span>`
        ).replace(
          /\{ticket:(.*?)\}/g,
          `<span onclick="handleTicketLink('$1')" class="inlineTicketLink"><i class="fa-duotone fa-clipboard-list-check"></i> $1</span>`
        );
        return description;
      }
      return '';
    },
    setUpdateComment(comment) {
      this.setComment = comment;
      this.editComment = true;
    },
    checkTask() {
      this.$emit('checkTask');
    },
    uncheckTask(task) {
      this.$emit('uncheckTask');
    },
    closeComment() {
      this.showCommentNew = false;
      this.$store.commit("setComment", {})
    },
    deleteComment(commentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.showDropdown = false;
      this.$confirm(this.$t('tasks.comments.commentsTextDelete'), this.$t('tasks.comments.deleteComment'), 'warning', {
        confirmButtonText: this.$t('globals.yes'),
        cancelButtonText: this.$t('globals.no'),
        type: 'warning'
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment/" + commentId, {headers: headers})
          .then(() => {
            this.editComment = false;
            this.$emit("update");
          })
          .catch((error) => {
            console.log(error);
          });
      }).catch((r) => {
        console.log(r);
      });
    },
    updateComment() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {
        data: this.setComment
      }
      axios.put(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment/" + this.setComment.id, postContent, {headers: headers})
        .then((response) => {
          this.editComment = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openTicket(ticket) {
      const ticket_id = ticket.detail.id;
      this.$router.push({name: 'TasksTabs', params: {id: ticket_id, tab: 'details'}});
    }
  },
  created() {
    window.addEventListener("triggerOpenTicket", this.openTicket);
  },
  beforeDestroy() {
    window.removeEventListener("triggerOpenTicket", this.openTicket);
  }
}
</script>

