<template>
  <div class="draft-item headline">
  <div class="draftLineWrap" >
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title">
      <span @click="emitSort('month')"  :class="(sortDirections.month) ? 'active' : ''">Monat</span>
      <i v-if="sortDirections.month === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.month === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-default" >
      <span @click="emitSort('year')"  :class="(sortDirections.year) ? 'active' : ''">Jahr</span>
      <i v-if="sortDirections.year === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.year === 'desc'" class="fa-duotone fa-arrow-down"></i>

    </div>
    <div class="lineitem l-default">
      <span @click="emitSort('workdays')" :class="(sortDirections.workdays) ? 'active' : ''">Arbeitstage</span>
      <i v-if="sortDirections.workdays === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.workdays === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-default">
      <span @click="emitSort('created_on')" :class="(sortDirections.created_on) ? 'active' : ''">Erstellt</span>
      <i v-if="sortDirections.created_on === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.created_on === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-default">
      <span @click="emitSort('date_formatted')" :class="(sortDirections.date_formatted) ? 'active' : ''">Format Datum</span>
      <i v-if="sortDirections.date_formatted === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.date_formatted === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-default">
      <span @click="emitSort('created_by')" :class="(sortDirections.created_by) ? 'active' : ''">Ersteller</span>
      <i v-if="sortDirections.created_by === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.created_by === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        month: '',
        year: '',
        workdays: '',
        created_by: '',
        created_on: '',
        date_formatted: '',
        id: '',
      }

    }
  },
  props: {
    employee: Object,
  },
  computed: {
    ...mapGetters(["draftActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
