<template>

  <!-- checkall button: checks all checkboxes in each <Headline /> -->
  <Sepadetails  v-if="sepaActive && sepaActive.id"/>
  <Sort @sort="resortOrder($event)" />
  <div class="singleRequest" v-for="(sepa,key) in sepaList.data" :key="sepa.id">

    <div class="plugin-item">

    <Headline :sepa="sepa"   />
      </div>
  </div>
  <pagination list="sepas" listName="sepaList" filterName="sepasFilter"  />
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Sepadetails from "./Details";
import Headline from "@/routing/core/Sepa/Layouts/Headline";
import Sort from "@/routing/core/Sepa/Layouts/Sort.vue";
import $ from "jquery";
import Pagination from "@/filter/components/pagination.vue";
export default {
  name: 'SEPAOverview',

  computed: {
    ...mapGetters(["sepaList", "sepaActive", "sepaSelected"])
  },

  data() {
    return {
      sepas: [],
    }
  },
  components: {
    Pagination,
    Sort,
    Sepadetails, Headline
  },
  methods: {
    getSEPA() {
      this.$store.dispatch("sepasFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.sepas.ordering.value = payload.type;
        this.$store.state.filters.sepas.direction.value = payload.direction;
      } else {
        this.$store.state.filters.sepas.ordering.value = payload.type
      }
      this.getSEPA();
    },

    setSepa(sepa) {
      this.$store.commit("setSepa", sepa);
    },

    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    loadSepaDetails(id) {
      const sepa = this.sepaList.data.find(s => s.id === id);
      if (sepa) {
        this.setSepa(sepa);
      }
    }
  },
  watch: {
    '$route.params.id'(newId) {
      if (newId) {
        this.loadSepaDetails(newId);
      }
    }
  },
  created() {
    this.getSEPA();
    if (this.$route.params.id) {
      this.loadSepaDetails(this.$route.params.id);
    }

  },
}
</script>
