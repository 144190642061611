<template>
  <div class="event-list">
    <div class="mainscroller"
                       :class="(eventActive && Object.keys(eventActive).length > 0) ? 'active' : ''">
      <Sort @sort="resortOrder($event)" />
      <template v-if="$store.state.lists.events.loading">
        <preLoader height="70vh" style="background: transparent;position: relative;" class="inner" :loading="$store.state.lists.events.loading" />
      </template>
      <template v-else>
      <p class="text-sm text-secondary" v-if="!eventList.data.length">
        Es wurden keine Veranstaltungen gefunden.</p>
      <div class="eventWrap" v-for="event in eventList.data"
           :class="(eventActive && eventActive.id === event.id) ? 'activeEvent' : 'notActive'"
           :key="event.id">
        <Headline :event="event"/>
      </div>
      </template>
    </div>
    <pagination list="events" listName="eventList" filterName="eventsFilter"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Events/Layouts/Headline.vue";
import Pagination from "@/filter/components/pagination.vue";
import Sort from "@/routing/core/Events/Layouts/Sort.vue";
import $ from "jquery";
import preLoader from "@/components/Loading.vue";

export default {
  name: "EventsList",
  computed: {
    ...mapGetters(["eventList", "eventActive"]),
  },
  components: {
    preLoader,
    Sort,
    Headline,
    Pagination
  },

  methods: {

    getEvents(){
      this.$store.dispatch("eventsFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.events.ordering.value = payload.type;
        this.$store.state.filters.events.direction.value = payload.direction;
      } else {
        this.$store.state.filters.events.ordering.value = payload.type
      }
      this.getEvents();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },

  created() {
    if(this.$store.state.filters.events.ordering.value === "") {
      this.$store.state.filters.events.ordering.value = "start";
      this.$store.state.filters.events.direction.value = "asc";
      this.$store.state.filters.events.aktuell.value = 1;
    }
    this.$store.dispatch("setActiveEvent", null);
    this.$store.dispatch("eventsFilter");
  }
};
</script>
