<template>
  <div class="task-list">

      <Sort @sort="resortOrder($event)" />
    <template v-if="$store.state.lists.tasks.loading">
      <preLoader height="70vh" style="background: transparent;position: relative;" class="inner" :loading="$store.state.lists.tasks.loading" />
    </template>
    <template v-else>
      <p class="text-sm text-secondary" v-if="!taskList.data.length">
        {{ $t('tasks.list.tasksNotFound') }} </p>
      <div class="taskWrap" v-for="task in taskList.data"
           :class="(taskActive && taskActive.id === task.id) ? 'activeTask' : 'notActive'"
           :key="task.id">
        <Headline :task="task"/>
      </div>
    </template>
    <pagination list="tasks" listName="taskList" filterName="tasksFilter"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Tasks/Layouts/Headline.vue";
import Pagination from "@/filter/components/pagination.vue";
import Sort from "@/routing/core/Tasks/Layouts/Sort.vue";
import $ from "jquery";
import preLoader from "@/components/Loading.vue";

export default {
  name: "TasksList",
  props: {
    tasks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["taskList", "taskActive", "getUser"]),
  },
  components: {
    preLoader,
    Sort,
    Headline,
    Pagination
  },

  methods: {

    getTasks(){

      this.$store.dispatch("tasksFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.tasks.ordering.value = payload.type;
        this.$store.state.filters.tasks.direction.value = payload.direction;
      } else {
        this.$store.state.filters.tasks.ordering.value = payload.type
      }
      this.getTasks();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },

},
  created() {
    this.$store.dispatch("setTask", null);
    if(this.getUser && this.getUser.id){
      this.$store.state.filters.tasks.employee.value = this.getUser._isEmployee;

    }
    this.$store.dispatch("tasksFilter");
  },
};
</script>
