<template>
  <div class="draft-item headline">
  <div class="draftLineWrap" >
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-title">
      <span @click="emitSort('cost_title')"  :class="(sortDirections.cost_title) ? 'active' : ''">Titel</span>
      <i v-if="sortDirections.cost_title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.cost_title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-default">
      <span @click="emitSort('cost_number')" :class="(sortDirections.cost_number) ? 'active' : ''">Nummer</span>
      <i v-if="sortDirections.cost_number === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.cost_number=== 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-default" >
      <span @click="emitSort('cost_type')"  :class="(sortDirections.cost_type) ? 'active' : ''">Typ</span>
      <i v-if="sortDirections.cost_type === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.cost_type === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>



    <div class="lineitem l-description">
      <span>Beschreibung</span>

    </div>

    <div class="lineitem l-default ml-auto">
      <span @click="emitSort('cost_employe_id')" :class="(sortDirections.cost_employe_id) ? 'active' : ''">Mitarbeiter</span>
      <i v-if="sortDirections.cost_employe_id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.cost_employe_id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        cost_title: '',
        cost_type:'',
        cost_number: '',
        cost_description_in: '',
        cost_description_out: '',
        cost_employe_id: '',
        id:'',
      }

    }
  },
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["draftActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
