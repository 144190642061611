<template>
  <Modal>
    <div class="modal show"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            Passwort ändern
            <div class="btn-group float-end">
              <button type="button" class="btn btn-outline-secondary btn-sm" @click="$emit('close')"><i class="fa-duotone fa-x"></i> Abbrechen</button>
              <button type="button" class="btn btn-outline-primary btn-sm" @click="updatePassword"><i class="fa-duotone fa-floppy-disk"></i> Speichern</button>
            </div>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Altes Passwort</label>
              <div class="input-group">
                <template v-if="oldPasswordShow">
                  <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                  <input type="text" class="form-control form-control-lg"
                         v-model="oldpassword" placeholder="Altes Passwort"
                  />
                  <span class="passwordEye" @click="showOldPassword"><i class="fa-duotone fa-eye-slash"></i></span>
                </template>
                <template v-else>
                  <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                  <input type="password" class="form-control form-control-lg"
                         v-model="oldpassword"
                         placeholder="Altes Passwort"
                  />
                  <span class="passwordEye" @click="showOldPassword"><i class="fa-duotone fa-eye"></i></span>
                </template>
              </div>

            </div>
            <div class="form-group">
              <label>Neues Passwort</label>
              <div class="input-group">
                <template v-if="newPasswordShow">
                  <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                  <input type="text" class="form-control form-control-lg"
                         v-model="newpassword" placeholder="Neues Passwort"
                  />
                  <span class="passwordEye" @click="showNewPassword"><i class="fa-duotone fa-eye-slash"></i></span>
                </template>
                <template v-else>
                  <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                  <input type="password" class="form-control form-control-lg"
                         v-model="newpassword"
                         placeholder="Neues Passwort"
                  />
                  <span class="passwordEye" @click="showNewPassword"><i class="fa-duotone fa-eye"></i></span>
                </template>
              </div>
            </div>
            <div class="form-group">
              <label>Neues Passwort bestätigen</label>

              <div class="input-group">
                <template v-if="newPasswordVerifyShow">
                  <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                  <input type="text" class="form-control form-control-lg"
                         v-model="newpasswordverify" placeholder="Neues Passwort bestätigen"
                  />
                  <span class="passwordEye" @click="showNewPasswordVerify"><i class="fa-duotone fa-eye-slash"></i></span>
                </template>
                <template v-else>
                  <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                  <input type="password" class="form-control form-control-lg"
                         v-model="newpasswordverify"
                         placeholder="Neues Passwort bestätigen"
                  />
                  <span class="passwordEye" @click="showNewPasswordVerify"><i class="fa-duotone fa-eye"></i></span>
                </template>
              </div>
            </div>
            <div class="form-group">
              <label>2FA Code</label>
              <input type="text" class="form-control" placeholder="2FA Code (falls aktiv)" v-model="twofactor">
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Password",
  emits: ['close'],
  data() {
    return {
      showMenu: false,
      oldpassword: '',
      newpassword: '',
      newpasswordverify: '',
      twofactor: '',
      oldPasswordShow: false,
      newPasswordShow: false,
      newPasswordVerifyShow: false
    };
  },
  components: {},
  computed: {
    ...mapGetters(['getUser'])
  },
  methods: {
    updatePassword() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "user/password" , {
        oldPassword: this.oldpassword,
        newPassword: this.newpassword,
        newPasswordVerify: this.newpasswordverify,
        twoFactor: this.twofactor
      }, {headers: headers})
        .then((response) => {
          if (response.data.result.success === true) {
            this.$emit('close');
            this.$store.dispatch("toast", {message: "Das Passwort wurde erfolgreich geändert.", type: "success"})
          } else {
            this.$store.dispatch("handleApiResponse", response);
          }
        })
        .catch((error) => {
          this.$store.dispatch("handleApiResponse", error.response);
        });
    },
    showOldPassword() {
      this.oldPasswordShow = !this.oldPasswordShow;
    },
    showNewPassword() {
      this.newPasswordShow = !this.newPasswordShow;
    },
    showNewPasswordVerify() {
      this.newPasswordVerifyShow = !this.newPasswordVerifyShow;
    }
  },
};
</script>
<style>
.modal {
  z-index: 10000 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}
</style>
