<template>
  <div class="card">
    <div class="card-header card-header-inline">
      <h6 class="editLine">{{
          event.title
        }}</h6>
      <div class="btn-group float-end">
        <button class="btn btn-outline-primary btn-sm" @click="addSubevent"><i
          class="fa-duotone fa-list-tree"></i> Neue Unterveranstaltung
        </button>
      </div>
    </div>
    <div class="card-body">
      <template v-if="addSubEvent">
        <h6 class="editLine subevent" ref="editMe" contenteditable @keypress.enter.prevent="editItem('title')" @keydown.esc="addSubEvent= false">{{
            subevent.title
          }}</h6>
      </template>
      <List :events="subevents" />

    </div>
  </div>
</template>
<script>
import axios from "axios";
import List from "@/routing/core/Events/Sublist";

export default {
  name: "Subtasks",

  props: {
    event: {
      type: Object
    }
  },
  components: {
    List
  },
  data() {
    return {
      subevents: [],
      addSubEvent: false,
      subevent: {
        title: ''
      }
    };
  },
  methods: {
    addSubevent() {
      this.addSubEvent = true;
      // timeout
      this.$nextTick(() => {
        this.$refs.editMe.focus();
      });

    },
    editItem(field) {
      const value = event.target.innerText;
      this.subevent[field] = value;
      this.postSubEvent();
    },

    postSubEvent() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};

      const title = this.subevent.title;

      let postContent = {
        title: title,

      }
      axios.post(this.$store.state.apiUrl + 'event/'+this.event.id+'/createSubevent', postContent, {headers})
        .then(response => {

          this.addSubEvent = false;
          this.subevent.title = '';
          this.getSubevents(this.event.id);
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
    getSubevents(id){
      // if taskis is object
      if(typeof id === 'object')
      {
        id = id.detail;
      }
      if(id === undefined){
        id = this.$route.params.id;
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + 'event/' + id , {headers})
        .then(response => {
          this.subevents = response.data.result.data.subevents;

        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
      return true;
    }
  },
  mounted() {
     this.getSubevents(this.event.id);
  },


}
</script>
<style scoped>
.editLine.subtask{
  border-bottom: 1px solid #333;
  width: 100%;
  min-height: 24px;
}
</style>
