<template>
  <div class="statsSingle newCustomers redView">
    <h5>Neue Kunden</h5>
    <!-- Datum von bis filter -->
    <div class="filters">
      <div class="row">
        <div class="col">
          <label for="employee" class="w-100">Mitarbeiter</label>
          <select id="employee" class="form-select" v-model="filters.employee">
            <option :value="null">Alle Mitarbeiter</option>
            <option :value="emp.id" v-for="emp in getEmployees">{{ emp.e_name }}</option>
          </select>
        </div>
        <div class="col">
          <label for="from">1. Zeitraum: Von</label>
          <VueDatePicker v-model="filters.firstRow.from" model-type="yyyy-MM-dd" format="dd.MM.yyyy" :placeholder="Von"
                         :auto-apply="true" :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                         cancel-text="X" select-text="Wählen" :teleport="true">
          </VueDatePicker>
        </div>
        <div class="col">
          <label for="to">Bis</label>
          <VueDatePicker v-model="filters.firstRow.to" model-type="yyyy-MM-dd" format="dd.MM.yyyy" :placeholder="Bis"
                         :auto-apply="true" :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                         cancel-text="X" select-text="Wählen" :teleport="true">
          </VueDatePicker>
        </div>
        <div class="col">
          <label for="from">2. Zeitraum: Von</label>
          <VueDatePicker v-model="filters.secondRow.from" model-type="yyyy-MM-dd" format="dd.MM.yyyy" :placeholder="Von"
                         :auto-apply="true" :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                         cancel-text="X" select-text="Wählen" :teleport="true">
          </VueDatePicker>
        </div>
        <div class="col">
          <label for="to">Bis</label>
          <VueDatePicker v-model="filters.secondRow.to" model-type="yyyy-MM-dd" format="dd.MM.yyyy" :placeholder="Bis"
                         :auto-apply="true" :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                         cancel-text="X" select-text="Wählen" :teleport="true">
          </VueDatePicker>
        </div>
      </div>
    </div>
    <div class="resultPieChart">
      <div class="row">
        <div class="columns xlarge-6 large-6 medium-12 small-12"
             v-if="chartData.firstRow.country.labels && chartData.firstRow.country.labels.length">
          <h5>Zeitraum #1</h5>
          <div class="chartWrap mb-3">
            <h5>Nach Land</h5>
            <pie-chart v-if="chartData.firstRow.country.labels" :chartData="chartData.firstRow.country"
                       class="boxWhite"/>
            <!--            <bar-chart v-if="chartData.firstRow.country.labels" :chartData="chartData.firstRow.country" class="boxWhite" />-->
            <!--            <line-chart v-if="chartData.firstRow.country.labels" :chartData="chartData.firstRow.country" class="boxWhite"  />-->


          </div>
          <div class="chartWrap mb-3">
            <h5>Nach Mitarbeiter</h5>
            <pie-chart v-if="chartData.firstRow.employee.labels" :chartData="chartData.firstRow.employee"
                       class="boxWhite"/>

          </div>
          <div class="chartWrap mb-3">
            <h5>Nach Typ</h5>
            <pie-chart v-if="chartData.firstRow.type.labels" :chartData="chartData.firstRow.type" class="boxWhite"/>

          </div>
          <div class="chartWrap table mb-3">
            <StatsTable :tableData="chartData.firstRow.country.plainData" />
          </div>
        </div>
        <div class="columns xlarge-6 large-6 medium-12 small-12" v-else>Keine Daten</div>
        <div class="columns xlarge-6 large-6 medium-12 small-12" v-if="chartData.secondRow">
          <h5>Zeitraum #2</h5>
          <div class="chartWrap mb-3">
            <h5>Nach Land</h5>
            <pie-chart v-if="chartData.secondRow.country.labels" :chartData="chartData.secondRow.country"
                       class="boxWhite"/>
            <!--            <bar-chart v-if="chartData.secondRow.country.labels" :chartData="chartData.secondRow.country" class="boxWhite" />-->
            <!--            <line-chart v-if="chartData.secondRow.country.labels" :chartData="chartData.secondRow.country" class="boxWhite"  />-->
          </div>
          <div class="chartWrap mb-3">
            <h5>Nach Mitarbeiter</h5>
            <pie-chart v-if="chartData.secondRow.employee.labels" :chartData="chartData.secondRow.employee"
                       class="boxWhite"/>
          </div>
          <div class="chartWrap mb-3">
            <h5>Nach Typ</h5>
            <pie-chart v-if="chartData.secondRow.type.labels" :chartData="chartData.secondRow.type" class="boxWhite"/>
          </div>
          <div class="chartWrap table mb-3">
            <StatsTable :tableData="chartData.secondRow.country.plainData" />
          </div>
        </div>
      </div>
    </div>
    <div class="resultTable">
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {mapGetters} from "vuex";
import {LineChart, PieChart, BarChart} from 'vue-chart-3';
import VueDatePicker from '@vuepic/vue-datepicker';
import StatsTable from "@/routing/core/Stats/Statistics/StatsTable";
export default {
  name: 'newCustomersStats',
  components: {VueDatePicker, LineChart, PieChart, BarChart, StatsTable},
  computed:
    {
      ...mapGetters(["getEmployees"])
    },
  data() {
    return {
      stats: null,
      filters: {
        firstRow: {
          from: null,
          to: null,
        },
        secondRow: {
          from: null,
          to: null,
        },
        employee: null,
      },
      chartData: {
        firstRow: {
          country: {},
          employee: {},
          type: {},
        },
        secondRow: {
          country: {},
          employee: {},
          type: {},
        },
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#fbfbfb",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    }
  },
  created() {
    this.getStat();
  },
  methods: {
    generateRandomBackgroundColor() {
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },
    fillDefaultColors(data) {
      let i;
      for (i in data.firstRow.country) {
        data.firstRow.country[i].backgroundColor = this.generateRandomBackgroundColor();
      }
      return data;
    },
    getStat() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      // filters as string
      let filters = "";
      if (!this.filters.firstRow.from) {
        // current month first day YYYY-MM-DD
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
        this.filters.firstRow.from = firstDay.toISOString().slice(0, 10);
      }
      if (!this.filters.firstRow.to) {
        // current month last day YYYY-MM-DD
        let date = new Date();
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.filters.firstRow.to = lastDay.toISOString().slice(0, 10);
      }
      filters += "?firstFrom=" + this.filters.firstRow.from;
      filters += "&firstTo=" + this.filters.firstRow.to;
      if (this.filters.secondRow.from) {
        filters += "&secondFrom=" + this.filters.secondRow.from;
      }
      if (this.filters.secondRow.to) {
        filters += "&secondTo=" + this.filters.secondRow.to;
      }
      if (this.filters.employee) {
        filters += "&employee=" + this.filters.employee;
      }
      axios.get(this.$store.getters.getApiUrl + "statistic/customers/new" + filters, {headers}).then((r) => {
        this.chartData = this.fillDefaultColors(r.data.result.data);
      }).catch((error) => {
        this.$store.dispatch("handleApiResponse", error.response);
      });
    }
  },
  watch: {
    filters: {
      handler: function () {
        this.getStat();
      },
      deep: true
    }
  }
}
</script>
