<template>
  <div class="plugin-item headline">
  <div class="pluginLineWrap" >
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-number">
      <span @click="emitSort('customerNumber')" :class="(sortDirections.customerNumber) ? 'active' : ''">Nummer</span>
      <i v-if="sortDirections.customerNumber === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.customerNumber === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title">
      <span @click="emitSort('title')"  :class="(sortDirections.title) ? 'active' : ''">Titel</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title" >
      <span @click="emitSort('owner')"  :class="(sortDirections.owner) ? 'active' : ''">Besitzer</span>
      <i v-if="sortDirections.owner === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.owner === 'desc'" class="fa-duotone fa-arrow-down"></i>

    </div>
    <div class="lineitem l-iban">
      <span @click="emitSort('iban')" :class="(sortDirections.iban) ? 'active' : ''">IBAN</span>
      <i v-if="sortDirections.iban === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.iban === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>


    <div class="lineitem l-date ml-auto">
      <span @click="emitSort('crm_modified_date')" :class="(sortDirections.crm_modified_date) ? 'active' : ''">Datum</span>
      <i v-if="sortDirections.crm_modified_date === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.crm_modified_date === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem last ms-3  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        owner: '',
        iban: '',
        bic: '',
        institute: '',
        crm_modified_date: '',
        id: '',
        customerNumber:'',
      }

    }
  },
  props: {
    sepa: Object,
  },
  computed: {
    ...mapGetters(["sepaActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
