<template>
  <div class="row" v-if="stats && stats.data">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
          <label for="dateSelect">Datum wählen: </label>
          <select id="dateSelect" class="form-select" v-model="selectedDate" @change="updateChart">
            <option v-for="item in stats.data" :key="item.date" :value="item.date">
              {{ $filters.formatDate(item.date) }}
            </option>
          </select>
            </div>
          <div class="container">
          <BarChart :chartData="barChartStats" :options="barChartOptions" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BarChart } from "vue-chart-3";

export default {
  name: "CancelView",
  components: {
    BarChart
  },
  props: {
    stats: {
      type: Object,
      default: () => ({ data: [] })
    },
    compareDate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedDate: '', // This will store the selected date
      stateNames:{
        "declined": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Nicht eingereicht"},
        "declined-02": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Voraussetzungen nicht erfüllt"},
        "declined-03": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Korrigierte Punkte"},
        "declined-04": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Ungeprüft zurückgewiesen"},
        "declined-05": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Zu wenig Punkte"},
        "deleted": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Bewerbung zurückgezogen"},
      },
      barChartStats: {
        labels: [],
        datasets: [
          {
            label: 'Cancellation Count',
            data: [],
            backgroundColor: '#188360',
          }
        ]
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false, // show legend for better understanding
          },
          tooltip: {
            interaction: {}
          }
        },
      },
    };
  },
  methods: {
    prepareChartData(date) {
      const labels = [];
      const countData = [];

      const selectedData = this.stats.data.find(item => item.date === date);

      if (selectedData) {
        for (const key in selectedData.data) {
          if (selectedData.data.hasOwnProperty(key)) {
            const reason = selectedData.data[key];
            const labelText = this.stateNames[key]?.text || reason.name; // Use the text from stateNames or fallback to the original name
            labels.push(labelText);
            countData.push(reason.count);
          }
        }

        this.barChartStats.labels = labels;
        this.barChartStats.datasets[0].data = countData;

        // Dynamically calculate height based on the number of labels
        const numberOfLabels = labels.length;
        const pixelsPerLabel = 30; // Adjust this value to control spacing
        const chartHeight = numberOfLabels * pixelsPerLabel;

        // Apply the height to the chart container

      }
    },
    updateChart() {
      this.prepareChartData(this.selectedDate);
    }
  },
  mounted() {
    if (this.stats.data.length > 0) {
      // Find the closest match to the compareDate (ignoring time for simplicity)
      const defaultDate = this.stats.data.find(item => item.date.startsWith(this.compareDate.split(' ')[0]));

      if (defaultDate) {
        this.selectedDate = defaultDate.date;
      } else {
        this.selectedDate = this.stats.data[0].date; // Fallback to the first available date
      }

      this.prepareChartData(this.selectedDate);
    }
  }
};

</script>
