<template>
  <router-link :to="url" class="nav-link" v-bind="$attrs"  >

    <span
      class="nav-link-text"
      :class="this.$store.state.isRTL ? ' me-1' : 'ms-1'"
      >{{ navText }}</span
    > <i :class="bicon" class="fa-duotone" :title="navText" ></i>
  </router-link>
</template>
<script>
export default {
  name: "sidenav-item",
  props: {
    url: {
      type: String,
      required: true
    },
    navText: {
      type: String,
      required: true
    },
    bicon: {
      type: String,
      required: true
    }
  }
};
</script>
