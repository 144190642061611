<template>
  <div class="plugin-item headline">
  <div class="pluginLineWrap" >
    <div class="lineitem l-number">
      <span @click="emitSort('type')" :class="(sortDirections.type) ? 'active' : ''">Typ</span>
      <i v-if="sortDirections.type === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.type === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title">
      <span @click="emitSort('title')"  :class="(sortDirections.title) ? 'active' : ''">Titel</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-date" >
      <span @click="emitSort('created_on')"  :class="(sortDirections.created_on) ? 'active' : ''">Erstellt</span>
      <i v-if="sortDirections.created_on === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.created_on === 'desc'" class="fa-duotone fa-arrow-down"></i>

    </div>
    <div class="lineitem l-date">
      <span @click="emitSort('version')" :class="(sortDirections.version) ? 'active' : ''">Version</span>
      <i v-if="sortDirections.version === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.version === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-avatar">
      <span @click="emitSort('crm_modified_user_id')" :class="(sortDirections.crm_modified_user_id) ? 'active' : ''">Ersteller</span>
      <i v-if="sortDirections.crm_modified_user_id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.crm_modified_user_id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem actions" >

    </div>
    <div class="lineitem l-actions ml-auto">

    </div>
    <div class="lineitem l-actions ">
      </div>
    <div class="lineitem l-avatar"></div>
    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        type: '',
        created_on: '',
        version: '',
        crm_modified_user_id: '',
        id: '',
      }

    }
  },
  props: {
    legals: Object,
  },
  computed: {
    ...mapGetters(["legalActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
