<template>

  <div class="row mt-1 document-item">

    <div class="col-md-1">
      <p class="text-sm mb-0 font-weight-bold">{{ documentItem.di_ordering }}</p>
    </div>

    <div class="col-md-7">
      <p class="text-sm mb-0 font-weight-bold">{{ documentItem.di_title }}</p>
      <p class="text-xs text-secondary mb-0 pe-4" v-html="$filters.nl2br(documentItem.di_description)"></p>
    </div>

    <div class="col-md-1">
      <p class="text-sm text-end mb-0 font-weight-bold">{{ documentItem.di_quantity }}</p>
    </div>
    <div class="col-md-1">
      <p class="text-sm mb-0 font-weight-bold">{{ documentItem.di_unit }}</p>
    </div>

    <div class="col-md-1">
      <p class="text-sm text-end font-weight-bold">{{ $filters.priceEuro(documentItem.di_base_price) }}</p>
    </div>
    <div class="col-md-1">
      <p class="text-sm text-end font-weight-bold">{{ $filters.priceEuro(documentItem.di_sum_price) }}</p>
    </div>



  </div>

</template>

<script>
export default {
  name: "document-item",
  props: {
    documentItem: {}
  }
};
</script>