<template>
  <div class="taskLineWrap">
    <div class="d-flex">
      <div class="lineitem l-check">
        <template
          v-if="getConfigs && getConfigs.params_values && (task.state_id === getConfigs.params_values.issue_default_close)">
          <button class="checkCircleBtn btn-primary btn btn-sm" @click="uncheckTask(task)"><i
            class="fa-duotone fa-check fa-xl"></i></button>
        </template>
        <template v-else>
          <button class="checkCircleBtn btn-outline-primary btn btn-sm" @click="checkTask(task)"><i
            class="fa-duotone fa-check fa-xl"></i></button>
        </template>
      </div>
      <div class="lineitem l-title" @click="setTask(task, 'details')">
        <template v-if="task.parent_issue && !this.$route.params.id"><i class="fa-duotone fa-list-tree"></i></template>
        {{ task.title }}
      </div>
      <div class="lineitem l-subtasks" @click="setTask(task, 'subtasks')">
        <template v-if="task._countIssues > 0">
          <i class="fa-duotone fa-list-tree"></i><span> <span class="incNumber">{{ task._countIssues }}</span></span>
        </template>
      </div>
      <div class="lineitem l-attachments" @click="setTask(task, 'attachments')">
        <template v-if="task._countAttachments > 0">
          <i class="fa-duotone fa-paperclip"></i><span> <span class="incNumber">{{
            task._countAttachments
          }}</span></span>
        </template>
      </div>
      <div class="lineitem l-comments" @click="setTask(task, 'comments')">
        <template v-if="task._countComments > 0">
          <i class="fa-duotone fa-comments"></i><span> <span class="incNumber">{{ task._countComments }}</span></span>
        </template>
      </div>
      <div class="lineitem l-times">
        <template v-if="task._sumTimesComplete > 0">
          <i class="fa-duotone fa-stopwatch"></i><span> <span class="incNumber">{{
            parseInt(task._sumTimesComplete)
          }}</span></span>
        </template>
      </div>
      <div class="lineitem l-prio" @click="setEditPrio(task)"><span :tooltip="task.issuePriorityTitle"
                                                                    :position="'left'"
                                                                    :style="'color:'+task.issuePriorityColor"><i
        class="fa-duotone"
        :class="task.issuePriorityIcon"></i></span>
      </div>
      <div class="lineitem l-state" @click="setEditState(task)"><span :tooltip="task.issueStateTitle" :position="'left'"
                                                                      :style="'color:'+task.issueStateColor"><i
        class="fa-duotone"
        :class="task.issueStateIcon"></i></span>
      </div>
      <div class="lineitem l-date duedate" @click="setEditDate(task)"
           :class="(task.due_date < today) ? 'due' : (task.due_date === today) ? 'today' : ''"
           :tooltip="'Abgabedatum'" :position="'left'">{{ $filters.formatDate(task.due_date) }}
      </div>
      <div class="lineitem l-customer" style="width: 270px">
        <template v-if="task.c_company">
        {{ task.c_company }}
        </template>
        <template v-else>
          <template v-if="task.c_firstname && task.c_lastname">{{task.c_firstname}} {{task.c_lastname}}</template>
          <template v-else>{{task.c_email}}</template>
        </template>
        <template v-if="task.projectTitle">
          <br>&raquo; {{ task.projectTitle }}
        </template>
      </div>
      <div class="lineitem l-date lastChange"
           :tooltip="'Letzte Änderung'" :position="'left'">{{ $filters.formatDateTime(task._lastChange) }}
      </div>
      <div class="lineitem l-id">
        <span style="font-size: 0.75rem">{{ task.id }}</span>
      </div>
      <div class="lineitem l-avatar ml-auto">
        <template v-if="task.infos && task.infos.followers && task.infos.followers.outer">
          <small :tooltip="follower.c_firstname + ' ' + follower.c_lastname" :position="'left'" class="avatar me-1"
                 v-for="follower in task.infos.followers.outer"><img style="max-width: 40px"
                                                                     :src="$filters.generateSVGFromName(follower.c_firstname + ' ' + follower.c_lastname)"
                                                                     class="sketchAvatar"/></small>
        </template>
        <span @click="setEditEmployee(task)">
          <small :tooltip="$filters.employeeName(getEmployees, task.employee_id, 'id')" :position="'left'">
        <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, task.employee_id, 'id')"
             class="sketchAvatar" v-if="task.employee_id"></small></span>
      </div>
      <div class="lineitem l-actions last">
        <template v-if="taskActive && taskActive.id === task.id">
          <i class="fa-duotone fa-x" @click="closeTask"></i>
        </template>
        <template v-else>
        <span @click="setTask(task)"><i class="fa-duotone fa-chevrons-up"></i>
        </span></template>
      </div>
    </div>
  </div>
  <Modal v-if="editPrio">
    <div class="modal fade show " tabindex="-1"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Priorität ändern</h5>
            <button type="button" class="btn-close" @click="editPrio = false; setPrio = 0; saveTask = {}"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="prio in getConfigs.issue_prios">
                <div class="col-3">
                  <div class="prioSetter" @click="setPrio = prio.id">
                    <template v-if="prio.id === setPrio">
                      <button type="button" class="btn btn-sm mb-2 w-100   " :position="'left'"
                              :style="'border: 0;color:#fff; background-color:'+prio.color"
                              @click="updateTaskWith('priority_id', prio.id)"><i
                        class="fa-duotone"
                        :class="prio.icon"></i> <span>{{ prio.title }}</span></button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn btn-outline-secondary btn-sm mb-2 w-100   " :position="'left'"
                              :style="'border-color: '+prio.color+';color:'+prio.color"
                              @click="updateTaskWith('priority_id', prio.id)"><i
                        class="fa-duotone"
                        :class="prio.icon"></i> <span>{{ prio.title }}</span></button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="editState">
    <div class="modal fade show " tabindex="-1"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Status ändern</h5>
            <button type="button" class="btn-close" @click="editState = false; setState = 0; saveTask = {}"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="state in getConfigs.issue_states">
                <div class="col-3">
                  <div class="prioSetter" @click="setState = state.id">
                    <template v-if="state.id === setState">
                      <button type="button" class="btn mb-2 w-100 btn-sm  " :position="'left'"
                              :style="'border: 0;color:#fff; background-color:'+state.color"
                              @click="updateTaskWith('state_id', state.id)"><i
                        class="fa-duotone"
                        :class="state.icon"></i> <span>{{ state.title }}</span></button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn w-100 mb-2 btn-sm btn-outline-secondary   " :position="'left'"
                              :style="'border-color: '+state.color+';color:'+state.color"
                              @click="updateTaskWith('state_id', state.id)"><i
                        class="fa-duotone"
                        :class="state.icon"></i> <span>{{ state.title }}</span></button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="editDate">
    <div class="modal fade show " tabindex="-1"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-sm ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Status ändern</h5>
            <button type="button" class="btn-close" @click="editDate = false; setDate = false; saveTask = {}"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <VueDatePicker class="small" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                               placeholder="Datum" :auto-apply="true" :close-on-auto-apply="true"
                               locale="de" :enable-time-picker="false" cancel-text="X" inline
                               :select-text="this.$t('globals.choose')"
                               v-model="setDate"></VueDatePicker>
                <button class="btn btn-outline-primary btn-sm me-2" @click="setDate = today">Heute</button>
                <button class="btn btn-outline-primary btn-sm me-2" @click="setDate = nextWeek">+1 Woche</button>
                <button class="btn btn-outline-primary btn-sm " @click="setDate = nextMonth">+1 Monat</button>
                <hr>
                <button type="button" class="btn btn-outline-primary mt-3" @click="updateTaskWith('due_date', setDate)">
                  <i class="fa-duotone fa-floppy-disk"></i> Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="editEmployee">
    <div class="modal fade show " tabindex="-1"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mitarbeiter ändern</h5>
            <button type="button" class="btn-close" @click="editEmployee = false; saveTask = {}"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="employee in getEmployees">
                <div class="col-3" v-if="employee.status === 'active' && employee.use_in_issues === 'yes'">
                  <div class="prioSetter" @click="setEmployee = employee.id">
                    <template v-if="employee.id === setEmployee">
                      <button type="button" class="btn mb-2 w-100 btn-sm  " :position="'left'"
                              :style="'border: 0;color:#fff; background-color:'+employee.color"
                              @click="updateTaskWith('employee_id', employee.id)"><img style="max-width: 40px"
                                                                                       :src="$filters.employeeIcon(getEmployees, employee.id, 'id')"
                                                                                       class="sketchAvatar"
                                                                                       v-if="employee.id"> <span
                        class="shortName">{{ employee.e_name }}</span></button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn w-100 mb-2 btn-sm btn-outline-secondary   " :position="'left'"
                              :style="'border-color: '+employee.color+';color:'+employee.color"
                              @click="updateTaskWith('employee_id', employee.id)">
                        <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, employee.id, 'id')"
                             class="sketchAvatar" v-if="employee.id"> <span class="shortName">{{
                          employee.e_name
                        }}</span></button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import router from "@/router";
import axios from "axios";
import VueDatePicker from '@vuepic/vue-datepicker';
import {inject} from 'vue';

export default {
  name: "Headline",
  props: {
    task: Object,
  },
  computed: {
    ...mapGetters(["taskActive", "getEmployees", "customerActive", "getConfigs"])
  },
  components: {
    VueDatePicker
  },
  data() {
    return {
      editPrio: false,
      editState: false,
      editDate: false,
      setPrio: 0,
      setDate: false,
      editEmployee: false,
      setState: 0,
      saveTask: {},
      today: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().slice(0, 10),
      nextWeek: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
      nextMonth: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
    }
  },
  methods: {
    setTask(task, subview) {
      if (subview === undefined) {
        subview = "details";
      }
      // Unset if already set:
      if (this.taskActive === task.id) {
        // this.$store.dispatch('setTask', {})
        this.$router.push({name: "Tasks"});
      } else {
        this.$router.push({name: "TasksTabs", params: {id: task.id, tab: subview}})
      }
    },
    setEditPrio(task) {
      this.editPrio = true;
      this.setPrio = task.priority_id;
      this.saveTask = task;
    },
    setEditState(task) {
      this.editState = true;
      this.setState = task.state_id;
      this.saveTask = task;
    },
    setEditDate(task) {
      this.editDate = true;
      if (task.due_date) {
        // Append 'Z' to treat the date as UTC
        this.setDate = new Date(task.due_date + 'Z').toISOString().slice(0, 10);
      } else {
        this.setDate = new Date().toISOString().slice(0, 10);
      }
      this.saveTask = task;
    },
    setEditEmployee(task) {
      this.editEmployee = true;
      this.setEmployee = task.employee_id;
      this.saveTask = task;
    },
    closeTask() {
      this.$store.dispatch("tasksFilter");
      this.$store.dispatch('setTask', null);
      this.$router.push({name: "Tasks"});
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    updateTaskWith(type, value) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let task = this.saveTask;
      let postContent = {
        data: {},
        hash: task.customerHash
      }
      postContent.data[type] = value;
      axios.put(this.$store.state.apiUrl + 'issue/' + task.id, postContent, {headers})
        .then(response => {
          if (this.$route.params && this.$route.params.id) {
            this.$store.dispatch("tasksFilterCustomer");
          } else {
            this.$store.dispatch("tasksFilter");
          }
          if (task.parent_issue) {
            window.dispatchEvent(new CustomEvent('reloadSubtasks', {detail: task.parent_issue}));
          }
          this.$store.dispatch('toastSuccess', this.$t("dataSaved." + type));
          this.editPrio = false;
          this.editState = false;
          this.saveTask = {};
          this.setPrio = 0;
          this.setState = 0;
          this.editDate = false;
          this.setDate = false;
          this.editEmployee = false;
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
    checkTask(task) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.task.customerHash;
      let postContent = {
        data: task,
        hash: hash
      }
      postContent.data.state_id = parseInt(this.getConfigs.params_values.issue_default_close);
      axios.put(this.$store.state.apiUrl + 'issue/' + task.id, postContent, {headers})
        .then(response => {
          if (this.$route.params && this.$route.params.id) {
            this.$store.dispatch("tasksFilterCustomer");
          } else {
            this.$store.dispatch("tasksFilter");
          }
          if (task.parent_issue) {
            window.dispatchEvent(new CustomEvent('reloadSubtasks', {detail: task.parent_issue}));
          }
          window.dispatchEvent(new CustomEvent('task-checked'));
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
    uncheckTask(task) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.task.customerHash;
      let postContent = {
        data: task,
        hash: hash
      }
      postContent.data.state_id = parseInt(this.getConfigs.params_values.issue_default_open);
      axios.put(this.$store.state.apiUrl + 'issue/' + task.id, postContent, {headers})
        .then(response => {
          if (this.$route.params && this.$route.params.id) {
            this.$store.dispatch("tasksFilterCustomer");
          } else {
            this.$store.dispatch("tasksFilter");
          }
          if (task.parent_issue) {
            window.dispatchEvent(new CustomEvent('reloadSubtasks', {detail: task.parent_issue}));
          }
          window.dispatchEvent(new CustomEvent('task-unchecked'));
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    }
  },
}
</script>
