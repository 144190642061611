<template>
  <div class="appView documents">
    <Details  v-if="$route.params.id"/>
    <List  />
  </div>
</template>
<script>

import List from "@/routing/core/Documents/List";
import Details from "@/routing/core/Documents/Details.vue";

export default {
  name: "Documents",
  components: {
    Details,
    List,
  },
  created: function () {


    this.$store.dispatch("documentsFilter");
    this.$store.dispatch("setActiveDocument", null);

    this.$store.dispatch("setActiveCustomer", null);
  },
  watch: {
    '$route': function () {
      if (!this.$route.params.id) {

      this.$store.dispatch("documentsFilter");
      this.$store.dispatch("setActiveDocument", null);
        this.$store.dispatch("setActiveCustomer", null);
      }
    }
  }


};
</script>
