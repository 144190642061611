<template>
  <ul class="navbar-nav">
    <li class="filter-item" v-for="filter in $store.state.filters.tasks">
      <template v-if="filter.name ==='customer'">
        <template v-if="$store.state.filters.tasks.customer.value ===''">
          <customersearch class="small" v-model="$store.state.filters.tasks.customer.value" :onlyid="true"
                          @changedCustomer="updateValue()"/>
        </template>
        <template v-else>
          <button class="btn btn-outline-primary btn-sm w-100" style="margin-bottom: 12px;"
                  @click="$store.state.lists.tasks.offset = 0;$store.state.filters.tasks.customer.value = '';$store.state.filters.tempProjects = [];$store.state.filters.tasks.customer.value = ''; updateValue();">
            {{ $t('reset.customerfilter') }}
          </button>
        </template>
      </template>
      <template v-else-if="filter.name ==='project'">
        <VueMultiselect v-if="$store.state.filters.tasks.customer.value !== '' && $store.state.filters.tempProjects && $store.state.filters.tempProjects.length > 0" class="small isMultiselect"
                        v-model="$store.state.filters.tasks.project.value"
                        :options="$store.state.filters.tempProjects"
                        :searchable="true"

                        :close-on-select="true"
                        valueProp="id"
                        label="title"
                        :placeholder="$t('search.project')"
                        @input="$nextTick(() => {updateValue()})"
                         />
      </template>
      <template v-else>
        <component :is="'sketchFilter'+filter.type" :type="'tasks'" :filter="filter" v-if="filter"/>
      </template>

    </li>

    <template v-if="!$store.state.filters.tasks.employee.value  && !$store.state.filters.tasks.state.value  ">
      <li class="filter-item">
      <button type="button" class="btn btn-outline-primary btn-sm" @click="setProductive">{{$t('tasks.productive')}}</button></li>
    </template>
  </ul>

</template>
<script>
import sketchFilterdatetime from "@/filter/components/datetime.vue";
import sketchFilterinput from "@/filter/components/textinput.vue";
import axios from 'axios';
import sketchFilterlist from "@/filter/components/list.vue";
import sketchFiltermultilist from "@/filter/components/multilist.vue";
import customersearch from "@/filter/components/customersearch.vue";
import {mapGetters} from 'vuex';
import VueMultiselect from '@vueform/multiselect'
import {nextTick} from "vue";

export default {
  name: "FiltersTasks",
  components: {
    customersearch, VueMultiselect,
    sketchFilterlist, sketchFiltermultilist, sketchFilterinput, sketchFilterdatetime
  },
  computed: {
    ...mapGetters(['getApiUrl'])
  },
  data() {
    return {
      customer: '',
      projects: [],
    };
  },
  methods: {
    nextTick,
    updateValue() {
      this.$store.dispatch('tasksFilter');
    },
    setProductive() {
      if(this.$store.state.user._isEmployee){
      this.$store.state.filters.tasks.employee.value = this.$store.state.user._isEmployee;}
      this.$store.state.filters.tasks.state.value = 'progress';
      this.updateValue();
    },
    getProjects(customerId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + customerId + "&filter_state=active&limit=0", {headers}).then((r) => {
        this.$store.state.filters.tempProjects = r.data.result.data;
      });
    }
  },
  watch: {
    '$store.state.filters.tasks.customer.value'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getProjects(newVal);
        this.updateValue();
      }
    }
  }
};
</script>
