<template>
  <div class="event-item headline">
  <div class="eventLineWrap" >
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title">
      <span @click="emitSort('title')"  :class="(sortDirections.title) ? 'active' : ''">Titel</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-category" >
      <span @click="emitSort('category')"  :class="(sortDirections.category) ? 'active' : ''">Kategorie</span>
      <i v-if="sortDirections.category === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.category === 'desc'" class="fa-duotone fa-arrow-down"></i>

    </div>
    <div class="lineitem l-subevents">
      <span @click="emitSort('subevents')" :class="(sortDirections.subevents) ? 'active' : ''" tooltip="UnterEreignisse" :position="'left'">Ue.</span>
      <i v-if="sortDirections.subevents === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.subevents === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-date">
      <span @click="emitSort('start')" :class="(sortDirections.start) ? 'active' : ''">Eventbeginn</span>
      <i v-if="sortDirections.start === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.start === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-state">
      <span @click="emitSort('state')" :class="(sortDirections.state) ? 'active' : ''" tooltip="Eventstatus" :position="'left'">S.</span>
      <i v-if="sortDirections.state === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.state === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-payment">
      <span @click="emitSort('show_payment')" :class="(sortDirections.show_payment) ? 'active' : ''" tooltip="Anmeldungsstatus" :position="'left'">As.</span>
      <i v-if="sortDirections.show_payment === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.show_payment === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('quota')" :class="(sortDirections.quota) ? 'active' : ''">Offen</span>
      <i v-if="sortDirections.quota === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.quota === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('sumquota')" :class="(sortDirections.sumquota) ? 'active' : ''">Belegt</span>
      <i v-if="sortDirections.sumquota === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.sumquota === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('sumwaiting')" :class="(sortDirections.sumwaiting) ? 'active' : ''" tooltip="Warteliste" :position="'left'">WL.</span>
      <i v-if="sortDirections.sumwaiting === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.sumwaiting === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('sumstorno')" :class="(sortDirections.sumstorno) ? 'active' : ''" tooltip="Storniert" :position="'left'">Storno</span>
      <i v-if="sortDirections.sumstorno === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.sumstorno === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('sumabos')" :class="(sortDirections.sumabos) ? 'active' : ''" tooltip="Abonnenten" :position="'left'">Abo</span>
      <i v-if="sortDirections.sumabos === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.sumabos === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <!--<div class="lineitem l-tags">
      <span @click="emitSort('tags')" :class="(sortDirections.tags) ? 'active' : ''"></span>
      <i v-if="sortDirections.tags === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.tags === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>-->

    <div class="lineitem actions" >

    </div>
    <div class="lineitem l-actions ml-auto">

    </div>
    <div class="lineitem l-actions ">
      </div>
    <div class="lineitem l-avatar" v-if="!$store.state.filters.documents.owner.value"></div>
    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        quota: '',
        sumquota: '',
        sumwaiting: '',
        sumstorno: '',
        tags: '',
        id: '',
        show_payment:'',
        state:'',
        start:'',
        subevents:'',
        category:'',

      }

    }
  },
  props: {
    document: Object,
  },
  computed: {
    ...mapGetters(["eventActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
