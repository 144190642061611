<template>
  <div class="pluginItem activePlugin">
    <div class="singleRequest" v-if="sepaActive.id">
      <div class="plugin-item activePlugin">
        <Headline :sepa="sepaActive"/>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h6><i class="fa-duotone fa-square-info"></i> Details
          <template v-if="sepaActive.id">
            <button type="button" class="btn btn-outline-secondary btn-sm float-end ms-3" @click="updateSepa()"><i
              class="fa-duotone fa-floppy-disk"></i> Speichern
            </button>
          </template>
          <template v-else>
            <button type="button" class="btn btn-outline-secondary btn-sm float-end ms-3" @click="createSepa()"><i
              class="fa-duotone fa-floppy-disk"></i> Speichern
            </button>
          </template>
          <button type="button" class="btn btn-outline-danger btn-sm float-end ms-3"
                  @click="$store.commit('closeSepa');$store.commit('closeModal', 'sepa');"><i
            class="fa-duotone fa-xmark"></i> Abbrechen
          </button>
          <template v-if="sepaActive.id">
            <button type="button" class="btn btn-outline-danger btn-sm float-end ms-3" @click="deleteSepa()"><i
              class="fa-duotone fa-trash"></i> Löschen
            </button>
          </template>
        </h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-12">
            <input type="hidden" v-model="sepa.id"/>
            <label class="required col-form-label">Mandatstitel<span class="star">&nbsp;*</span></label>
            <input type="text" v-model="sepa.title" class="required form-control" required/>
          </div>
          <div class="col-lg-6 col-12">
            <label class="col-form-label">Kontoinhaber</label>
            <input type="text" v-model="sepa.owner" class="form-control"/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-12">
            <label class="col-form-label">Adresse</label>
            <textarea v-model="sepa.address" class="form-control" style="height: 149px;"></textarea>
          </div>
          <div class="col-lg-6 col-12">
            <label class="col-form-label">Kunde</label>
            <template v-if="customer && customer.id">
              <template v-if="customer.c_company">
                {{ customer.c_company }}</template>
                <br><div class="text-xs customerInfo"
                          v-if="customer.c_title || customer.c_firstname || customer.c_lastname">
           <template v-if="customer.c_title">{{ customer.c_title }}&nbsp;</template>
          <template v-if="customer.c_firstname">{{ customer.c_firstname }}&nbsp;</template>
          <template v-if="customer.c_lastname">{{ customer.c_lastname }}</template>
            </div>

            </template>
            <template v-if="!sepa.id && (!customer || !customer.id)">
              <customersearch v-model="sepa.customer_id"/>
            </template>
            <label class="col-form-label">Bank</label>
            <input type="text" v-model="sepa.institute" class="form-control"/>
          </div>
          <div class="col-lg-6 col-12">
            <label class="col-form-label">Gültig ab</label>
            <VueDatePicker v-model="sepa.valid_from" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                           :placeholder="'Gültig ab wählen'" :auto-apply="true" :close-on-auto-apply="true"
                           locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen"
                           :teleport="true"></VueDatePicker>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-6 col-12">
            <label class="col-form-label">IBAN</label>
            <input type="text" v-model="sepa.iban" class="form-control"/>
          </div>
          <div class="col-lg-6 col-12">
            <label class="col-form-label">BIC</label>
            <input type="text" v-model="sepa.bic" class="form-control"/>
          </div>
        </div>
        <h5 class="mt-4">Kündigung</h5>
        <div class="row">
          <div class="col-lg-6 col-12">
            <label class="col-form-label">Kündigungsdatum</label>
            <VueDatePicker v-model="sepa.terminate_at" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                           :placeholder="'Kündigungsdatum wählen'" :auto-apply="true" :close-on-auto-apply="true"
                           locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen"
                           :teleport="true"></VueDatePicker>
          </div>
          <div class="col-lg-6 col-12" v-if="sepa.deleteAt">
            <label class="col-form-label">Löschungsdatum</label>
            <input type="text" v-model="sepa.deletedAt" class="form-control" readonly/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Headline from "@/routing/core/Sepa/Layouts/Headline.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import customersearch from "@/filter/components/customersearch.vue";

export default {
  name: 'SEPADetails',
  components: {VueDatePicker, Headline, customersearch},
  emits: ['closeSepa'],
  props: {
    customer: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      sepa: {},
      changeCustomer: 'no'
    }
  },
  methods: {
    updateSepa() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "sepa/" + VueInner.sepa.id, VueInner.sepa, {headers}).then(() => {
        VueInner.$store.commit('closeSepa');
        // dispatch toastSuccess
        VueInner.emit('closeSepa');
        VueInner.$store.dispatch('toastSuccess', 'Sepa wurde erfolgreich gespeichert');
        // Load Products
        VueInner.$store.dispatch('sepasFilter');
        this.$router.push({name: 'Sepa'});
      }).catch(() => {
      });
    },
    createSepa() {
      let VueInner = this;
      let postData = {hash: VueInner.sepa.customer_id.hash, data: VueInner.sepa}
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "sepa", postData, {headers}).then((r) => {
        VueInner.$store.commit('closeSepa');
        // dispatch toastSuccess
        VueInner.$store.dispatch('toastSuccess', 'Sepa wurde erfolgreich gespeichert');
        // Load Products
        VueInner.$store.dispatch('sepasFilter');
        VueInner.$store.commit('closeModal', 'sepa');
        VueInner.emit('closeSepa');
        this.$router.push({name: 'Sepa'});
      }).catch((r) => {
        // handle error
        this.$store.dispatch("handleApiResponse", r.response);
      });
    },
    deleteSepa() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      // confirm first
      this.$confirm('Soll der Eintrag wirklich gelöscht werden?', 'Sepa löschen', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        type: 'warning'
      }).then(() => {
        // delete
        axios.delete(this.$store.state.apiUrl + "sepa/" + VueInner.sepa.id, {headers}).then(() => {
          VueInner.$store.commit('closeSepa');
          VueInner.emit('closeSepa');
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Sepa wurde erfolgreich gelöscht');
          // Load Products
          VueInner.$store.dispatch('sepasFilter');
          VueInner.$router.push({name: 'Sepa'});
          this.$router.push({name: 'Sepa'});
        }).catch(() => {
        });
      }).catch(() => {
        // cancel
      });
    },
  },
  created() {
    this.sepa = this.$store.state.activeItems.sepa;
    if (this.customer && this.customer.id) {
      this.sepa.customer_id = this.customer.id;
    }



  },
  computed: {
    ...mapGetters(["sepaList", "sepaActive"]),
  },
}
</script>

