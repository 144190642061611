<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
            <label for="dateSelect">Datum wählen:</label>
            <select id="dateSelect" class="form-select" v-model="selectedDate" @change="updateChart">
              <option value="">- Bitte Datum wählen -</option>
              <option v-for="item in stats.data" :key="item.date" :value="item.date">
                {{ $filters.formatDate(item.date) }}
              </option>
            </select>
          </div>
            <BarChart :chartData="barChartStats" :options="barChartOptions" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
            <label for="dateSelect">Vergleich wählen:</label>
            <select id="dateSelect" class="form-select" v-model="selectedDateRight" @change="updateChartRight">
              <option value="">- Bitte Vergleichsdatum wählen -</option>
              <option v-for="item in stats.data" :key="item.date" :value="item.date">
                {{ $filters.formatDate(item.date) }}
              </option>
            </select>
            </div>
            <BarChart :chartData="barChartStatsRight" :options="barChartOptions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BarChart } from "vue-chart-3";
import axios from "axios";

export default {
  name: "DFBGenders",
  components: {
    BarChart
  },
  props: {
    stats: {
      type: Object,
      default: () => ({ data: [] })
    }
  },
  data() {
    return {
      selectedDate: "", // This will store the selected date
      selectedDateRight: "",
      barChartStats: {
        labels: ['Männlich', 'Weiblich', 'keine Angabe'],
        datasets: [
          {
            label: 'akzeptiert',
            data: [],
            backgroundColor: [
              '#188360',
              '#188360',
              '#188360'
            ]
          },
          {
            label: 'abgelehnt',
            data: [],
            backgroundColor: [
              'rgb(248,26,44)',
              'rgb(248,26,44)',
              'rgb(248,26,44)'
            ]
          }
        ]
      },
      barChartStatsRight:{
        labels: ['Männlich', 'Weiblich', 'keine Angabe'],
        datasets: [
          {
            label: 'akzeptiert',
            data: [],
            backgroundColor: [
              'rgb(15,138,45)',
              'rgb(15,138,45)',
              'rgb(15,138,45)'
            ]
          },
          {
            label: 'abgelehnt',
            data: [],
            backgroundColor: [
              'rgb(248,26,44)',
              'rgb(248,26,44)',
              'rgb(248,26,44)'
            ]
          }
        ]
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false, // show legend for better understanding
          },
          tooltip: {
            interaction: {}
          }
        },
      },
    };
  },
  methods: {
    calculateMaxCount(datasets) {
      // Flatten the data arrays and find the maximum value
      return Math.max(...datasets.flat());
    },
    updateScales(maxCount) {
      // Update the y-axis max value for both charts
      this.barChartOptions.scales = {
        y: {
          beginAtZero: true,
          max: maxCount,
          ticks: {
            stepSize: Math.ceil(maxCount / 5) // Optional: control the step size on y-axis
          }
        }
      };
    },
    updateChart() {
      const selectedData = this.stats.data.find(item => item.date === this.selectedDate);
      let maxCount = 0;

      if (selectedData) {
        const male = selectedData.data.maennlich || { countAccepted: 0, countDenied: 0 };
        const female = selectedData.data.weiblich || { countAccepted: 0, countDenied: 0 };
        const divers = selectedData.data["keine-angabe"] || { countAccepted: 0, countDenied: 0 };

        // Populate the datasets with the correct data
        const acceptedData = [male.countAccepted, female.countAccepted, divers.countAccepted];
        const deniedData = [male.countDenied, female.countDenied, divers.countDenied];

        this.barChartStats.datasets[0].data = acceptedData;
        this.barChartStats.datasets[1].data = deniedData;

        maxCount = this.calculateMaxCount([acceptedData, deniedData]);
      } else {
        this.barChartStats.datasets[0].data = [0, 0, 0];
        this.barChartStats.datasets[1].data = [0, 0, 0];
      }

      this.updateScales(maxCount);
    },
    updateChartRight() {
      const selectedData = this.stats.data.find(item => item.date === this.selectedDateRight);
      let maxCount = 0;

      if (selectedData) {
        const male = selectedData.data.maennlich || { countAccepted: 0, countDenied: 0 };
        const female = selectedData.data.weiblich || { countAccepted: 0, countDenied: 0 };
        const divers = selectedData.data["keine-angabe"] || { countAccepted: 0, countDenied: 0 };

        // Populate the datasets with the correct data
        const acceptedDataRight = [male.countAccepted, female.countAccepted, divers.countAccepted];
        const deniedDataRight = [male.countDenied, female.countDenied, divers.countDenied];

        this.barChartStatsRight.datasets[0].data = acceptedDataRight;
        this.barChartStatsRight.datasets[1].data = deniedDataRight;

        maxCount = this.calculateMaxCount([acceptedDataRight, deniedDataRight]);
      } else {
        this.barChartStatsRight.datasets[0].data = [0, 0, 0];
        this.barChartStatsRight.datasets[1].data = [0, 0, 0];
      }

      this.updateScales(maxCount);
    }
  },
  mounted() {
    // Set the initial date selection to the first available date
    if (this.stats.data.length > 0) {
      this.selectedDate = this.stats.data[0].date;
      this.updateChart();

    }
  }
};
</script>
