<template>
  <div class="card-body document-list">
    <Sort @sort="resortOrder($event)"/>
    <template v-if="$store.state.lists.documents.loading">
      <preLoader height="70vh" style="background: transparent;position: relative;" class="inner"
                 :loading="$store.state.lists.documents.loading"/>
    </template>
    <template v-else>
      <p class="text-sm text-secondary" v-if="!documentList || documentList.data === 0">Es wurden keine Kunden
        gefunden.</p>
      <div :id="'document_'+document.id" class="document-item"
           :class="(activeDocument && activeDocument.id === document.id) ? 'activeDocument' : 'notActive'"
           v-for="document in documentList.data" :key="document.id">
        <Headline :document="document"/>
      </div>
    </template>
    <FooterInfo v-if="allDocumentsSum" :allDocumentsSum="allDocumentsSum"/>
    <pagination list="documents" listName="documentList" filterName="documentsFilter"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Documents/Layouts/Headline";
import Sort from "@/routing/core/Documents/Layouts/Sort";
import $ from "jquery";
import Pagination from "@/filter/components/pagination.vue";
import preLoader from "@/components/Loading.vue";
import FooterInfo from "@/routing/core/Documents/Layouts/FooterInfo.vue"
export default {
  name: "DocumentsList",
  computed: {
    ...mapGetters(["getEmployees", "documentList", "documentActive"]),
    allDocumentsSum() {
      if (!this.documentList || !this.documentList.data) {
        return 0;
      }
      return this.documentList.data.reduce((sum, document) => {
        if (document.complete_netto) {
          return parseFloat(sum + parseFloat(document.complete_netto));
        }
        return parseFloat(sum);
      }, 0).toFixed(2);
    }
  },
  data() {
    return {
      selectedDocument: null,
      activeDocument: null,
      completeDocuments: 0,
      offset: 0,
      counter: 0,
    }
  },
  components: {
    preLoader,
    Pagination,
    Headline, Sort,
    FooterInfo
  },
  methods: {
    getDocuments() {
      this.$store.dispatch("documentsFilter");
    },
    resortOrder(payload) {
      if (payload.direction) {
        this.$store.state.filters.documents.ordering.value = payload.type;
        this.$store.state.filters.documents.direction.value = payload.direction;
      } else {
        this.$store.state.filters.documents.ordering.value = payload.type
      }
      this.getDocuments();
    },
    switchPage(type) {
      if (type === 'next' && this.documentList.offset <= this.documentList.completeDocuments) {
        this.$store.state.lists.documents.offset = this.documentList.offset + 20;
        this.getDocuments()
      }
      if (type === 'previous' && this.documentList.offset >= 20) {
        this.$store.state.lists.documents.offset = this.documentList.offset - 20;
        this.getDocuments()
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  mounted() {
    this.getDocuments();
  },
};
</script>
