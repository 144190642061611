<template>
  <ckeditor :editor="editor" v-model="c" :config="editorConfig" @focus="onEditorFocus" @blur="onEditorBlur" :class="(editorFocused) ? 'on-focus' : 'on-blur'"></ckeditor>
</template>
<script>
import ClassicEditor from '@/assets/ckeditor/build/ckeditor';
import axios from 'axios';
let globalApiUrl = "https://api.sketch.media/";
let globalHeaders = {"crmAuth": localStorage.getItem("sketchManagerToken")};
let comeFromRoute = false;
export default {
  name: 'Editor2',
  props: {
    content: null,
    customConfig: Object,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [  'heading', '|', 'fontSize', 'alignment', 'bold', 'italic', 'strikeThrough', 'underline', '|', 'link', 'bulletedList', 'numberedList', 'todoList', '|', 'outdent', 'indent', 'imageUpload', '-', 'undo', 'redo', 'findAndReplace', 'removeFormat',
            // Break line manually for clarity, but it's still a single array
            'fontColor', 'fontBackgroundColor',  '|', 'blockQuote', 'insertTable', 'mediaEmbed', '|',  'horizontalLine'
          ]
        },
        extraPlugins: [MyCustomUploadAdapterPlugin],
        // Add other configurations here
      },
      c: this.content,
      editorFocused: false
    };
  },
  methods:{
    onEditorFocus() {
      this.editorFocused = true;
    },
    onEditorBlur() {
      setTimeout(() => {
        // Check if the editor is still the active element or part of its active path
        if (!document.activeElement.closest('.ck-editor')) {
          this.editorFocused = false;
        }
      }, 100);
    },


  },
  computed: {
    defaultConfig() {
      return {
        toolbar: {
          items: [
            'heading', '|', 'bold', 'italic', '|', 'link', 'bulletedList', 'numberedList', '|',
            'imageUpload', '|', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo',
          ]
        },
        // Your default editor configuration here
      };
    }
  },
  created() {
    globalApiUrl = this.$store.state.apiUrl;
    globalHeaders = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    comeFromRoute = this.$route.name;
  },

}

class MyUploadAdapter {
  constructor(loader, url, headers) {
    this.loader = loader;
    this.url = url;
    this.headers = headers;
  }

  upload() {
    return this.loader.file.then(file => {
      const formData = new FormData();
      formData.append('upload', file); // 'upload' is the field name that your server expects
      formData.append('comeFromRoute', comeFromRoute); // 'editor' is the type of the upload (you can adjust this based on your server's requirements

      return axios({
        url:   this.url,
        method: 'post',
        data: formData,
        headers: this.headers
      })
        .then(response => {
          // The response should contain the URL of the uploaded image
          // The exact path depends on your backend setup


          return {
            default: response.data.data.url // Adjust 'url' based on your server's response structure
          };
        })
        .catch(error => {
          throw error;
        });
    });
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  // todo: get url from api url from store
  const url = globalApiUrl + "files/uploadEditor"; // Ensure this is the correct endpoint
  const headers = globalHeaders;
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader, url, headers);
}}
</script>
