<template>
  <div class="draftLineWrap" :class="($store.state.activeItems && $store.state.activeItems.ConfigCustomerTitle && $store.state.activeItems.ConfigCustomerTitle.id === draft.id) ? 'active' : ''" >
    <div class="lineitem l-title" @click="setConfig(draft)">
      {{ draft.name }}
    </div>

    <div class="lineitem l-default">
      {{ draft.greeting }}
    </div>


    <div class="lineitem l-ordering">
      {{ draft.ordering }}
    </div>

    <div class="lineitem l-default btn">
      <a href="index.php?option=com_sketchcrm&amp;task=addTitles&amp;title=3">Jetzt zuweisen</a>
    </div>


    <div class="lineitem last ml-auto">
      <template v-if="$store.state.activeItems && $store.state.activeItems.ConfigCustomerTitle && $store.state.activeItems.ConfigCustomerTitle.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigCustomerTitle');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["getEmployees"])
  },
  methods: {
    setConfig(draft) {
      const newData = {data: draft, type:'ConfigCustomerTitle'};
      this.$store.dispatch("setActiveConfig",  newData).then(() => {
        this.$router.push({name: 'ConfigCustomerTitleDetails', params: {id: draft.id}})
      });


    },
  }
}
</script>
