<template>
  <div class="card" style="min-height: 45vh;">
    <div class="card-header"><h6 class="inline"><i class="fa-id-card-clip fa-duotone"></i> Mitarbeiter</h6>
      <div class="float-end">
        <div class="btn-group me-4">
          <template v-if="e.id && getUser && getUser.acl">
            <button type="button" class="btn btn-outline-danger btn-sm" @click="resetPW()"
                    v-if="getUser.acl.employees.delete === 'all'"><i class="fa-duotone fa-passport"></i> Neues Passwort setzen
            </button>
            <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteEmployee()"
                    v-if="getUser.acl.employees.delete === 'all'"><i class="fa-duotone fa-trash"></i> Löschen
            </button>
            <template v-if="e._block ==='0'">
              <button type="button" class="btn btn-outline-danger btn-sm" @click="lockEmployee()"
                      v-if="getUser.acl.employees.delete === 'all'"><i class="fa-duotone fa-lock"></i> Sperren
              </button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-outline-secondary btn-sm" @click="unlockEmployee()"
                      v-if="getUser.acl.employees.delete === 'all'"><i class="fa-duotone fa-unlock"></i> Entsperren
              </button>
            </template>
          </template>
          <button type="button" class="btn btn-outline-primary btn-sm" @click="updateEmployee()"
                  v-if="getUser.acl.employees.update === 'all'"><i class="fa-duotone fa-floppy-disk"></i> Speichern
          </button>
        </div>
        <button type="button" class="btn btn-close" @click="closeProject();"></button>
      </div>
    </div>
    <ul class="tabNav">
      <li v-for="cat in employeeTabs" :key="cat.name"
          @click="changeTab(cat.name)"
          :class="(activeSubtab === cat.name) ? 'active' : ''"><i class="fa-duotone" :class="cat.icon"></i><br><small>
        {{ cat.label }}</small>
      </li>
      <li @click="changeTab('mail')" :class="(activeSubtab === 'mail') ? 'active' : ''"
          v-if="getUser.acl.addOwners === 'yes'"><i class="fa-duotone fa-envelope"></i><br><small>E-Mails</small>
      </li>
      <li @click="changeTab('timesheet')" :class="(activeSubtab === 'timesheet') ? 'active' : ''"
          v-if="getMenu.times && getUser.acl.addOwners === 'yes'"><i class="fa-duotone fa-calendar"></i><br><small>Arbeitszeit</small>
      </li>
      <li @click="changeTab('acl')" :class="(activeSubtab === 'acl') ? 'active' : ''"
          v-if="getUser.acl.addOwners === 'yes'"><i class="fa-duotone fa-hammer"></i><br><small>Zugriffsrechte</small>
      </li>
    </ul>
    <div class="card-body">
      <template v-if="activeSubtab === 'details'">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="e_name">Name</label>
              <input type="text" class="form-control" name="e_name" id="e_name" v-model="e.e_name"/>
            </div>
          </div>
          <div class="col-3" v-if="e.status">
            <div class="form-group">
              <label for="e_status">Status</label>
              <template v-if="getUser.acl.addOwners === 'yes'">
                <multiselect
                  :options="[{label: 'Aktiv', value: 'active'}, {label: 'Inaktiv', value: 'inactive'}, {label: 'Ticket-System', value: 'issue'}, {label: 'Inhaber', value: 'owner'}]"
                  :multiple="false"
                  :label="'label'"
                  :can-clear="false"
                  :valueProp="'value'"
                  :track-by="'value'"
                  v-model="e.status"
                  :searchable="false"></multiselect>
              </template>
              <template v-else>
                <multiselect
                  :options="[{label: 'Aktiv', value: 'active'}, {label: 'Inaktiv', value: 'inactive'}, {label: 'Ticket-System', value: 'issue'}]"
                  :multiple="false"
                  :label="'label'"
                  :can-clear="false"
                  :valueProp="'value'"
                  :track-by="'value'"
                  v-model="e.status"
                  :searchable="false"></multiselect>
              </template>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>Position</label>
              <multiselect :options="getConfigs.categoriesEmployee" v-if="getConfigs.categoriesEmployee"
                           :multiple="false"
                           :label="'title'"
                           :can-clear="false"
                           :valueProp="'id'"
                           :track-by="'id'"
                           v-model="e.e_catid"
                           :searchable="false"
                           :placeholder="'Bitte wählen'"

              ></multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Telefon</label>
              <input type="text" class="form-control" name="phone" v-model="e.phone"/>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="e_email">E-Mail</label>
              <input type="text" class="form-control" name="e_email" id="e_email" v-model="e.e_email"/>
            </div>
          </div>
        </div>
        <div class="row" v-if="getMenu.tasks">
          <div class="col-3">
            <div class="form-group">
              <label for="e_issues">Ticket-System</label>
              <multiselect :options="[ {label: 'Nein', value: 'no'}, {label: 'Ja', value: 'yes'}]" v-if="e.use_in_issues"
                           :multiple="false"
                           :label="'label'"
                           :can-clear="false"
                           :valueProp="'value'"
                           :track-by="'value'"
                           v-model="e.use_in_issues"
                           :searchable="false"></multiselect>

            </div>
          </div>
          <!-- if yes show int field for hours -->
          <div class="col-3" v-if="e.use_in_issues === 'yes'">
            <div class="form-group">
              <label for="e_issue_reminder_start">Beginn des Tages</label>
              <input type="time" name="e_issue_reminder_start" id="e_issue_reminder_start" class="form-control"
                     v-model="e.issue_reminder_start"/>
            </div>
          </div>
          <div class="col-3" v-if="e.use_in_issues === 'yes'">
            <div class="form-group">
              <label for="e_issue_reminder_end">Ende des Tages</label>
              <input type="time" name="e_issue_reminder_end" id="e_issue_reminder_end" class="form-control"
                     v-model="e.issue_reminder_end"/>
            </div>
          </div>
          <div class="col-3" v-if="e.use_in_issues === 'yes'">
            <div class="form-group">
              <label for="issue_reminder_intervall">Erinnerungsintervall in h</label>
              <input type="number" name="issue_reminder_intervall" id="issue_reminder_intervall" class="form-select"
                     v-model="e.issue_reminder_interval"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6" v-if="getPlugins.phone && getPlugins.phone.placetel">
            <div class="form-group">
              <label>Telefon IP</label>
              <input type="text" class="form-control" name="phone_ip" v-model="e.phone_ip"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <div class="form-group">
              <label>Mitarbeiter Farbe</label>
              <input type="color" class="form-control" name="phone_ip" v-model="e.color"/>
            </div>
          </div>
          <div class="col-6" v-if="e && e.id">
            <div class="form-group">
              <label>Neues Mitarbeiter Bild</label>
              <input type="file" ref="fileInput" class="form-control" @change="onImageChange" accept="images/*"/>
            </div>
          </div>
          <div v-if="e.imageSource" class="col-4">
            <div class="form-group">
              <label class="mb-2">Mitarbeiter Bild</label><br>
              <template v-if="showImage === false">
                <button class="btn btn-outline-primary me-3" @click="showImage = true" tooltip="Bild anzeigen"><i
                  class="fa-duotone fa-image"></i></button>
              </template>
              <template v-else>
                <button class="btn btn-primary me-3" @click="showImage = false"><i
                  class="fa-duotone fa-image"></i></button>
              </template>
              <button class="btn btn-danger" @click="e.imageSource = ''; e.imageMimeType = ''" tooltip="Bild löschen"><i
                class="fa-duotone fa-image-slash"></i></button>
              <div v-if="showImage">
                <img :src="'data:'+e.imageMimeType+';base64,'+e.imageSource" :alt="e.imageMimeType"/></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="open_times">Zusätzliche Informationen</label>
              <Editor2 v-model="e.open_times"/>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeSubtab === 'acl'">
        <Acl :employee="e" @acl-changed="updateRights"/>
      </template>
      <template v-if="activeSubtab === 'timesheet'">
        <div class="row">
          <div class="col-5">
            <div class="globals" v-if="e.customFields && e.customFields.timesGlobal">
              <div class="form-group">
                <label class="me-3"><small>Durschnittliche tägliche Arbeitszeit (Stunden)</small></label>
                <input type="number" step="0.01" min="0.00" class="small"
                       v-model="e.customFields.timesGlobal.daily" placeholder="Tägliche Durchschnittszeit"></div>
            </div>
          </div>
          <div class="col-4">
            <template v-if="e.customFields && e.customFields.timesGlobal && e.customFields.timesGlobal.daily && e.customFields.timesGlobal.daily > 0">
            <div
              v-if="e.customFields && e.customFields.timesGlobal && (averageDayHours !== parseFloat(e.customFields.timesGlobal.daily))"
              class="alert text-center" style="background-color: #F7E6E7;border: 0; width: auto; margin-left: auto;">
              <i class="fa-duotone fa-circle-exclamation text-danger  me-2"></i> Tagesarbeitszeit entspricht nicht der
              Einstellung!
            </div></template>
          </div>
          <div class="col-3">
            <button type="button" class="copyGroupTime btn btn-outline-primary float-end" @click="addNewTime"><i
              class="fa-duotone fa-circle-plus"></i> Tag hinzufügen
            </button>
          </div>
        </div>
        <div class="groupTimes" v-if="sortedTimes">
          <div class="day" v-for="(times, day) in sortedTimes" :key="day">
            <template v-if="times && times.day">
            <strong class="mb-2" style="display: block; font-weight: 600;" ><small
              class="text-secondary">{{ getDayByDay(times.day) }} <span class="countDay">{{ calculateDayHours(times) }} Stunde(n)</span></small></strong>
            <div class="groupTime">
              <div class="row mb-3">
                <div class="col-3">
                  <multiselect class="small"
                               :options="daysOptions"
                               :multiple="false"
                               :label="'label'"
                               :can-clear="false"
                               :valueProp="'value'"
                               v-model="times.day"
                               :searchable="false"></multiselect>
                </div>
                <div class="col-3"><input class="form-control small" required type="time" v-model="times.from"></div>
                <div class="col-3"><input class="form-control small" required type="time" v-model="times.till"></div>
                <div class="col-3">
                  <button class="deleteIt btn btn-outline-danger btn-sm" @click="deleteTime(day)"><i
                    class="fa-duotone fa-trash-can"></i></button>
                </div>
              </div>
            </div></template>
          </div>
        </div>
        <div class="sumWeeks" v-if="sumWeek && averageDayHours">
          Wochenarbeitszeit: <strong>{{ sumWeek }}</strong><br>
          Durchschnittliche Tagesarbeitszeit: <strong v-if="averageDayHours">{{ averageDayHours.toFixed(2) }}</strong>
        </div>

        <div class="row" v-if="!e.timeStory">

          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group"><label>Einstiegsjahr</label><input
              type="number" step="1" class="form-control"
              min="1950" max="2050"
              v-model="e.timedetails.first_year"/></div>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group"><label>Vertragl. Urlaubstage</label><input type="number" step=".01"
                                                                               class="form-control"
                                                                               v-model="e.timedetails.holiday"/></div>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group"><label>Übertrag Resturlaub vorheriges System</label><input type="number" step=".01"
                                                                                               class="form-control"
                                                                                               v-model="e.timedetails.holiday_more"/>
              <span class="text-xs">z.b. -4 falls bereits 4 im aktuellen Jahr genommen wurden</span></div>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group"><label>Übertrag Überstunden vorheriges System</label><input type="number" step=".01"
                                                                                                class="form-control"
                                                                                                v-model="e.timedetails.hours"/><span class="text-xs">Anzahl
              an Überstunden aus vorherigem System z.B. 24.50</span></div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12">
            Arbeitszeitkonto ist <b>aktiv.</b>
          </div>
        </div>
      </template>
      <template v-if="activeSubtab === 'mail'">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="form-group">
              <label class="me-3">Separates Mailing</label>
              <div class="btn-group btn-group-yesno" role="group"
                   v-if="e.mail_settings && e.mail_settings.use_own_mailing">
                <input type="radio" class="btn-check" name="use_own_mailing" id="use_own_mailing_yes" value="1"
                       v-model="e.mail_settings.use_own_mailing">
                <label class="btn btn-outline-primary" for="use_own_mailing_yes">Ja</label>
                <input type="radio" class="btn-check" name="use_own_mailing" id="use_own_mailing_no" value="0"
                       v-model="e.mail_settings.use_own_mailing">
                <label class="btn btn-outline-danger" for="use_own_mailing_no">Nein</label>
              </div>
            </div>
            <template v-if="e.mail_settings && e.mail_settings.use_own_mailing">
              <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
                <label>Absenderadresse</label>
                <input class="form-control" type="text" v-model="e.mail_settings.mailfrom">
              </div>
              <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
                <label>Absendername</label>
                <input class="form-control" type="text" v-model="e.mail_settings.fromname">
              </div>
              <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
                <label>Server</label>
                <input class="form-control" type="text" v-model="e.mail_settings.smtphost">
              </div>
              <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
                <label>Port</label>
                <input class="form-control" type="text" v-model="e.mail_settings.smtpport">
              </div>
              <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
                <label>SMTP-Sicherheit</label>
                <multiselect
                  :options="[
          {label: 'Keine', id: 'none'},
          {label: 'SSL/TLS', id: 'ssl'},
          {label: 'STARTTLS', id: 'tls'},
        ]"
                  :multiple="false"
                  :label="'label'"
                  :can-clear="false"
                  :valueProp="'id'"
                  v-model="e.mail_settings.smtpsecure"
                  placeholder="SMTP-Sicherheit"
                  :searchable="false"></multiselect>
              </div>
            </template>
          </div>
          <div class="col-lg-6 col-12">
            <template v-if="e.mail_settings && e.mail_settings.use_own_mailing">
              <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
                <label class="me-3">SMTP-Authentifizierung</label>
                <div class="btn-group btn-group-yesno" role="group">
                  <input type="radio" class="btn-check" name="smtpauth" id="smtpauth_yes" value="1"
                         v-model="e.mail_settings.smtpauth">
                  <label class="btn btn-outline-primary" for="smtpauth_yes">Ja</label>
                  <input type="radio" class="btn-check" name="smtpauth" id="smtpauth_no" value="0"
                         v-model="e.mail_settings.smtpauth">
                  <label class="btn btn-outline-danger" for="smtpauth_no">Nein</label>
                </div>
              </div>
              <div class="form-group"
                   v-if="e.mail_settings.use_own_mailing === '1' && e.mail_settings.smtpauth === '1'">
                <label>Benutzer</label>
                <input class="form-control" type="text" v-model="e.mail_settings.smtpuser">
              </div>
              <div class="form-group"
                   v-if="e.mail_settings.use_own_mailing === '1' && e.mail_settings.smtpauth === '1'">
                <label>Passwort</label>
                <input class="form-control" type="password" v-model="e.mail_settings.smtppass">
              </div>
              <div class="form-group" v-if="e.mail_settings.use_own_mailing === '1'">
                <label>Eigener E-Mail Abschluss (Grüße)</label>
                <Editor2 v-model="e.mail_settings.greetings"/>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Acl from "@/routing/core/Employees/Acl";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import employee from "../Config/Employee.vue";
import Multiselect from '@vueform/multiselect'

export default {
  name: "EmployeeDetails",
  emits: ['closedEdit'],
  components: {
    Editor2, Acl, Multiselect
  },
  computed: {
    employee() {
      return employee
    },
    ...mapGetters(["getConfigs", "getUser", "getPlugins", "getMenu"]),
    checkAccess() {
      // Here you'll need to implement the ACL check logic
      // I'm returning true as a placeholder for now
      return true;
    },
    sortedTimes() {
      // Convert the times object into an array
      if (!this.e.customFields || !this.e.customFields.times) {
        return [];
      }
      return Object.values(this.e.customFields.times);
    },
    sumWeek() {
      // Calculate the sum of the week's hours
      let sum = 0;
      for (const day in this.sortedTimes) {
        sum += this.calculateDayHours(this.sortedTimes[day]);
      }
      return sum;
    },
    averageDayHours() {
      // combine multiple times per day
      if (this.sortedTimes && this.sortedTimes.length > 0) {
        let times = {};
        for (const day in this.sortedTimes) {
          const time = this.sortedTimes[day];

          // Ensure that time is not null or undefined
          if (time && time.day) {
            if (!times[time.day]) {
              times[time.day] = {
                day: time.day,
                from: time.from,
                till: time.till,
                sum: this.calculateDayHours(time)
              };
            } else {
              times[time.day].sum += this.calculateDayHours(time);
            }
          }
        }

        // Use a local variable for sumWeek instead of assigning to this.sumWeek
        let sumWeek = 0;
        for (const day in times) {
          sumWeek += times[day].sum;
        }

        // Return average day hours based on the local sumWeek
        return sumWeek / Object.keys(times).length;
      }
      return 0; // Default return when sortedTimes is empty or invalid
    },

  },
  data() {
    return {
      e: {
        color: '#000000',
        rights: {},
        customFields: {
          times: {},
          status: 'active',
          timesGlobal: {
            daily: 0
          },
          images: null, // Hier wird das Bild gespeichert
        },
        mail_settings: {
          use_own_mailing: '0',
          greetings: '',
          mailfrom: '',
          smtpauth: '0',
          smtppass: '',
          smtpsecure: 'none',
          smtpuser: '',
        },
        e_catid: '',
        timedetails: {
          first_year: new Date().getFullYear().toString(),
          hours: '',
          holiday: '',
          holiday_more: ''
        },
        timeStory: false
      },
      daysOptions: [
        {value: '1', label: 'Montag'},
        {value: '2', label: 'Dienstag'},
        {value: '3', label: 'Mittwoch'},
        {value: '4', label: 'Donnerstag'},
        {value: '5', label: 'Freitag'},
        {value: '6', label: 'Samstag'},
        {value: '0', label: 'Sonntag'}
      ],
      isNew: true,
      employeeTabs: [
        {name: 'details', label: 'Details', icon: 'fa-info'},
      ],
      activeSubtab: 'details',
      showImage: false
    };
  },
  methods: {
    onImageChange() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0]; // Get the selected file
      let VueInner = this;
      if (file) {
        // Read the file as a Data URL to get base64 data
        const reader = new FileReader();
        reader.onload = () => {
          VueInner.e.imageMimeType = file.type; // Set the image MIME type
          VueInner.e.imageSource = reader.result.split(',')[1]; // Set the base64 image data
        };
        reader.readAsDataURL(file);
      }
    },
    formatValue(value) {
      if (typeof value !== 'number') {
        return '0.00';
      }
      return value.toFixed(2);
    },
    addHoursToTime(startTime, hoursToAdd) {
      const [startHour, startMinutes] = startTime.split(':').map(Number);
      const startDate = new Date(1970, 0, 1, startHour, startMinutes);
      startDate.setHours(startDate.getHours() + hoursToAdd);
      const endHour = startDate.getHours().toString().padStart(2, '0');
      const endMinutes = startDate.getMinutes().toString().padStart(2, '0');
      return `${endHour}:${endMinutes}`;
    },
    addNewTime() {
      // Default values
      let from = "08:00";
      let till = "16:00";
      // If the average daily hours is set, use it to calculate the 'till' time
      if (this.e.customFields && this.e.customFields.timesGlobal && this.e.customFields.timesGlobal.daily) {
        const dailyHours = parseFloat(this.e.customFields.timesGlobal.daily);
        till = this.addHoursToTime(from, dailyHours);
      }
      // Create a new time entry
      const newTime = {
        day: "1",  // Default to Monday or any other day
        from,
        till
      };
      // Initialize e.customFields if it doesn't exist
      if (!this.e.customFields) {
        this.e.customFields = {};
      }
      // Initialize e.customFields.times if it doesn't exist
      if (!this.e.customFields.times) {
        this.e.customFields.times = {};
      }
      // Determine the next key
      const nextKey = Object.keys(this.e.customFields.times).length + 1;
      // Add the new time entry
      this.e.customFields.times[nextKey] = newTime;
    },
    getDayByDay(day) {
      // Convert the day to a number and find a match in the daysOptions array
      const matchingDay = this.daysOptions.find(option => option.value === String(day));
      // Return the label of the matching day or a default value if not found
      return matchingDay ? matchingDay.label : 'Unknown Day';
    },
    calculateDayHours(time) {
      if(time && time.from && time.till){
        return this.calcTimeDifference(time.from, time.till);
      }
    },
    calcTimeDifference(from, till) {
      // Convert time strings to Date objects
      const fromDate = new Date(`1970-01-01T${from}:00Z`);
      const tillDate = new Date(`1970-01-01T${till}:00Z`);
      // Calculate difference in milliseconds and convert to hours
      const diffHours = (tillDate - fromDate) / (1000 * 60 * 60);
      return diffHours;
    },
    deleteTime(index) {
      // Check if the data structure exists
      console.log(index);
      if (this.e && this.e.customFields && this.e.customFields.times) {
        const timesArray = Object.keys(this.e.customFields.times);
        const keyToDelete = timesArray[index];
        // Delete the time entry with the given key
        delete this.e.customFields.times[keyToDelete];
      }
    },
    closeProject() {
      this.$store.commit('closeModal', 'employee');
      this.$emit('closedEdit');
      // trigger custom Event newEmployee windows event
      const MyEvent = new CustomEvent('newEmployee');
      window.dispatchEvent(MyEvent);
    },
    resetPW() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "employee/" + VueInner.e.id + "/resetPW", {}, {headers}).then(() => {
        this.$store.dispatch("toastSuccess", "Passwort wurde per Email versendet.");
        this.closeProject();
      }).catch((r) => {
        this.$store.dispatch('handleApiResponse', r.response);
      });
    },
    deleteEmployee() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.$confirm("Mitarbeiter löschen", 'Sicher löschen?', 'warning', {
        confirmButtonText: this.$t("globals.yes"),
        cancelButtonText: this.$t("globals.no"),
        type: 'warning',
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + "employee/" + VueInner.e.id, {headers}).then(() => {
          this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich gelöscht.");
          this.closeProject();
        }).catch((r) => {
          this.$store.dispatch('handleApiResponse', r.response);
        });
      });
    },
    lockEmployee() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "employee/" + VueInner.e.id + "/lock", {}, {headers}).then(() => {
        this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich gesperrt.");
        this.closeProject();
      }).catch((r) => {
        this.$store.dispatch('handleApiResponse', r.response);
      });
    },
    unlockEmployee() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "employee/" + VueInner.e.id + "/unlock", {}, {headers}).then(() => {
        this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich entsperrt.");
        this.closeProject();
      }).catch((r) => {
        this.$store.dispatch('handleApiResponse', r.response);
      });
    },
    getEmployee() {
      if (this.$route.params !== undefined && this.$route.params.id !== undefined) {
        let VueInner = this;
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.get(this.$store.state.apiUrl + "employee/" + this.$route.params.id, {headers}).then((r) => {
          VueInner.e = r.data.result.data.employee;
          VueInner.e.timeStory = r.data.result.timeStory;
          if(!VueInner.e.timeStory){
          VueInner.e.timedetails =   {
            first_year: new Date().getFullYear().toString(),
              hours: '',
              holiday: '',
              holiday_more: ''
          }}
          if(!VueInner.e.customFields.timesGlobal){
            VueInner.e.customFields.timesGlobal = {
              daily: 0
            }
          }

        }).catch((r) => {
          this.$store.dispatch('handleApiResponse', r.response);
        });
      }
    },
    updateEmployee() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (this.e.timedetails && this.e.timedetails.holiday === ''){
        this.e.timedetails = false;
      }
      if (VueInner.isNew) {
        axios.post(this.$store.state.apiUrl + "employee", VueInner.e, {headers}).then(() => {
          this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich angelegt.");
          this.closeProject();
        }).catch((r) => {
          this.$store.dispatch('handleApiResponse', r.response);
        });
      } else {
        axios.put(this.$store.state.apiUrl + "employee/" + VueInner.e.id, VueInner.e, {headers}).then(() => {
          this.$store.dispatch("toastSuccess", "Mitarbeiter wurde erfolgreich gespeichert.");
          this.closeProject();
        }).catch((r) => {
          this.$store.dispatch('handleApiResponse', r.response);
        });
      }
    },
    checkIfIsNew() {
      if (this.$route.params !== undefined && this.$route.params.id) {
        this.isNew = false;
      } else {
        this.e = {
          color: '#000000',
          rights: {},
          status: 'active',
          e_catid: '',
          customFields: {
            times: {},
            timesGlobal: {
              daily: 0
            }
          },
          mail_settings: {
            use_own_mailing: '0',
            greetings: '',
            mailfrom: '',
            smtpauth: '0',
            smtppass: '',
            smtpsecure: 'none',
            smtpuser: '',
          },
          use_in_issues: 'no',
          timedetails: {
            first_year: new Date().getFullYear().toString(),
            hours: '',
            holiday: '',
            holiday_more: ''
          },
          timeStory: false,
        };
        this.isNew = true;
      }
    },
    updateRights(newRights) {
      this.e.rights = newRights;
    },
    changeTab(tabName) {
      this.activeSubtab = tabName;
    },
  },
  mounted() {
    this.getEmployee();
    if (!this.e.status) {
      this.e.status = 'active'; // Set the default value here
    }
  },
  created() {
    this.checkIfIsNew();
  },
  watch: {
    $route(to, from) {
      this.getEmployee();
      this.checkIfIsNew();
    },
    "e.e_catid": function (newVal, oldVal) {
      if (newVal && oldVal !== newVal){
        if(this.getConfigs){
          let a; for (a in this.getConfigs.categoriesEmployee) {
            if (this.getConfigs.categoriesEmployee[a].id === newVal) {
               this.e.rights = this.getConfigs.categoriesEmployee[a].rights;
            }
          }
          //this.$store.dispatch("toastSuccess", "Zugriffsrechte neu vergeben");
        }
      }
    },
  },
};
</script>
<style scoped>
.inline {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 6px;
}
</style>
