<template>
  <template v-if="mode === 'listSort'">
    <div class="lineitem l-license fromPlugin pluginDfb isSort">
      <span tooltip="Erfasste Lizenzen" position="left"><i class="fa-duotone fa-address-card"></i></span>
    </div>
    <div class="lineitem l-licenseNo fromPlugin pluginDfb isSort">
      <span tooltip="Lizenznummer" position="left"><i class="fa-duotone fa-rectangle-barcode"></i></span></div>
    <div class="lineitem l-licenseVer fromPlugin pluginDfb isSort">
      <span tooltip="Verifizierung Lizenz" position="left"><i class="fa-duotone fa-file-certificate"></i></span>
    </div>
  </template>
  <template v-if="mode === 'listHeadline'">
    <div class="lineitem l-license fromPlugin pluginDfb" style="text-align: center;">
      <span v-if="customer._trainerLicenses && customer._trainerLicenses.length > 0"
            v-for="l in customer._trainerLicenses">{{ l._licenseTitle }}<br></span>
    </div>
    <div class="lineitem l-licenseNo fromPlugin pluginDfb">
      <span v-if="customer._trainerLicenses && customer._trainerLicenses.length > 0"
            v-for="l in customer._trainerLicenses">{{ l.license_no }}<br></span>
    </div>
    <div class="lineitem l-licenseVer fromPlugin pluginDfb">
      <span v-if="customer._trainerLicenses && customer._trainerLicenses.length > 0"
            v-for="l in customer._trainerLicenses">{{
          $t('plugins.dfb.status.' + l.verified)
        }}<br></span>
    </div>
  </template>
  <template v-if="mode ==='viewLicense'">
    <div class="card-body">
      <p v-if="!editLicense.id">Bitte Lizenz wählen:</p>
      <button @click="editIt(l.id)" class="btn btn-outline-primary me-3 mb-3"
              :class="(editLicense.id === l.id) ? 'btn-primary' : ''" v-for="l in ediLicenses">{{ l._licenseTitle }}
      </button>
      <div class="row" v-if="editLicense">
        <div class="col-lg-6">
          <div class="form-group">
            <label>Lizenz-Nummer</label>
            <input class="form-control" type="text" v-model="editLicense.license_no"/>
          </div>
          <div class="form-group">
            <label>Lizenz gültig von</label>
            <input class="form-control" type="date" v-model="editLicense.valid_from"/>
          </div>
          <div class="form-group">
            <label>Lizenz gültig bis</label>
            <input class="form-control" type="date" v-model="editLicense.valid_to"/>
          </div>
          <div class="form-group">
            <label>Start der Ausbildung</label>
            <input class="form-control" type="date" v-model="editLicense.start_from"/>
          </div>
          <div class="form-group">
            <label>Verifizierung</label>
            <div class="form-group">
              <div class="btn-group btn-group-toggle mb-3" data-toggle="buttons">
                <label class="btn btn-outline-primary" :class="(editLicense.verified === 'waiting') ? 'btn-primary':''">
                  <input style="opacity: 0;"
                    type="radio" v-model="editLicense.verified" autocomplete="off" value="waiting"><i class="fa-duotone fa-clock"></i> Warten</label><label
                class="btn btn-outline-primary" :class="(editLicense.verified === 'verified') ? 'btn-primary':''">
                <input style="opacity: 0;"
                  type="radio" v-model="editLicense.verified" autocomplete="off" value="verified"><i class="fa-duotone fa-check-circle"></i>
                Verifiziert</label><label
                class="btn btn-outline-primary" :class="(editLicense.verified === 'denied') ? 'btn-primary':''"><input style="opacity: 0;"
                type="radio" v-model="editLicense.verified" autocomplete="off" value="denied"><i class="fa-duotone fa-xmark-circle"></i> Abgelehnt</label>
              </div>
            </div>
            <div class="form-group">
              <label>Kommentar</label>
              <textarea class="form-control" v-model="editLicense.verified_comment"></textarea>
            </div>
            <div class="form-group">
              <button class="btn btn-outline-primary me-3" @click="saveLicense()"><i class="fa-duotone fa-floppy-disk"></i> Speichern</button>
              <button class="btn btn-outline-danger" @click="closeLicense()"><i class="fa-duotone fa-xmark"></i> Schließen</button>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <p class="mb-2"><strong>Vorderseite</strong></p>
          <template v-if="isPdf(editLicense.license_card_front)  && editLicense && editLicense.license_card_front">
            <VuePdfEmbed :source="editLicense.license_card_front" style="margin-bottom: 24px;max-width: 100%; "/>
          </template>
          <template v-else>
            <embed :src="editLicense.license_card_front" width="100%" height="auto" style="margin-bottom: 24px;"/>
          </template>
          <p class="mb-2"><strong>Rückseite</strong></p>
          <template v-if="isPdf(editLicense.license_card_back) && editLicense && editLicense.license_card_back">
            <VuePdfEmbed :source="editLicense.license_card_back"   style="margin-bottom: 24px;max-width: 100%; "/>
          </template>
          <template v-else>
            <embed :src="editLicense.license_card_back" width="100%" height="auto" style="margin-bottom: 24px;"/>
          </template>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import axios from "axios";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  props: {
    customer: {
      type: Object,
      required: false
    },
    mode: {
      type: String,
      required: true
    },
    sortDirections: {
      type: Object,
      required: false
    },
  },
  components: {
    VuePdfEmbed
  },
  emits: ['sort', 'refreshAfterSave'],
  data() {
    return {
      show: false,
      ediLicenses: [],
      editLicense: false
    }
  },
  methods: {
    isPdf(base64Data) {
      return base64Data.startsWith('data:application/pdf');
    },
    toggle() {
      this.show = !this.show
    },
    editIt(id) {
      this.getLicense(id);
    },
    getLicense(id) {
      // get license with axios from /customer/id/trainerLicense
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (id === undefined) {
        axios.get(this.$store.state.apiUrl + "customer/" + this.customer.id + "/trainerLicense/list", {headers}).then((r) => {
          if (r.data.result.data) {
            this.ediLicenses = r.data.result.data;
            this.editLicense = false;
          }
        });
      } else {
        axios.get(this.$store.state.apiUrl + "customer/" + this.customer.id + "/trainerLicense/" + id, {headers}).then((r) => {
          if (r.data.result.data) {
            this.editLicense = r.data.result.data;
          }
        });
      }
    },
    saveLicense() {
      // save license with axios to /trainer/license/{id}
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "trainer/license/" + this.editLicense.id, this.editLicense, {headers}).then((r) => {
        // toast success
        this.getLicense();
        this.$emit('refreshAfterSave');
        this.$store.dispatch('toastSuccess', 'Lizenz gespeichert');
      });
    },
    closeLicense() {
      this.editLicense = false;
    },
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }
      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }
      // Emit the clicked type and direction
      this.$emit('sort', {type: clickedType, direction: this.sortDirections[clickedType]});
    }
  },
  created() {
    if (this.mode === 'viewLicense') {
      this.getLicense();
    }
  }
}
</script>
