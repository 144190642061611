<template>
  <div class="card">
    <div class="card-header"><button type="button" class="btn-close float-end" @click='$store.dispatch("setAboActive",false)'></button><h6>Produktdetails</h6> </div>
    <div class="card-body">

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Titel</label>
            <input class="form-control" v-model="a.title">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Aktiv</label>
            <select v-model="a.active" class="form-select" >
              <option value="no">Nein</option>
              <option value="yes">Ja</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Kostenfrei</label>
            <select v-model="a.is_free"  class="form-select" >
              <option value="no">Nein</option>
              <option value="yes">Ja</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Legt einen CRM-Vertrag an?</label>
            <select v-model="a.is_contract"  class="form-select" >
              <option value="no">Nein</option>
              <option value="yes">Ja</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Aktueller %-Rabatt auf alle diese Subscription</label>
            <input class="form-control" type="number" v-model="a.discount" step="1" min="0">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>Gibt es Varianten / Laufzeiten?</label>
            <select v-model="a.option_type" class="form-control">
              <option value="normal">Nein</option>
              <option value="extended">Ja</option>
            </select>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>Dokument anfordern</label>
            <select v-model="a.request_document" class="form-control">
              <option value="normal">Nein</option>
              <option value="extended">Ja</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label>Dokument anfordern Titel</label>
          <input class="form-control" v-model="a.request_doc_title">
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label>E-Mail-Betreff</label>
          <input class="form-control" v-model="a.mail_subject">
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>E-Mail-Text</label>
          <Editor2 :content="a.mail_body"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          {{ a }}
          <button type="button" class="btn btn-secondary float-end mt-3" @click="updateAbonnement()">Speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import axios from "axios";
import axios from "axios";
import Editor2 from "@/components/Editor2";

export default {
  name: "AbonnementDetails",
  components: {Editor2},
  props: {
    abonnement: {},
  },
  data() {
    return {
      a: {},
      isNew: true
    };
  },
  methods: {
    updateProduct() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.isNew) {
        axios.post(this.$store.state.apiUrl + "subscription/", VueInner.p, {headers}).then(() => {
          console.log('test2');
        }).catch(() => {
        });
      } else {
        axios.put(this.$store.state.apiUrl + "subscription/" + VueInner.p.id, VueInner.p, {headers}).then(() => {
          console.log('test2');
        }).catch(() => {
        });
      }
    },
    checkIfIsNew() {
      if (this.abonnement && this.abonnement.id) {
        this.a = this.abonnement;
        this.isNew = false;
      } else {
        this.a = {};
        this.isNew = true;
      }
    }
  },
  created() {
    this.checkIfIsNew();
  },
  watch: {
    abonnement: function (val) {
      this.a = val;
      this.checkIfIsNew();
    },
  }
};
</script>
