<template>
  <div class="pluginLineWrap">
    <div class="lineitem l-number"  @click="setAbo(abo)">{{ abo.is_free }}
    </div>
    <div class="lineitem l-title"  @click="setAbo(abo)">{{ abo.title }}
    </div>


    <template v-if="aboActive.id === abo.id">
    <div class="lineitem last ml-auto" @click="setAbo(false)">
      <i class="fa-duotone fa-x fa-swap-opacity"></i>
    </div>
    </template>
    <template v-else>
    <div class="lineitem last ml-auto" @click="setAbo(abo)">
      <i class="fa-duotone fa-chevrons-up"></i>
    </div>
    </template>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    abo: Object,
  },
  computed: {
    ...mapGetters(["getEmployees", "aboActive"])
  },
  methods: {
    setAbo(abo) {
      this.$store.dispatch("setAboActive", abo);
    },
  }
}
</script>
