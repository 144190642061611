<template>
  <div class="document-item headline">
  <div class="documentLineWrap" >
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-number">
      <span @click="emitSort('doc_number')" :class="(sortDirections.doc_number) ? 'active' : ''">Nummer</span>
      <i v-if="sortDirections.doc_number === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.doc_number === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title">
      <span @click="emitSort('title')"  :class="(sortDirections.title) ? 'active' : ''">Titel</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-company" >
      <span @click="emitSort('customer')"  :class="(sortDirections.customer) ? 'active' : ''">Kunde</span>
      <i v-if="sortDirections.customer === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.customer === 'desc'" class="fa-duotone fa-arrow-down"></i>

    </div>
    <div class="lineitem l-date">
      <span @click="emitSort('doc_d_date')" :class="(sortDirections.doc_d_date) ? 'active' : ''">Datum</span>
      <i v-if="sortDirections.doc_d_date === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.doc_d_date === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-netto" v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.document_brutto_netto !== 'brutto'" >
      <span @click="emitSort('complete_netto')" :class="(sortDirections.complete_netto) ? 'active' : ''">Preis netto</span>
      <i v-if="sortDirections.complete_netto === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.complete_netto === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-brutto" v-else>
      <span @click="emitSort('complete_brutto')" :class="(sortDirections.complete_brutto) ? 'active' : ''" >Preis brutto</span>
      <i v-if="sortDirections.complete_brutto === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.complete_brutto === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem actions" >

    </div>
    <div class="lineitem l-actions ml-auto">

    </div>
    <div class="lineitem l-actions ">
      </div>
    <div class="lineitem l-avatar" v-if="!$store.state.filters.documents.owner.value"></div>
    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        customer: '',
        doc_d_date: '',
        complete_netto: '',
        complete_brutto: '',
        doc_number: '',
        id: '',
      }

    }
  },
  props: {
    document: Object,
  },
  computed: {
    ...mapGetters(["documentActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
