<template>
  <div class="appView drafts ">
    <Details  v-if="$route.params.id"/>
    <List  />
  </div>


</template>
<script>

import List from "./List.vue";
import Details from "@/routing/core/Drafts/Details.vue";
import {mapGetters} from "vuex";
export default {
  name: "DraftsOverview",
  components: {
    Details,
    List
  },
  computed: {
  },
}
</script>
<style lang="scss">
.product-item {
  cursor: pointer;
}
</style>
