<template>



  <div class="sidenav-card text-center" v-if="$store.state.publishNewVersion">
      <a   href="/#/" @click="$store.state.publishNewVersion = false;" class="button btn btn-success mb-3">Neue Version verfügbar!</a>
  </div>



  <p style="font-size: 10px; text-align: center" class="mb-0">Version {{$store.state.version}}</p>
<div class="brandingLogo" v-if="getConfigs && getConfigs.params_values"><img v-if="getConfigs.params_values.image_rechnung" :src="getConfigs.params_values.image_rechnung"/></div>


</template>
<script>
import {mapGetters} from 'vuex';
export default {
  name: "sidenav-card",
  props: ["textPrimary", "textSecondary", "iconClass"],
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters(["getConfigs"])
  },
};
</script>
