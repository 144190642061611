<template>
  <div class="row flex">
    <div class="col-lg-12">
      <div class="card">
        <h6 class="card-header">Kontakte nach Land </h6>
        <statisticCustomerTable :cType="'filter_country'" :stats="statsByCountry"/>
      </div>
    </div>
  </div>
</template>
<script >
import {BarChart} from "vue-chart-3";
import axios from "axios";
import {mapGetters} from "vuex";
import statisticCustomerTable from "@/routing/core/Dashboard/statistic/CustomerTable.vue";
export default {
  name: 'country',
  components: {
    statisticCustomerTable,
    BarChart,
  },
  data() {
    return {

      loading: false,
      statsByCountry: {},
    }
  },
  props: {
    filter: {
      type: String,
      default: ""
    },
    options: {
      type: Object

    }
  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  methods: {
    getStatsCountry() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/countries" + this.filter, {headers}).then((r) => {
        VueInner.statsByCountry = r.data.result.data;
        VueInner.loading = false;
      });
    },
  },
  created() {

    if (this.getMenu && this.getMenu.customers) {
    this.getStatsCountry();
    }
  },
  watch: {
    getMenu: function () {
      this.getStatsCountry();
    },
    filter: function () {
      this.getStatsCountry();
    }
  }
}
</script>
