<template>
  <div class="row" v-if="getMenu && getMenu.accounting">
    <div class="col-lg-12 mb-lg">
      <div class="card z-index-2">
        <LineChart v-if="chartData.labels" :chartData="chartData" class="boxWhite" :options='chartOptions'/>
      </div>
    </div>
  </div>
</template>
<script >
import {LineChart} from "vue-chart-3";
import axios from "axios";
import {mapGetters} from "vuex";
export default {
  name: 'turnover',
  components: {
    LineChart
  },
  data() {
    return {
      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
        },

        scales: {
          y: {
            grid: {
              drawBorder: true,
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#ccc",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: true,
              display: false,
              drawOnChartArea: true,
              drawTicks: true,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      }
    }
  },
  props: {
    filter: {
      type: String,
      default: ""
    },
    options: {
      type: Object

    }
  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  methods: {
    getStatsInvoicesYear() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/invoices/year" + this.filter, {headers}).then((r) => {
        if (r.data.result && r.data.result.counter) {
          VueInner.chartData = r.data.result.data;
        }
        VueInner.loading = false;
      });
    },
  },
  created() {
    this.getStatsInvoicesYear();
  },
  watch: {
    filter: function (newVal, oldVal) {
      this.getStatsInvoicesYear();
    }
  }
}
</script>
