<template>
  <template v-if="onlyid">
  <VueMultiselect

    :options="customers"
    :multiple="false"
    :searchable="true"
    :close-on-select="true"
    :show-labels="false"
    :canClear="true"
    :canDeselect="true"
    @search-change="debouncedFetchOptions"
    valueProp="id"
    class="mb-3"
    :class="(small) ? 'small' : ''"
    :label="'full_name'"
    locale="de"
    :placeholder="'Kunden suchen'"
    :showNoOptions="false"
    :noOptionsText="'Bitte nach Kunden suchen'"
    :noResultsText="'Leider keine Kunden gefunden'"
    :minChars="3"
    :filterResults="false"
    v-model="returnCustomer"
    ref="customerSelect"
    openDirection="bottom"
  />
  </template>
  <template v-else>
  <VueMultiselect

    :options="customers"
    :multiple="false"
    :searchable="true"
    :close-on-select="true"
    :show-labels="false"
    :canClear="true"
    :canDeselect="true"
    :object="true"
    @search-change="debouncedFetchOptions"
    valueProp="id"
    class="mb-3"
    openDirection="bottom"
    :class="(small) ? 'small' : ''"
    :label="'full_name'"
    locale="de"
    :placeholder="'Kunden suchen'"
    :showNoOptions="false"
    :noOptionsText="'Bitte nach Kunden suchen'"
    :noResultsText="'Leider keine Kunden gefunden'"
    :minChars="3"
    :filterResults="false"
    v-model="returnCustomer"
    ref="customerSelect" />
  </template>
</template>
<script>
import VueMultiselect from '@vueform/multiselect'
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import {mapGetters} from 'vuex';

export default {
  name: "customersearch.vue",
  emits: ['changed-customer'],
  props:{
    small: {
      type: Boolean,
      default: false
    },
    onlyid: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueMultiselect, Multiselect
  },
  computed: {
    ...mapGetters(["getApiUrl"]),
  },
  data() {
    return {
      customers: [ ],
      returnCustomer: {},
    };
  },
  methods:{

    debounce(func, wait) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    },
    fetchOptions(searchTerm) {
      if (searchTerm.length >= 3) {
        let headers = {
          'crmAuth': localStorage.getItem('sketchManagerToken')
        };
        // prepare searchterm, strtolower and urlencode
        searchTerm = searchTerm.toLowerCase();
        searchTerm = encodeURIComponent(searchTerm);
        axios.get(this.$store.state.apiUrl + "customer/search?limit=0&quick=true&filter_search=" + searchTerm, {headers}).then((response) => {
            this.customers = response.data.result.data;
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        // Clear the options when the search term is less than 3 characters
        this.customers = [];
      }
    },
    callCustomerChange(selectedCustomer){
     // emit changed customer next tick
      this.$nextTick(() => {
        this.$emit('changed-customer', this.returnCustomer);
      });

    }

  },
  watch: {
    returnCustomer: {
      handler(newVal, oldVal) {
        this.$emit('changed-customer', newVal);
      },
      deep: true
    }
  },

  created(){
    this.debouncedFetchOptions = this.debounce(this.fetchOptions, 300);
  }

}
</script>

