<template>
  <div class="fileLineWrap" :class="( activeTempFile && activeTempFile.id === file.id ) ? 'active':''">
    <div class="lineitem l-title" @click="setFile(file);">
      {{ file.filename }}
    </div>
    <div class="lineitem l-category">
      <img
        :src="$filters.employeeIcon(getEmployees, file.uploaded_by, 'u_id')"
        class="sketchAvatar">

    </div>
    <div class="lineitem l-date">
      {{ $filters.formatDate(file.uploaded_on) }}
    </div>
    <div class="lineitem l-delete">
      <button class="btn btn-outline-danger btn-sm float-end ms-3" @click="deleteFile(file.id)" :tooltip="'Temporäre Datei löschen'" position="right">
        <i class="fa-duotone fa-trash" ></i>
      </button>
    </div>
    <div class="lineitem last ml-auto">
      <template v-if="activeTempFile && activeTempFile.id === file.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="closeFile"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up" @click="setFile(file)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
export default {
  name: "Headline",
  emits: ["close-file", "set-file"],
  props: {
    file: Object,
    fileActive: Object
  },
  computed: {
    ...mapGetters([ "getEmployees"])
  },
  methods: {
    closeFile() {
      this.$emit("close-file");
    },
    setFile(file) {
      this.$emit("set-file", file);
    },
    deleteFile(id) {

      this.$confirm('Soll die temporäre Datei wirklich gelöscht werden?', 'Datei löschen', 'warning', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + 'files/uploadTemp/' + id, {headers: this.headers})
          .then((response) => {
            this.toast.success('Datei erfolgreich gelöscht.');
            const triggerReloadTask = new CustomEvent('triggerReloadFiles');
            window.dispatchEvent(triggerReloadTask);
          })
          .catch((error) => {

            const triggerReloadTask = new CustomEvent('triggerReloadFiles');
            window.dispatchEvent(triggerReloadTask);
          });
      })
    }
  },
  data() {
    return {
      activeTempFile: false,
      headers: {
        'crmAuth': localStorage.getItem('sketchManagerToken')
      },
    }
  },
  created(){
    this.activeTempFile = this.fileActive;
  },
  watch: {
    fileActive: function (val) {
      this.activeTempFile = val;
    }
  }

}
</script>

