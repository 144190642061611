<template>
  <div class="pluginLineWrap">

    <div class="lineitem l-number"  @click="setLegal(legal)">{{ legal.type }}
    </div>
    <div class="lineitem l-title"  @click="setLegal(legal)">{{ legal.title }}
    </div>
    <div class="lineitem l-date">{{ $filters.formatDate(legal.created_on) }}
    </div>
    <div class="lineitem l-date">{{ legal.version }}
    </div>
    <div class="lineitem  l-avatar">
      <img style="max-width: 40px" position="right" tooltip="zuletzt bearbeitet"
           :src="$filters.employeeIcon(getEmployees, legal.crm_modified_user_id, 'u_id')"
           class="sketchAvatar">
    </div>
    <template v-if="legalActive.hash === legal.hash">
    <div class="lineitem last ml-auto" @click="setLegal(false)">
      <i class="fa-duotone fa-x fa-swap-opacity"></i>
    </div>
    </template>
    <template v-else>
    <div class="lineitem last ml-auto" @click="setLegal(legal)">
      <i class="fa-duotone fa-chevrons-up"></i>
    </div>
    </template>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    legal: Object,
  },
  computed: {
    ...mapGetters(["legalActive", "getEmployees"])
  },
  methods: {
    setLegal(legal) {
      this.$store.state.activeItems.legal = legal;
    },
  }
}
</script>
