<template>
  <h3>{{project.title}}</h3>
  <div class="infoBlock"><i
    class="fa-duotone fa-calendar-circle-plus"></i> Erstellt: {{$filters.formatDate(project.crm_created_date)}} | <i
    class="fa-duotone fa-calendar-circle-user"></i> Bearbeitet:
    {{$filters.formatDate(project.crm_modified_date)}}</div>
  <p>{{project.c_firstname}} {{project.c_lastname}} <template v-if="project.c_company">- {{project.c_company}}</template></p>
  <Holzkraft :project="project"  v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft" mode="show" />

  <div class="projectDesc" v-if="project.description">
    <label class="color">Beschreibung</label>
    <div v-html="project.description"></div>
  </div>

</template>
<script>
import {mapGetters} from "vuex";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects";
export default {
  name: "Details",
  emits: ['refresh-project-list'],
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  components: {
    Holzkraft
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees",  "modalsList", "getPlugins"])
  },
  created() {
    this.$store.dispatch("setProject", this.project);
  },
}
</script>
