<template>
  <div class="card">
    <div class="card-header">
      <h6>Jährlicher Umsatz</h6>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6 col">
          <strong>Chart:</strong>
          <div class="chart">
            <DoughnutChart :chartData="chartData" :options="options"/>
          </div>
        </div>
        <div class="col-lg-6 col">
          <strong>Umsatz</strong> dieses Quartal <br>
          <h4>{{ $filters.priceEuro(quartal) }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {DoughnutChart} from 'vue-chart-3';

export default {
  name: 'projectTurnover',
  components: {
    DoughnutChart
  },
  data() {
    return {
      turnoverData: {}, // Current turnover
      quartal: 0,
      chartData: {
        labels: ['Umsatz', 'Offen'],
        datasets: [
          {
            data: [20, 80], // 20% for turnover, 80% for remaining goal
            backgroundColor: [
              'rgb(54, 162, 235)', // Color for turnover
              'rgb(211, 211, 211)' // Color for remaining goal
            ]
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          }
        },
        cutout: '40%' // Adjust for desired thickness of the doughnut
      }
    }
  },
  props: {
    filter: {
      type: String,
      default: ""
    },

  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  methods: {
    getTurnover() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/projects/year" + this.filter, {headers}).then((r) => {
        VueInner.turnoverData = r.data.result.data;
        VueInner.chartData.datasets[0].data[0] = VueInner.turnoverData.complete;
        VueInner.chartData.datasets[0].data[1] = VueInner.turnoverData.turnover - VueInner.turnoverData.complete;
        VueInner.quartal = VueInner.turnoverData.quartal;
        VueInner.loading = false;
      });
    },
  },
  watch: {
    filter: function (newVal, oldVal) {
      this.getTurnover();
    },
  },
  created() {
    this.getTurnover();
  },
}
</script>
