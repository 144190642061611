<template>
  <div class="row flex">
    <div class="col-xl-6 col-12 mb-4">
      <div class="card">
        <h6 class="card-header">{{ $t("dashboard.cardHeader.customerType") }}</h6>
        <statisticCustomerTable :cType="'filter_type'" :stats="statsByType"/>
      </div>
    </div>
    <div class="col-xl-6 col-12  mb-4">
      <div class="card">
        <h6 class="card-header">{{ $t("dashboard.cardHeader.customerPriority") }}</h6>
        <statisticCustomerTable :cType="'filter_prio'" :stats="statsByPrio"/>
      </div>
    </div>
  </div>
  <div class="row flex">
    <div class="col-xl-6 col-12 mb-4">
      <div class="card">
        <h6 class="card-header">{{ $t("dashboard.cardHeader.customerAcquisition") }}</h6>
        <statisticCustomerTable :cType="'filter_akquise'" :stats="statsByAkquise"/>
      </div>
    </div>
    <div class="col-xl-6 col-12 mb-4">
      <div class="card">
        <h6 class="card-header">{{ $t("dashboard.cardHeader.customerCategories") }}</h6>
        <statisticCustomerTable :cType="'filter_category'" :stats="statsByCat"/>
      </div>
    </div>
  </div>
</template>
<script >
import {LineChart} from "vue-chart-3";
import axios from "axios";
import {mapGetters} from "vuex";
import statisticCustomerTable from "@/routing/core/Dashboard/statistic/CustomerTable.vue";
export default {
  name: 'customer',
  components: {
    statisticCustomerTable,
    LineChart
  },
  data() {
    return {
      statsByType: {},
      statsByPrio: {},
      statsByAkquise: {},
      statsByCat: {},

    }
  },
  props: {
    filter: {
      type: String,
      default: ""
    },
    options: {
      type: Object

    }
  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  methods: {
    getStatsTypes() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/types" + this.filter, {headers}).then((r) => {
        VueInner.statsByType = r.data.result.data;
        VueInner.loading = false;
      });
    },
    getStatsPrio() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/prio" + this.filter, {headers}).then((r) => {
        VueInner.statsByPrio = r.data.result.data;
        VueInner.loading = false;
      })
    },
    getStatsAkquise() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/akquise" + this.filter, {headers}).then((r) => {
        VueInner.statsByAkquise = r.data.result.data;
        VueInner.loading = false;
      });
    },
    getStatsCat() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/customers/categories" + this.filter, {headers}).then((r) => {
        VueInner.statsByCat = r.data.result.data;
        VueInner.loading = false;
      });
    },
  },
  created() {
    if (this.getMenu && this.getMenu.customers) {
      this.getStatsTypes();
      this.getStatsPrio();
      this.getStatsAkquise();
      this.getStatsCat();
    }
  },
  watch: {
    filter: function (newVal, oldVal) {
      if (this.getMenu && this.getMenu.customers) {
        this.getStatsTypes();
        this.getStatsPrio();
        this.getStatsAkquise();
        this.getStatsCat();
      }
    }
  }
}
</script>
