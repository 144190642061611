<template>
  <template v-if="$route && $route.matched && $route.matched.length">
    <template v-if="$route.matched[0].name === 'customers'">
      <button class="btn btn-outline-primary btn-sm btn-hide-mobile  me-3"
              position="left" tooltip="Bericht erstellen"
              @click="$store.commit('openModal', 'report');getExportableFields() "><i
        class="fa-duotone fa-file-circle-info"></i></button>
    </template>
  </template>
  <Modal v-if="modalsList.report">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="productItem activeProduct">
          <div class="card">
            <div class="card-header ">
              <button type="button" class="btn btn-close float-end ms-4" style="position: relative;
z-index: 1;"
                      @click="$store.commit('closeModal', 'report');">
              </button>
              <template v-if="$route.matched[0].name === 'customers'">
                <template v-if="loading">
                  <button class="btn btn-outline-primary btn-sm float-end  me-3" :tooltip="$t('report.customers')"
                          position="left" style="z-index: 1;" disabled
                  >
                    <i class="fa-duotone fa-spinner-third fa-spin"></i> Bericht wird erstellt..
                  </button>
                </template>
                <template v-else>
                  <template v-if="title && subtype">
                    <button class="btn btn-outline-primary btn-sm float-end  me-3" :tooltip="$t('report.customers')"
                            position="left" style="z-index: 1;"
                            @click.once="loading = true;reportData('customers', exportFields)">
                      <i class="fa-duotone fa-file-circle-info"></i> Bericht erstellen
                    </button>
                  </template>
                  <template v-else>
                    <button class="btn btn-outline-primary btn-sm float-end  me-3" :tooltip="$t('report.customers')"
                            position="left" style="z-index: 1;" disabled
                    >
                      <i class="fa-duotone fa-file-circle-info"></i> Bericht erstellen
                    </button>
                  </template>
                </template>
              </template>
              <button class="btn btn-sm float-end  me-3" :tooltip="(setAsDraft === 1) ? 'wird gespeichert' : 'wird nicht gespeichert'"
                      :class="(setAsDraft === 1) ? 'btn-primary' : 'btn-outline-primary'"
                      position="left" style="z-index: 1;"
                      @click="toggleSetDraft()">
                <i class="fa-duotone fa-file-circle-info"></i> Als Vorlage verwenden
              </button>
              <div class="form-group float-end me-3" v-if="fields && fields.customersFieldDrafts">
                <select class="form-control-sm" v-model="selectedFields" @change="applySelectedFields">
                  <option :value="[]">Vorlage wählen</option>
                  <option v-for="field in fields.customersFieldDrafts" :value="field.fields">{{ field.title }}</option>
                </select></div>
              <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{ $t("report.customers") }}</h6>
            </div>
            <div class="card-body">
              <div class="row" >
                <div class="col-12"><p v-if="customerSelected && customerSelected.length > 0">Direkt ausgewählte Datensätze: {{customerSelected.length}}</p>
                  <p v-else><template v-if="$store.state.lists.customers.complete">Gefilterte Datensätze: {{$store.state.lists.customers.complete }}</template></p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6"><input type="text" v-model="title" class="form-control" placeholder="Berichttitel"/>
                </div>
                <div class="col-6"><input type="text" v-model="subtype" class="form-control"
                                          placeholder="Art des Berichts"/></div>
              </div>
              <div class="row">
                <div class="col" style="overflow-y: auto; overflow-x: hidden;">
                  <h6>Diese Felder exportieren:</h6>
                  <p><small>Reihenfolge per Drag & Drop festlegen.</small></p>
                  <draggable v-model="exportFields" group="fields" class="drag-area" item-key="name"
                             style="height: 100%;">
                    <template #item="{element}">
                      <div class="drag-item">{{ $t('exportField.' + element) }}</div>
                    </template>
                  </draggable>
                </div>
                <div class="col">
                  <h6>Diese Felder nicht exportieren:</h6>
                  <p><small>Elemente ablegen, die ignoriert werden sollen:</small></p>
                  <draggable v-model="ignoredFields" group="fields" class="drag-area" item-key="name">
                    <template #item="{element}">
                      <div class="drag-item">{{ $t('exportField.' + element) }}</div>
                    </template>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import Mail from "@/components/Mail.vue";
import axios from "axios";
import draggable from "vuedraggable";

export default {
  name: "reportSelected",
  data() {
    return {
      batchMailWindow: false,
      fields: [],
      exportFields: [],
      ignoredFields: [],
      title: '',
      subtype: '',
      loading: false,
      setAsDraft: 0,
      selectedFields: [],
    };
  },
  computed: {
    ...mapGetters(['modalsList', 'getUser', 'customerSelected']),
  },
  components: {Mail, draggable},
  methods: {
    toggleSetDraft() {
      this.setAsDraft = this.setAsDraft === 0 ? 1 : 0;
    },
    applySelectedFields() {
      this.exportFields = this.selectedFields;
    },
    getExportableFields() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "config/exportableFields", {headers}).then((r) => {
        this.fields = r.data.result.data;
        const routeName = this.$route.name;
        // Set exportFields based on the route name
        if (routeName === 'customers') {
          this.ignoredFields = this.fields.customers;
        } else if (routeName === 'Documents') {
          this.ignoredFields = this.fields.documents;
        } else if (routeName === 'Projects') {
          this.ignoredFields = this.fields.documents;
        }
        // get difference from exportFields and latestFields and write in ignoredFields
        let tempExportFields = this.exportFields;
        // if (this.getUser.latestFields && this.exportFields) {
        //   this.exportFields = tempExportFields.filter(field => this.getUser.latestFields.includes(field));
        //   this.ignoredFields = tempExportFields.filter(field => !this.getUser.latestFields.includes(field));
        // }
        this.exportFields = [];
      });
    },
    reportData(listName, exportFields) {
      let title = this.title;
      let subtype = this.subtype;
      let VueInner = this;
      let setAsDefault = this.setAsDraft;
      this.$store.dispatch('reportSelected', {listName, exportFields, title, subtype, setAsDefault}).then(() => {
        VueInner.loading = false;
        VueInner.title = '';
        VueInner.subtype = '';
        VueInner.$store.state.user.latestFields = VueInner.exportFields;
      });
    },
  },
  created() {
    this.getExportableFields();
  }
}
</script>
<style scoped>
.drag-area {
  border: 1px solid #ccc;
  min-height: 200px;
  list-style-type: none;
  padding: 10px;
}

.drag-item {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  background-color: #f8f8f8;
}
</style>
