<template>
  <div class="card">
    <h6 class="card-header"> {{$t("globals.notes")}}
      <button type="button" class="btn btn-outline-primary float-end  btn-sm"
              @click="setNotice({})">
        <i class="fa-duotone fa-pen-field"></i> {{$t("globals.newNote")}}
      </button>
    </h6>
    <div class="card-body" v-if="notices && notices.length > 0">
      <div ref="activeCustomer" style="max-height: 350px; overflow-y: scroll; overflow-x: hidden;">
        <div class="row mb-3 pb-3 borderRow" v-for="notice in notices" :key="notice.id">
          <!--{{ notice }}-->

          <div class="col-12">
            <div class="btn-group-vertical float-end me-2" style=" margin-left: 24px;">
              <button type="button" class="btn btn-outline-primary text-center btn-sm" @click="setNotice(notice)"  :tooltip="this.$t('globals.editNote')" :position="'left'">
                <i class="fa-duotone fa-pen-to-square"></i>
              </button>
              <button type="button" class="btn btn-outline-danger text-center btn-sm" :tooltip="this.$t('globals.deleteNote')" :position="'left'" @click="deleteNotice(notice.id)">
                <i class="fa-duotone fa-trash-xmark"></i>
              </button></div>
            <div class="inline">
            <p class="mb-2 text-sm font-weight-bold"><img style="max-width: 40px"
                                                          :src="$filters.employeeIcon(getEmployees, notice.notice_author, 'u_id')"
                                                          class="sketchAvatar">
              {{ $filters.formatDateTime(notice.notice_date) }} <span
                class="badge badge-primary ms-2">{{ notice.notice_type }}</span></p>
            <p class="mb-0 text-xs" v-html="notice.notice_message"></p></div>
          </div>

        </div>
      </div>
    </div>
    <div class="card-body" v-else><p class="noItemsFound">{{$t("activity.noEntriesFound")}}</p></div>
    <Modal v-if="activeNotice">
      <div class="modal fade show" :id="'edit' + activeNotice.id" tabindex="-1" aria-labelledby="exampleModalLabel"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                <template v-if="activeNotice.id">
                  {{$t("globals.editNote")}}
                </template>
                <template v-else>
                  {{$t("globals.newNote")}}
                </template>
              </h5>
              <button type="button" class="btn-close" @click="resetNotice()"></button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>{{$t("globals.note")}}</label>
                <Editor2  v-model="activeNotice.notice_message" />

              </div>
            </div>
            <div class="modal-footer">
              <div class="row w-100">
                <div class="col">
                  <button type="button" class="btn btn-outline-secondary"  @click="resetNotice()">
                    <i class="fa-duotone fa-xmark-circle"></i> {{$t("globals.cancel")}}
                  </button>
                </div>
                <div class="col" style="text-align: right;">
                  <button type="button" class="btn btn-outline-primary float-end" @click="editNotice()" v-if="activeNotice.id">
                    <i class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
                  </button>
                  <button type="button" class="btn btn-outline-primary float-end" @click="postNotice()" v-else><i class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>
<script>
import axios from "axios";

import {mapGetters} from "vuex";
import {ref} from "vue";
import Editor2 from "@/components/Editor2";

export default {
  name: "CustomerNotices",

  computed: {
    ...mapGetters(["getEmployees", "customerActive"]),
  },
  components: {

    Editor2
  },
  props: {
    customer: {}
  },
  methods: {
    setNotice(notice) {
      this.activeNotice = notice;
    },
    resetNotice() {
      this.activeNotice = null;
    },
    deleteNotice(id) {
      let Vueinner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "notice/" + id, {headers}).then((r) => {
        Vueinner.notices = r.data.result.data;
        Vueinner.loading = false;
        this.$store.dispatch('toastSuccess', 'Notiz erfolgreich gelöscht.');
      });
    },
    editNotice() {
      let Vueinner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let editedNotice = {
        hash: this.customerActive.hash,
        data: {
          message: this.activeNotice.notice_message
        }
      }
      axios.put(this.$store.state.apiUrl + "notice/" + Vueinner.activeNotice.id, editedNotice, {headers}).then((r) => {
        Vueinner.notices = r.data.result.data;
        Vueinner.loading = false;
        this.activeNotice = null;
        this.$store.dispatch('toastSuccess', 'Notiz erfolgreich gespeichert.');
      });
    },
    postNotice() {
      let Vueinner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {
        hash: this.customerActive.hash,
        data: {
          message: this.activeNotice.notice_message
        }
      }
      axios.post(this.$store.state.apiUrl + "notice/", postContent, {headers}).then((r) => {
        Vueinner.notices = r.data.result.data;
        Vueinner.loading = false;
        this.activeNotice = null;
        this.$emit('noticeAdded', true);
        this.$store.dispatch('toastSuccess', 'Notiz erfolgreich erstellt.');

      });
    },
    getNotices() {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/notices", {headers}).then((r) => {
        this.notices = r.data.result.data;
      });
    }
  },
  data() {
    return {
      notices: [],
      activeNotice: null,
      noticeToPost: [],
    };
  },
  created() {
    this.getNotices();
  },
  watch: {
    customerActive: function () {
      this.getNotices();
    }
  },
  emits: ["noticeAdded"],
};
</script>
<style lang="scss">
.modal {
  z-index: 10000 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}
</style>
