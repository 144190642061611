
<template>
  <Modal>
    <div class="modal show"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            Profil bearbeiten
            <button type="button" class="btn-close" @click="$emit('close')"></button>
          </div>
          <div class="modal-body">
            {{getUser}}
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      showMenu: false
    };
  },
  computed: {
    ...mapGetters(['getUser'])
  },
  components: {},

  methods: {
  },
};
</script>

