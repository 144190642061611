<template>
  <div class="appView plugin-list">
    <List/>
  </div>
</template>


<script>
import axios from "axios";
import {mapGetters} from "vuex";
import List from "@/routing/core/Legals/List.vue";

export default {
  name: 'LegalOverview',
  components: {List},
  computed: {
    ...mapGetters(["getEmployees", "legalList"]),
  },
  methods: {
    /*
    setAbonnement(abonnement) {

      this.activeProduct = abonnement;
      //this.$router.push({name: "AbonnementDetails", params: {id: product.id}});

    },
    */


  },
  data() {
    return {

    };
  },
  created() {

  },
}
</script>
