<template>

  <div class="draftLineWrap" :class="($store.state.activeItems && $store.state.activeItems.ConfigWorkflowStates && $store.state.activeItems.ConfigWorkflowStates.id === draft.id) ? 'active' : ''" >
    <div class="lineitem l-title" @click="setConfig(draft)">
      {{ draft.title }}
    </div>

    <div class="lineitem l-ordering" style="font-size: 13px; width: 150px;" >
      {{ $t("states.issueStates."+draft.state) }}
    </div>

    <div class="lineitem l-default">
      <i class="fa-duotone" :class="draft.icon" :style="'color:'+draft.color"></i>
    </div>

    <div class="lineitem l-color" :style="'background:'+draft.color+ '; color: #fff;'">
      {{ draft.color }}
    </div>

    <div class="lineitem l-default">
      <span tooltip="Für den Kunden sichtbar" position="left">
      <template v-if="draft.official==='yes'">
        <i class="fa-duotone fa-check-circle" style="color: #3B8130"></i>
      </template>
      <template v-else>
        <i class="fa-duotone fa-xmark-circle" style="color: #b21f28"></i>
      </template>
      </span>
    </div>

    <div class="lineitem l-number ml-auto">
      {{ draft.ordering }}
    </div>

    <div class="lineitem last ">
      <template v-if="$store.state.activeItems && $store.state.activeItems.ConfigWorkflowStates && $store.state.activeItems.ConfigWorkflowStates.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigWorkflowStates');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters([ "getEmployees", "getConfigWorkflowStates"])
  },
  methods: {
    setConfig(draft) {
      const newData = {data: draft, type:'ConfigWorkflowStates'};
      this.$store.dispatch("setActiveConfig",  newData).then(() => {
        this.$router.push({name: 'ConfigWorkflowStatesDetails', params: {id: draft.id}})
      });


    },
  }
}
</script>
