<template>
  <div class="appView drafts purpleView">
    <Details  v-if="$route.params.id"/>
    <List  />
  </div>


</template>
<script>

import List from "./List.vue";
import Details from "@/routing/core/Categories/Details.vue";
export default {
  name: "CategoriesOverview",
  components: {
    Details,
    List
  },



};
</script>
<style lang="scss">
.product-item {
  cursor: pointer;
}
</style>
