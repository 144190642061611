<template>
  <div class="row" v-if="stats && stats.data">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <label for="dateSelect">Datum wählen: </label>
            <select id="dateSelect" class="form-select" v-model="selectedDate" @change="updateChart">
              <option value="">- Bitte Datum wählen -</option>
              <option v-for="item in stats.data" :key="item.date" :value="item.date">
                {{ $filters.formatDate(item.date) }}
              </option>
            </select>
          </div>
          <BarChart :chartData="barChartStats" :options="barChartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BarChart } from "vue-chart-3";

export default {
  name: "SchoolView",
  components: {
    BarChart
  },
  props: {
    stats: {
      type: Object,
      default: () => ({ data: [] })
    },
    compareDate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedDate: '', // This will store the selected date
      barChartStats: {
        labels: [],
        datasets: [
          {
            label: 'Accepted',
            data: [],
            backgroundColor: '#188360',
          },
          {
            label: 'Denied',
            data: [],
            backgroundColor: 'rgb(248,26,44)',
          }
        ]
      },
      barChartOptions: {
        indexAxis: 'x', // Horizontal bars
        responsive: true,
        maintainAspectRatio: false, // Ensure the chart fills the container
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
            ticks: {
              autoSkip: false, // Ensure all labels are shown
              maxRotation: 0,
              minRotation: 0
            }
          }
        },
        plugins: {
          legend: {
            position: 'top', // Optional: Move legend to the top for better spacing
          }
        },
        barThickness: 15, // Adjust this value to control bar thickness
        categoryPercentage: 0.8, // Adjust the space between bars (lower value means more space)
      }
    };
  },
  methods: {
    prepareChartData(date) {
      const labels = [];
      const acceptedData = [];
      const deniedData = [];

      const selectedData = this.stats.data.find(item => item.date === date);

      if (selectedData) {
        for (const key in selectedData.data) {
          if (selectedData.data.hasOwnProperty(key)) {
            const degree = selectedData.data[key];
            labels.push(degree.name);
            acceptedData.push(degree.countAccepted);
            deniedData.push(degree.countDenied);
          }
        }

        this.barChartStats.labels = labels;
        this.barChartStats.datasets[0].data = acceptedData;
        this.barChartStats.datasets[1].data = deniedData;


      }
    },
    updateChart() {
      this.prepareChartData(this.selectedDate);
    }
  },
  mounted() {
    if (this.stats.data.length > 0) {
      // Find the closest match to the compareDate (ignoring time for simplicity)
      const defaultDate = this.stats.data.find(item => item.date.startsWith(this.compareDate.split(' ')[0]));

      if (defaultDate) {
        this.selectedDate = defaultDate.date;
      } else {
        this.selectedDate = this.stats.data[0].date; // Fallback to the first available date
      }

      this.prepareChartData(this.selectedDate);
    }
  }
};
</script>

<style scoped>
.card-body {
  padding: 0; /* Optional: Reduce padding to maximize space for the chart */
}
</style>
