<template>

  <div class="draftLineWrap" :class="(draftActive && draftActive.id === draft.id) ? 'active' : ''" >
    <div class="lineitem first l-checkbox">
      <div class="checkbox">
        <input type="checkbox"
               :id="'id_'+draft.id"
               :value="draft.id"
               v-if="draftList && draftList.selected"
               v-model="draftList.selected[draft.id]"/>
        <label :for="'id_'+draft.id"></label>
      </div>
    </div>


    <div class="lineitem l-title" @click="setConfig(draft)">
      {{ draft.title }}
    </div>

    <div class="lineitem l-default">
      {{ draft.iban }}
    </div>

    <div class="lineitem l-default l-kontostand">
      {{ $filters.priceEuro(draft.money) }}
    </div>

    <div class="lineitem l-start_month">
      {{ $filters.formatDate(draft.start_month) }}
    </div>

    <div class="lineitem l-default">
      {{ draft.konto }}
    </div>
    <div class="lineitem l-state ml-auto">
      <template v-if="draft.default==='1' ">
        <i class="fa-duotone fa-check-circle"></i>
      </template>
    </div>

    <div class="lineitem last">
      <template v-if="draftActive && draftActive.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigBankAccounts')"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["draftActive", "getEmployees", "draftList"])
  },
  methods: {
    setConfig(draft) {
      const newData = {data: draft, type:'ConfigBankAccounts'};
      this.$store.dispatch("setActiveConfig",  newData).then(() => {
        this.$router.push({name: 'ConfigBankAccountsDetails', params: {id: draft.id}})
      });
    },
  }
}
</script>
