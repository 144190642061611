<template>
  <div class="card-body draft-list">
      <p class="text-sm text-secondary" v-if="!getConfigs || getConfigs.issue_prios.length === 0">Es wurden keine Vorlagen
        gefunden.</p>
    <Sort @sort="resortOrder($event)" />
      <div  class="draft-item" v-for="draft in getConfigs.issue_prios"
            :key="draft.id">
        <Headline :draft="draft"/>
      </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import  Headline from "@/routing/core/Config/Workflow/IssuePrio/Layouts/Headline.vue";
import Sort from "@/routing/core/Config/Workflow/IssuePrio/Layouts/Sort.vue";
import $ from "jquery";


export default {
  name: "DraftSearch",
  computed: {
    ...mapGetters(["getEmployees", "draftList", "getConfigs", "draftActive"])
  },

  components: {
    Sort,
    Headline,
  },
  methods: {

    getIssuePrio(){
      this.$store.dispatch("issueprioFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.issuePrio.ordering.value = payload.type;
        this.$store.state.filters.issuePrio.direction.value = payload.direction;
      } else {
        this.$store.state.filters.issuePrio.ordering.value = payload.type
      }
      this.getIssuePrio();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },

  created() {
  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }

};
</script>
