<template>
  <div class="row" v-if="stats && stats.data">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <label for="dateSelect">Datum wählen: </label>
            <select id="dateSelect" class="form-select" v-model="selectedDate" @change="updateChart">
              <option value="">- Bitte Datum wählen -</option>
              <option v-for="item in stats.data" :key="item.date" :value="item.date">
                {{ $filters.formatDate(item.date) }}
              </option>
            </select>
          </div>
          <BarChart :chartData="barChartStats" :options="barChartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BarChart} from "vue-chart-3";

export default {
  name: "DFBAge",
  props: {
    stats: {
      type: Object,
      default: () => ({data: []})
    },
    compareDate: {
      type: String,
      default: ''
    }
  },
  components: {
    BarChart
  },
  data() {
    return {
      selectedDate: '', // This will store the selected date
      barChartStats: {
        labels: [],
        datasets: [
          {
            label: 'Accepted',
            data: [],
            backgroundColor: '#188360',
          },
          {
            label: 'Denied',
            data: [],
            backgroundColor: 'rgb(248,26,44)',
          }
        ]
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: false, // Ensure bars are next to each other, not stacked
          },
          y: {
            beginAtZero: true,
          }
        }
      }
    };
  },
  methods: {
    prepareChartData(date) {
      const labels = [];
      const acceptedData = [];
      const deniedData = [];
      // Find the selected date's data
      const selectedData = this.stats.data.find(item => item.date === date);
      if (selectedData) {
        for (const key in selectedData.data) {
          if (selectedData.data.hasOwnProperty(key)) {
            const group = selectedData.data[key];
            labels.push(group.name);
            acceptedData.push(group.countAccepted);
            deniedData.push(group.countDenied);
          }
        }
        this.barChartStats.labels = labels;
        this.barChartStats.datasets[0].data = acceptedData;
        this.barChartStats.datasets[1].data = deniedData;
      }
    },
    updateChart() {
      this.prepareChartData(this.selectedDate);
    }
  },
  mounted() {
    if (this.stats.data.length > 0) {
      // Try to preselect the compareDate if it exists
      const defaultDate = this.stats.data.find(item => item.date.startsWith(this.compareDate.split(' ')[0]));
      if (defaultDate) {
        this.selectedDate = defaultDate.date;
      } else {
        this.selectedDate = this.stats.data[0].date; // Fallback to the first available date
      }
      this.prepareChartData(this.selectedDate);
    }
  }
};
</script>
