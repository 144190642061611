<template>
  <div class="paginationWrapper mb-3">
    <ul class="pagination d-flex justify-content-center mt-3">
      <li class="page-item" :class="{disabled: ($store.getters[listName].offset === 0)}">
        <template v-if="$store.getters[listName].offset === 0">
          <button class="page-link disabled" href="#" aria-label="Previous">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
          </button>
        </template>
        <template v-else>
          <button @click="$store.dispatch('setPage', {type:'previous', list:list, listName:listName, filterName:filterName})" class="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
          </button>
        </template>
      </li>
      <template v-for="pageNum in totalPages" :key="pageNum">
        <!-- Show the first page -->
        <li v-if="pageNum === 1 && totalPages > 1" class="page-item" :class="{ active: currentPage === 1 }">
          <button @click="dispatchSetPage(1)" class="page-link">1</button>
        </li>

        <!-- Show ... after the first page if currentPage is greater than 4 (and there are more than 7 total pages) -->
        <li v-if="currentPage > 4 && totalPages > 7 && (pageNum === 1 )" class="page-item">
          <span class="page-link">...</span>
        </li>

        <!-- Conditionally render page numbers: Show 3 before & after the current, but not the first or last page -->
        <li v-if="pageNum !== 1 && pageNum !== totalPages && pageNum >= currentPage - 3 && pageNum <= currentPage + 3" class="page-item" :class="{ active: currentPage === pageNum }">
          <button @click="dispatchSetPage(pageNum)" class="page-link">{{ pageNum }}</button>
        </li>

        <!-- Show ... before the last page if currentPage is less than totalPages - 3 (and there are more than 7 total pages) -->
        <li v-if="currentPage < totalPages - 3 && totalPages > 7 && pageNum === totalPages - 1" class="page-item">
          <span class="page-link">...</span>
        </li>

        <!-- Show the last page -->
        <li v-if="pageNum === totalPages  && totalPages > 1" class="page-item" :class="{ active: currentPage === totalPages }">
          <button @click="dispatchSetPage(totalPages)" class="page-link">{{ totalPages }}</button>
        </li>
      </template>

      <li class="page-item"
          :class="{disabled: (($store.getters[listName].offset + $store.getters[listName].limit) >= $store.getters[listName].complete || $store.getters[listName].complete < $store.getters[listName].limit)}">
        <template v-if="(($store.getters[listName].offset + $store.getters[listName].limit) >= $store.getters[listName].complete || $store.getters[listName].complete < $store.getters[listName].limit)">
          <button class="page-link" aria-label="Next">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
          </button>
        </template>
        <template v-else>
          <button class="page-link" @click="$store.dispatch('setPage', {type:'next', list:list, listName:listName, filterName:filterName})" aria-label="Next">
            <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
          </button>
        </template>
      </li>
    </ul>
    <div class="overviewCounter float-end mb-3">
      <span v-if="$store.getters[listName].complete > 0">
  Einträge: {{ $store.getters[listName].offset + 1 }} -
  {{ Math.min($store.getters[listName].offset + $store.getters[listName].limit, $store.getters[listName].complete) }}
  von {{ $store.getters[listName].complete }}
</span>
      <span v-else>Keine Einträge</span>
    </div>
    <div class="limitBox float-start mb-3">
      <multiselect v-model="$store.state.lists[list].limit"     :options="listLimits" v-if="$store.state.lists[list].limit"
                   :multiple="false"
                   :canClear="false"
                   :closeOnSelect="true"
                   :placeholder="'Anzahl wählen'"
                   openDirection="top"
                   @update:model-value="$store.state.lists[list].offset = 0; $store.dispatch(filterName);"
                   class="form-select small">

      </multiselect>
<!--      <select  class="form-select  "  v-model="$store.state.lists[list].limit" @change="">-->
<!--        <option v-for="limit in listLimits">{{limit}}</option>-->
<!--      </select>-->

    </div>
  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
  export default {
    name: "Pagination",

    // list = state.lists[list]
    // listName = getters[listName]
    // filterName = dispatch[filterName] -> Run search
    components: {
      Multiselect,
    },
    props: ["list", "listName", "filterName"],
    data() {
      return {
        listLimits: [5,10,15,20,50,100],
      }
    },
    methods: {
      // Dispatch setPage to update the page directly
      dispatchSetPage(pageNum) {
        const offset = (pageNum - 1) * this.$store.state.lists[this.list].limit;

        this.$store.dispatch('setPage', {
          type: 'direct',
          list: this.list,
          listName: this.listName,
          filterName: this.filterName,
          offset: offset
        });
      }
    },
    computed:{
      totalPages() {
        const totalItems = this.$store.getters[this.listName]?.complete || 0;
        const limit = this.$store.state.lists[this.list]?.limit || 1;
        return totalItems > 0 ? Math.ceil(totalItems / limit) : 0;
      },
      currentPage() {
        return Math.floor(this.$store.state.lists[this.list].offset / this.$store.state.lists[this.list].limit) + 1;
      }
    }
  }
</script>
