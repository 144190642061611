<template>
  <template v-if="$route && $route.matched && $route.matched.length">
    <template v-if="$route.matched[0].name === 'drafts'">
      <button class="btn btn-outline-secondary btn-sm  me-3" :tooltip="$t('archive.drafts')" position="left"
              v-if="hasSelectionD"
              @click="archiveSelected( 'drafts');"><i class="fa-duotone fa-boxes-packing"></i></button>
    </template>
  </template>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "archiveSelected",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["draftList"]),
    // hasSelection() {
    //   return this.documentList.selected && Object.keys(this.documentList.selected).some(id => this.documentList.selected[id]);
    // },
    // hasSelectionC(){
    //   return this.customerList.selected && Object.keys(this.customerList.selected).some(id => this.customerList.selected[id]);
    // },
    hasSelectionD() {
      return this.draftList.selected && Object.keys(this.draftList.selected).some(id => this.draftList.selected[id]);
    }
  },
  components: {},
  methods: {
    archiveSelected(list) {
      let VueInner = this;
      this.$confirm('Ausgewählte Einträge wirklich archivieren?', 'Einträge archivieren?', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        this.$store.commit('archiveSelected', list);
      }).catch((r) => {
        console.log(r);
      });
    }
  }
}
</script>
