<template>
  <div class="event-item headline">
  <div class="eventLineWrap newsletterLineWrap" >
    <div class="lineitem first l-checkbox">
      <span @click="emitSort('id')"  :class="(sortDirections.id) ? 'active' : ''">ID</span>
      <i v-if="sortDirections.id === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.id === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-title">
      <span @click="emitSort('title')"  :class="(sortDirections.title) ? 'active' : ''">Titel</span>
      <i v-if="sortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-state">
      <span @click="emitSort('state')" :class="(sortDirections.state) ? 'active' : ''" tooltip="Status" :position="'left'">Status</span>
      <i v-if="sortDirections.state === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.state === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('automatic')" :class="(sortDirections.automatic) ? 'active' : ''" tooltip="Automatik" :position="'left'">Automatik</span>
      <i v-if="sortDirections.automatic === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.automatic === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('public')" :class="(sortDirections.public) ? 'active' : ''" tooltip="Öffentlich" :position="'left'">Öffentlich</span>
      <i v-if="sortDirections.automatic === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.automatic === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('doubleOptIn')" :class="(sortDirections.doubleOptIn) ? 'active' : ''" tooltip="Opt-In" :position="'left'">Opt-In</span>
      <i v-if="sortDirections.automatic === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.automatic === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-iconlist">
      <span @click="emitSort('sumAccepted')" :class="(sortDirections.sumAccepted) ? 'active' : ''">Aktiv</span>
      <i v-if="sortDirections.sumquota === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.sumquota === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('sumRequest')" :class="(sortDirections.sumRequest) ? 'active' : ''" tooltip="Warteliste" :position="'left'">Opt-In</span>
      <i v-if="sortDirections.sumwaiting === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.sumwaiting === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('sumDenied')" :class="(sortDirections.sumDenied) ? 'active' : ''" tooltip="Storniert" :position="'left'">Storno</span>
      <i v-if="sortDirections.sumstorno === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.sumstorno === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>
    <div class="lineitem l-iconlist">
      <span @click="emitSort('sumAutomatic')" :class="(sortDirections.sumAutomatic) ? 'active' : ''">Auto</span>
      <i v-if="sortDirections.quota === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.quota === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>

    <div class="lineitem l-date">
      <span @click="emitSort('start')" :class="(sortDirections.crm_created_date) ? 'active' : ''">Erstellt am</span>
      <i v-if="sortDirections.start === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="sortDirections.start === 'desc'" class="fa-duotone fa-arrow-down"></i>
    </div>


    <div class="lineitem last  ">

    </div>
  </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      sortDirections:{
        title: '',
        doubleOptIn: '',
        sumquota: '',
        sumwaiting: '',
        sumstorno: '',
        tags: '',
        id: '',
        automatic:'',
        state:'',
        start:'',

      }

    }
  },
  props: {
    document: Object,
  },
  computed: {
    ...mapGetters(["newsletterActive", "getEmployees", "getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.sortDirections) {
        if (type !== clickedType) {
          this.sortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.sortDirections[clickedType] === '') {
        this.sortDirections[clickedType] = 'desc';
      } else if (this.sortDirections[clickedType] === 'desc') {
        this.sortDirections[clickedType] = 'asc';
      } else {
        this.sortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.sortDirections[clickedType] });
    }
  },

}
</script>
<style lang="scss" scoped>
.newsletterLineWrap .lineitem {
  width: 100px !important;
  text-align: center !important;
  display: inline !important;
  &.l-title,&.l-checkbox {
    text-align: left !important;
  }
  .fa-circle-check {
    color: green
  }
}
</style>
<style lang="scss">

span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}

</style>
