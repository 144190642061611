<template>
  <div class="card w-100">
    <div class="card-header">
      <h6 style="display: inline; margin-right: 24px;">Institutionen</h6>
      <div class="input-group-sm" style="display: inline-block;">
        <input type="text" class="form-control me-4" style="display: inline-block;width: 200px;" v-model="searchInst"
               placeholder="Suche..." @input="searchInstitutions()">
        <template v-if="showAddInst">
          <input type="text" class="form-control me-2" style="display: inline-block;width: 200px;"
                 v-model="addInstTitle" placeholder="Titel für neue Institution" @input="searchInstitutions()">
          <button type="button" class="btn btn-sm btn-primary" style="display: inline-block;" @click="addInstitution()">
            <i class="fa-duotone fa-check"></i></button>
        </template>
      </div>
      <button class="float-end btn btn-sm btn-outline-primary" tooltip="Institution hinzufügen"
              @click="showAddInst = !showAddInst;" position="left"><i class="fa-duotone fa-plus"></i></button>
      <button class="float-end btn btn-sm me-3" :class="(showDeleted) ? 'btn-primary' : 'btn-outline-primary'"
              @click="toggleDeleted()" style="z-index: 25;position: relative;" position="left"
              tooltip="Versteckte Einträge anzeigen"><i class="fa-duotone fa-eye"></i></button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <template v-for="inst in institutions" :key="inst.id">
            <div class="singleRequest"
                 v-if="(inst.state === '1' && !showDeleted) || (showDeleted && inst.state !== '1')">
              <div class="pluginWrap insitutions">
                <div class="plugin-item">
                  <div class="lineitem l-id">{{ inst.id }}</div>
                  <div class="lineitem l-state">
                  <span class="stateIcon">
                    <template v-if="inst.state ==='1'">
                      <i class="fa-duotone fa-check"></i>
                    </template>
                    <template v-else-if="inst.state ==='-2'">
                      <i class="fa-duotone fa-times"></i>
                    </template>
                    <template v-else>
                      <i class="fa-duotone fa-eye-slash"></i></template>
                     </span>
                  </div>
                  <div class="lineitem l-edit-title">
                    <div class="form-group">
                      <input type="text" v-model="inst.title" class="form-control inline-input "
                             @blur="saveInstitution(inst)">
                    </div>
                  </div>
                  <div class="lineitem l-action ml-auto">
                    <button @click="deleteInstitution(inst.id)" class="btn btn-outline-danger btn-xs float-end me-2"
                            tooltip="Löschen" :disabled="(inst.state === '-2')" position="left"><i
                      class="fa-duotone fa-trash"></i></button>
                    <button @click="hideInstitution(inst)" class="btn btn-outline-secondary btn-xs float-end me-2"
                            :disabled="(inst.state ==='0')" tooltip="Verbergen" position="left"><i
                      class="fa-duotone fa-eye-slash"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      activeInstitution: null,
      institutions: [],
      showDeleted: false,
      searchInst: "",
      showAddInst: false,
      addInstTitle: "",
    };
  },
  mounted() {
    this.getInstitutions();
  },
  methods: {
    toggleDeleted() {
      this.showDeleted = !this.showDeleted;
    },
    searchInstitutions() {
      if (this.searchInst.length > 0) {
        this.institutions = this.institutions.filter((inst) => {
          return inst.title.toLowerCase().includes(this.searchInst.toLowerCase());
        });
      } else {
        this.getInstitutions();
      }
    },
    addInstitution() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "plugins/dfb/institutions", {title: this.addInstTitle}, {headers}).then(() => {
        this.addInstTitle = "";
        this.showAddInst = false;
        this.getInstitutions();
      });
    },
    getInstitutions() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/institutions", {headers}).then((r) => {
        this.institutions = r.data.result.data;
      });
    },
    deleteInstitution(id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.$confirm('Ausgewählte Institution wirklich löschen?', 'Eintrag löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#0088cc',
        type: 'warning'
      }).then(() => {
        axios.delete(this.$store.state.apiUrl + "plugins/dfb/institutions/" + id, {headers}).then(() => {
          this.getInstitutions();
        });
      }).catch(() => {
        console.log('Abgebrochen');
      });
    },
    hideInstitution(inst) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let tempInst = inst;
      tempInst.state = "0";
      axios.put(this.$store.state.apiUrl + "plugins/dfb/institutions/" + inst.id, tempInst, {headers}).then(() => {
        this.getInstitutions();
      });
    },
    saveInstitution(inst) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "plugins/dfb/institutions/" + inst.id, inst, {headers}).then(() => {
        this.getInstitutions();
      });
    },
  },
};
</script>
