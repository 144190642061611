<template>
  <div class="appView drafts purpleView">
    <Details  v-if="$route.params.id"/>
    <List  />
  </div>

</template>
<script>

import List from "./List.vue";
import Details from "./Details.vue";
import {mapGetters} from "vuex";
export default {
  name: "IssueTracker",
  components: {
    Details,
    List
  },
computed:{
...mapGetters(["getConfigs"]),
},



};
</script>
<style lang="scss">
.product-item {
  cursor: pointer;
}
</style>
