<template>
  <div class="appView drafts purpleView">
    <Details  v-if="$route.params.id"/>
    <List  />
  </div>


</template>
<script>

import List from "./AllList.vue";
import Details from "./AllDetails.vue";
export default {
  name: "Projects",
  components: {
    Details,
    List
  },
  computed: {

  },
}
</script>
<style lang="scss">
.product-item {
  cursor: pointer;
}
</style>
