<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
        <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{ $t("langoverrides.detailstitle") }}</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeLangOverrides');$store.commit('closeModal', 'ConfigLangOverride')">
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
          class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
        </button>
        <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
          class="fa-duotone fa-trash"></i> {{ $t("globals.delete") }}
        </button>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <input v-if="!$route.params.id"
              class="form-control"
              type="text"
              v-model="searchPhrase"
              placeholder="Sprachoverride suchen ..."
            >
            <ul class="searchSelect" v-if="searchPhrase !== ''">
              <li v-for="(value, fullKey) in filteredLanguageVariables" :key="fullKey">
                <span @click="saveKeyPath(fullKey)">
                  {{ fullKey }}: {{ value }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>{{ $t("globals.name") }}</label>
              <input class="from-control w-100" type="text" readonly v-model="d.name">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Neuer Wert</label>
              <input class="form-control" type="text" v-model="d.override">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Basic/LangOverrides/Layouts/Headline.vue";
import $ from "jquery";
import de from '@/lang/translations/de.json';

export default {
  name: "LangOverridesDetails",
  emits: ["update"],
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "getActiveOverride", "getConfigs"]),
    filteredLanguageVariables() {
      if (this.searchPhrase.length < 2) {
        return {};
      }
      let result = {};
      const searchLower = this.searchPhrase.toLowerCase();
      const search = (obj, baseKey) => {
        Object.keys(obj).forEach(key => {
          const fullKey = baseKey ? `${baseKey}.${key}` : key;
          if (typeof obj[key] === 'object') {
            search(obj[key], fullKey);
          } else if (obj[key].toString().toLowerCase().includes(searchLower)) {
            result[fullKey] = obj[key];
          }
        });
      };
      search(this.deJson);
      return result;
    }
  },
  data() {
    return {
      d: {
        override: '',
        name: '',
        lang: 'de'
      },
      searchPhrase: "",
      isNew: true,
      deJson: de,
    };
  },
  methods: {
    saveKeyPath(key) {
      this.d.name = key;
      this.searchPhrase = '';
      // ... do something with the saved key path ...
    },
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "config/overrides/" + VueInner.d.id, VueInner.d, {headers}).then(() => {
          // dispatch toastSuccess
          VueInner.$store.dispatch('toastSuccess', 'Sprachoverride wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeLangOverrides');
          VueInner.$emit("update");
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "config/overrides", VueInner.d, {headers}).then(() => {
          axios.get(this.$store.getters.getApiUrl + "config/overrides", {headers}).then((r) => {
            this.$store.commit('setLanguageOverrides', r.data.result.data);
          }).catch((error) => {
            this.$store.dispatch("handleApiResponse", error.response);
          });
          VueInner.$store.commit('closeModal', 'ConfigLangOverride');
          VueInner.$store.dispatch('toastSuccess', 'Sprachoverride wurde erfolgreich neu angelegt.');
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll der Sprachoverride wirklich gelöscht werden?', 'Sprachoverride löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#0088cc',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "config/overrides/" + VueInner.d.id, {headers}).then(() => {
          VueInner.$store.dispatch('toastSuccess', 'Sprachoverride wurde erfolgreich gelöscht');
          VueInner.$store.commit('closeLangOverrides');
          VueInner.$emit("update");
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id) {
        // axios get
         this.d = this.getActiveOverride;
        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>

