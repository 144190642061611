<template>

  <vue-csv-import :text="customText"
    v-model="csv"
    :fields="fields"
  >
    <div class="csvImportDivs">
    <div class="row">
      <div class="col-12 headers mb-3"><vue-csv-toggle-headers></vue-csv-toggle-headers></div>
    </div>
    <div class="row">
      <div class="col-12 errors mb-3"><vue-csv-errors></vue-csv-errors></div>
    </div>
    <div class="row">
      <div class="col-12 input mb-3"><vue-csv-input></vue-csv-input></div>
    </div>
    <div class="row">
      <div class="col-12 map mb-3"><vue-csv-map></vue-csv-map></div>
    </div>
    </div>


  </vue-csv-import>
  <template v-if="csv && csv.length > 0">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>{{ $t("edit.customerCategory") }}</label>
          <multiselect v-model="c.c_catid" :label="'title'" :options="getConfigs.categories_customers"
                       :modelValue="'id'"
                       :valueProp="'id'"
                       :multiple="false"
                       :closeOnSelect="true"
                       :canClear="false"
                       :placeholder="'Kategorie wählen'"
                       :trackBy="'id'"
          ></multiselect>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>{{ $t("edit.customerStatus") }}</label>
          <multiselect v-model="c.c_state" :label="'name'" :options="getConfigs.customerState"
                       :modelValue="'id'"
                       :valueProp="'id'"
                       :multiple="false"
                       :canClear="false"
                       :closeOnSelect="true"
                       :placeholder="'Status wählen'"
                       :trackBy="'id'"
          ></multiselect>
        </div>
      </div>
    </div>
    <div class="row" v-if="c.c_state ==='5'">
      <div class="col-12 col-lg-6 offset-lg-3"><div class="alert alert-warning">
        <i class="fa-duotone fa-info-circle"></i> {{ $t("edit.customerDead") }}
      </div></div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>{{ $t("edit.accountManager") }}</label>
          <multiselect v-model="c.c_owner" :label="'e_name'" :options="getEmployees"
                       :modelValue="'id'"
                       :valueProp="'id'"
                       :multiple="false"
                       :canClear="false"
                       :closeOnSelect="true"
                       :placeholder="'Betreuer wählen'"
                       :trackBy="'id'"
          ></multiselect>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>{{ $t("edit.customerPriority") }}</label>
          <multiselect v-model="c.c_prio" :label="'name'" :options="getConfigs.customerPrio"
                       :modelValue="'id'"
                       :valueProp="'id'"
                       :multiple="false"
                       :canClear="false"
                       :closeOnSelect="true"
                       :placeholder="'Priorität wählen'"
                       :trackBy="'id'"
          ></multiselect>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>{{ $t("edit.acquisitionStatus") }}</label>
          <multiselect v-model="c.c_akquise" :label="'name'" :options="getConfigs.customerAcquisition"
                       :modelValue="'id'"
                       :valueProp="'id'"
                       :multiple="false"
                       :canClear="false"
                       :closeOnSelect="true"
                       :placeholder="'Akquise-Stand wählen'"
                       :trackBy="'id'"
          ></multiselect>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>{{ $t("edit.customerType") }}</label>
          <multiselect v-model="c.c_type" :label="'name'" :options="getConfigs.customerTypes"
                       :modelValue="'id'"
                       :valueProp="'id'"
                       :multiple="false"
                       :canClear="false"
                       :closeOnSelect="true"
                       :placeholder="'Kunden-Typ wählen'"
                       :trackBy="'id'"
          ></multiselect>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>{{ $t("edit.billingLanguage") }}</label>
          <multiselect v-model="c.invoice_lang" :label="'name'" :options="invoiceLangOptions"
                       :modelValue="'id'"
                       :valueProp="'id'"
                       :multiple="false"
                       :canClear="false"
                       :closeOnSelect="true"
                       :placeholder="'Rechnungssprache wählen'"
                       :trackBy="'id'"
          ></multiselect>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>{{ $t("globals.tags") }}</label>
          <multiselect v-model="c.tags" :id="'tags'"
                       :modelValue="'id'"
                       :valueProp="'id'"
                       :options="getConfigs.customerTags"
                       :multiple="true"
                       :label="'name'"
                       :closeOnSelect="false"
                       :allow-empty="true"
                       :create-option="true"
                       :clearOnSelect="false"
                       :searchable="true"
                       :taggable="true"
                       @tag="addTag"
                       :placeholder="$t('globals.chooseTags')"
                       :tag-placeholder="$t('globals.insertNewTag')"
                       :trackBy="'id'"
                       mode="tags"
          ></multiselect>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>Qualifizierter Lead seit</label>
          <VueDatePicker v-model="c.qualified_akquise" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                         :auto-apply="true" :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                         cancel-text="X" :select-text="this.$t('glboals.choose')" :teleport="true">
          </VueDatePicker>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label>{{ $t("edit.customerLead") }}</label>
          <multiselect v-model="c.c_lead" :label="'name'" :options="getConfigs.customerLead"
                       :modelValue="'id'"
                       :valueProp="'id'"
                       :multiple="false"
                       :canClear="false"
                       :closeOnSelect="true"
                       :placeholder="'Lead-Quelle wählen'"
                       :trackBy="'id'"
          ></multiselect>
        </div>
      </div>
    </div>
    <button class="btn btn-outline-primary" @click="importData()"><i class="fa-duotone fa-file-import"></i> Daten importieren</button>
  </template>
</template>
<script>

import axios from "axios";
import VueDatePicker from '@vuepic/vue-datepicker';
import multiselect from '@vueform/multiselect'
import {mapGetters} from "vuex";
export default {
  name: 'UploadComponentCSV',
  components: {VueDatePicker, multiselect},
  data() {
    return {
      csv: null,
      customText: {
        toggleHeaders: 'Datei hat Überschriften', // Your custom text in German
      },
      invoiceLangOptions: [
        {id: 'de', name: this.$t('globals.german')},
        {id: 'en', name: this.$t('globals.english')},
      ],
      c:{
        tags:[]
      }
    }
  },
  props: {
    listName: 'customers',
    fields: {},
  },
  computed: {
    ...mapGetters(["getConfigs", "getEmployees", "modalsList"]),

  },
  methods: {
    importData() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const VueInner = this;
      axios.post(this.$store.state.apiUrl + "batch/" + this.listName + "/import", {items: this.csv, defaults: this.c}, {headers}).then((e) => {
        VueInner.$store.commit('closeModal', 'import');
        VueInner.$store.dispatch('toastSuccess', 'Daten wurden erfolgreich importiert');
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
      });
    },
    addTag(newTag) {
      // random id
      this.c.tags.push({name: newTag, code: Math.random().toString(36).substr(2, 9)})
    },
  },
  created() {
    if(this.c){
      this.localCountry = this.getConfigs.countries.find((country) => country.default === '1');
      // Prefill selects
      this.c.c_catid = this.getConfigs.categories_customers[0].id;
      this.c.c_state = this.getConfigs.customerState[0].id;
      this.c.c_owner = this.getEmployees[0].id;
      this.c.c_prio = this.getConfigs.customerPrio[0].id;
      this.c.c_akquise = this.getConfigs.customerAcquisition[0].id;
      this.c.c_type = this.getConfigs.customerTypes[0].id;
      this.c.invoice_lang = 'de';
      this.c.c_tax_rule = this.getConfigs.customerTax[0].id;
      this.c.invoice_type = "1";
    }
  }
}
</script>
