<template>
  <div class="appView customers reports"><div class="card">   <div class="card-header"><h6>Berichte</h6></div>
    <div class="card-body customer-list">
      <div class="table-responsive">
      <table class="table table-striped table-sm">
        <thead>
        <tr v-if="customerList && customerList.data && customerList.data.headlines">
          <th >&nbsp;</th>
          <th v-for="(head) in  customerList.data.headlines"  :class="'reportTableKey'+head" >{{ $t('exportField.'+head )}}</th>

        </tr>
        </thead>
        <template v-if="customerList && customerList.data && customerList.data.items">
          <tbody>
          <tr v-for="(customer) in customerList.data.items">
            <td>
              <router-link v-if="customer.id" :to="{name: 'CustomersDetails', params: {id: customer.id}}" class="btn btn-outline-primary btn-xs" target="_blank"  position="right" tooltip="Kunden in neuem Fenster öffnen"><i class="fa-external-link fa-duotone " style="font-size: 12px;"></i></router-link>
            </td>
            <td v-for="(c,key) in customer"  v-html="c" :class="'reportTableKey'+key"></td>

          </tr>
          </tbody>
        </template>
      </table></div>
      <pagination list="customers" listName="customerList" filterName="customersFilter"  />
    </div></div>
  </div>
</template>
<script>
import axios from 'axios';
import {mapGetters} from "vuex";
import Pagination from "@/filter/components/pagination.vue";

export default {
  name: "ReportsInCustomer",
  computed: {
    ...mapGetters(["getEmployees", "customerList", "customerActive", "getUser", "getConfigs"])
  },
  components: {Pagination},
  created: function () {
    this.$store.dispatch("customersFilter");



  },
  beforeUnmount() {
    // reset $store.state.filters.customer.report
    this.$store.state.filters.customers.report ={
      name: 'report',
      type: "list",
      labelType: 'name',
      value: "",
      structure: [],
      source: "customerReport",
    }
  },
};
</script>
<style>
.reportTableKeyid{
  display: none;
}

</style>
