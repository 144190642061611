<template>
  <div class="draftLineWrap" :class="(categoryActive && categoryActive.id === category.id) ? 'active' : ''" >

    <div class="lineitem l-title" @click="setCategory(category)">
      {{ category.renderTitle }}
    </div>

    <div class="lineitem last ml-auto">
      <template v-if="categoryActive && categoryActive.id === category.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeCategory');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setCategory(category)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    category: Object,
  },
  computed: {
    ...mapGetters(["categoryActive", "getEmployees"])
  },
  methods: {
    setCategory(category) {
      this.$store.dispatch("setActiveCategory", category);
      this.$router.push({name: 'CategoriesDetails', params: {id: category.id}})

    },
  }
}
</script>
