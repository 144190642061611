
<template>
  <div class="document-item notActive">
    <div class="documentLineWrap footerInfo" style="display: block;">
      <div class="lineitems item-row" style="display: flex;">
        <div class="lineitem first l-checkbox">&nbsp;</div>
        <div class="lineitem l-number">&nbsp;</div>
        <div class="lineitem l-title">&nbsp;</div>
        <div class="lineitem l-company">&nbsp;</div>
        <div class="lineitem l-date">&nbsp;</div>
        <div class="lineitem l-netto footerInfo">
          <strong>{{$filters.priceEuro(allDocumentsSum)}}</strong>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterInfo",
  props: {
    allDocumentsSum: {
      type: String,
      required: true
    }
  }
}

</script>
