<template>
<div class="loadFullWrap" :class="type" v-if="loading" :style="'height:'+height">
  <i class="fa-duotone fa-spinner-third fa-spin"></i>
</div>
</template>
<script >
export default {

  name: 'Loading',
  props: ['loading', 'percent', 'type', 'height'],

}


</script>

