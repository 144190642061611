<template>
  <template v-if="$route && $route.matched && $route.matched.length">
    <template v-if="$route.matched[0].name === 'Dashboard'">
      <button class="btn btn-outline-primary btn-sm me-3" :tooltip="$t('add.widget')" position="left"
              @click="$store.commit('openModal', 'widget');"><i class="fa-duotone fa-toolbox"></i></button>
      <Modal v-if="modalsList.widget">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="productItem activeProduct">
              <div class="card">
                <div class="card-header ">
                  <button type="button" class="btn btn-close float-end" style="position: relative;
z-index: 1;"
                          @click="$store.commit('closeModal', 'widget');">
                  </button>
                  <h6 class="inline"><i class="fa-duotone fa-toolbox"></i> {{ $t("add.widget") }}


                  </h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-8 col-12">
                      <div class="form-group">
                        <select class="form-select small" v-model="widget">
                          <option value="">{{ $t("select.widget") }}</option>
                          <option v-for="availableWidget in widgets" :value="availableWidget.type"
                                  :key="availableWidget.type">
                            {{ $t('widget.' + availableWidget.type) }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-4 col-12">
                      <button @click="addWidget" class="btn btn-outline-primary"><i class="fa-duotone fa-plus"></i>
                        weiteres Widget hinzufügen
                      </button>
                    </div>
                  </div>
                  <p>&nbsp;</p>
                  <div class="row mb-2" v-for="(addedWidget, index) in userWidgets" :key="index">
                    <template v-if="addedWidget">
                      <div class="col-lg-4 col">
                        <p>{{ $t('widget.' + addedWidget.type) }}</p>
                      </div>
                      <div class="col-lg-4 col">
                        <select v-model="addedWidget.grid">
                          <option value="12">Ganze Breite</option>
                          <option value="6">Halbe Breite</option>
                          <option value="4">Drittel Breite</option>
                          <option value="3">Viertel Breite</option>
                        </select>
                      </div>
                      <div class="col-lg-4 col">
                        <button class="btn btn-danger btn-sm" @click="removeWidget(index)">
                          <i class="fa-duotone fa-trash"></i> Löschen
                        </button>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Modal>
    </template>
  </template>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "widget",
  data() {
    return {
      widgets: [],
      initialWidgets: [{type: 'widgetCustomer', grid: '12'},
        {
          type: 'widgetContacts',
          grid: '12'
        },
        {
          type: 'widgetConversion',
          grid: '12'
        },
        {
          type: 'widgetLeads',
          grid: '12'
        },
        {
          type: 'widgetCountry',
          grid: '12'
        },
        {
          type: 'widgetDocuments',
          grid: '12'
        },

        {
          type: 'widgetAcquisition',
          grid: '12'
        }, {
          type: 'widgetTasksWeekly',
          grid: '12'
        },{
          type: 'widgetContactRequests',
          grid: '12'
        },
        {
          type: 'widgetProjectTurnover',
          grid: '12'
        },
        {
          type: 'widgetSales',
          grid: '12'
        },
      ],
      widget: '',
      userWidgets: [], // This will hold the added widgets
    };
  },
  components: {},
  computed: {
    ...mapGetters(['modalsList', 'getUser', 'getPlugins', 'getMenu']),
  },
  methods: {
    addWidget() {
      if (this.widget && this.widget !== '') {
        const selectedWidget = this.widgets.find(w => w.type === this.widget);
        if (selectedWidget) {
          this.userWidgets.push({
            type: selectedWidget.type,
            grid: selectedWidget.grid
          });
          // Remove the selected widget from the pool
          this.widgets = this.widgets.filter(w => w.type !== this.widget);
        }
        this.saveWidgets();
      } else {
        alert('Bitte wählen Sie ein Widget aus.');
      }
      this.widget = ''; // Reset the selected widget
    },
    removeWidget(index) {
      // Retrieve the widget to be removed
      const removedWidgetType = this.userWidgets[index].type;
      // Remove the widget from the user's list
      this.userWidgets.splice(index, 1);
      // Find the full widget data from allWidgets
      const originalWidget = this.initialWidgets.find(w => w.type === removedWidgetType);
      // If found, add it back to the available widgets
      if (originalWidget) {
        // Ensure we use a reactive method to update the widgets array
        this.widgets = [...this.widgets, originalWidget];
      }
      this.saveWidgets();
    },
    saveWidgets() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const userId = this.getUser._isEmployee;
      if (!userId) {
        return;
      }
      axios.put(this.$store.state.apiUrl + "employee/" + userId + "/dashboardWidgets", {widgets: this.userWidgets}, {headers}).then((r) => {
          this.$store.state.user.dashboardWidgets = this.userWidgets;
          this.getUserWidgets();
        // toast success
        this.$store.dispatch('toastSuccess', 'Ihre Widgets wurden erfolgreich gespeichert.');
      });
    },
    getUserWidgets() {

      if (this.getUser) {
        this.userWidgets = this.getUser.dashboardWidgets || [];
        this.widgets = this.initialWidgets.filter(iw =>
          !this.userWidgets.some(uw => uw.type === iw.type)
        );
        this.checkAllowedWidgets();
      }
    },
    setUserWidgets(dashboardWidgets) {
      this.userWidgets = dashboardWidgets || [];
      this.setAvailableWidgets();
    },
    setAvailableWidgets() {
      // Assuming `allWidgets` is an array containing all widget types.
      this.widgets = this.initialWidgets.filter(aw =>
        !this.userWidgets.some(uw => uw.type === aw.type)
      );
    },
    checkAllowedWidgets(){
      // remove widgetSales widget if plugin holzkraft is not installed
      let pluginInstalled = false;
      if(this.getPlugins && this.getPlugins.infos && this.getPlugins.infos.holzkraft){
        pluginInstalled = true;
      }
      if(!pluginInstalled){
        this.initialWidgets = this.initialWidgets.filter(w => w.type !== 'widgetSales');
        this.initialWidgets = this.initialWidgets.filter(w => w.type !== 'widgetProjectTurnover');
      }
    }
  },
  created() {
    this.getUserWidgets();
    if(this.getMenu && this.getMenu.accounting){
      this.widgets = [...this.initialWidgets, {type: 'widgetTurnover', grid: '12'}];

    } else {
      this.widgets = [...this.initialWidgets];

    }
  },
  watch: {
    'getUser.dashboardWidgets': {
      immediate: true,
      handler(dashboardWidgets) {
        this.setUserWidgets(dashboardWidgets);
      },
    },
  }
}
</script>
