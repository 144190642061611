<template>
  <Details v-if="legalActive && legalActive.id"/>
  <Sort @sort="resortOrder($event)"/>
  <div class="singleRequest" v-for="(legal,key) in legalList.data" :key="legal.id">
    <div class="plugin-item">
      <Headline :legal="legal"/>
    </div>
  </div>
  <ul class="pagination d-flex justify-content-center mt-3">
    <li class="page-item" :class="{disabled: (this.legalList.offset === 0)}">
      <template v-if="this.legalList.offset === 0">
        <button class="page-link disabled" href="#" aria-label="Previous">
          <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
        </button>
      </template>
      <template v-else>
        <button @click="switchPage('previous')" class="page-link" href="#" aria-label="Previous">
          <span aria-hidden="true"><i class="fa-duotone fa-chevrons-left"></i></span>
        </button>
      </template>
    </li>
    <li class="page-item"
        :class="{disabled: (this.legalList.offset >= this.legalList.complete|| this.legalList.complete < 20)}">
      <template v-if="(this.legalList.offset >= this.legalList.complete || this.legalList.complete < 20)">
        <button class="page-link" aria-label="Next">
          <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
        </button>
      </template>
      <template v-else>
        <button class="page-link" @click="switchPage('next')" aria-label="Next">
          <span aria-hidden="true"><i class="fa-duotone fa-chevrons-right"></i></span>
        </button>
      </template>
    </li>
  </ul>
</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import Details from "@/routing/core/Legals/Details.vue";
import Headline from "@/routing/core/Legals/Layouts/Headline.vue";
import Sort from "@/routing/core/Legals/Layouts/Sort.vue";

export default {
  name: 'LegalOverview',
  components: {Sort, Details, Headline},
  computed: {
    ...mapGetters(["getEmployees", "legalList", "legalActive"]),
  },
  methods: {
    switchPage(type) {
      if (type === 'next' && this.legalList.offset <= this.legalList.complete) {
        this.$store.state.lists.legals.offset = this.legalList.offset + 20;
        this.getLegals()
      }
      if (type === 'previous' && this.legalList.offset >= 20) {
        this.$store.state.lists.legals.offset = this.legalList.offset - 20;
        this.getLegals()
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    getLegals() {
      this.$store.dispatch("legalsFilter");
    },
    resortOrder(payload) {
      if (payload.direction) {
        this.$store.state.filters.legals.ordering.value = payload.type;
        this.$store.state.filters.legals.direction.value = payload.direction;
      } else {
        this.$store.state.filters.legals.ordering.value = payload.type
      }
      this.getLegals();
    },
  },
  data() {
    return {
      legals: [],
      activeLegal: {},
    };
  },
  created() {
    this.l = this.legals;
    this.getLegals();
  },
  watch: {
    legalActive: function (newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.getLegals();
      }
    }
  }}
</script>
