<template>
  <div class="card">
    <div class="card-header">
      <button type="button" class="btn-close float-end" aria-label="Close" @click="closeTask"></button>
      <template v-if="taskItem && taskItem.breadcrumbs && taskItem.breadcrumbs.length > 0">
        <div class="breadcrumbsWrap">
          <template v-for="(breadcrumb,key) in taskItem.breadcrumbs">
    <span class="breadrumb-item" :class="'level_'+key"
          @click="$router.push({name: 'TasksTabs', params: {id: breadcrumb.id, tab: 'details'}})">
      <template v-if="key > 0">
        <i class="fa-duotone fa-chevron-right ms-1 me-1"></i>
      </template>
      {{ breadcrumb.title }}</span>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="breadcrumbsWrap">
          <span class="breadrumb-item" @click="changeTab('details')">{{ taskItem.title }}</span>
        </div>
      </template>
    </div>
    <ul class="tabNav">
      <li v-for="cat in taskTabs" :key="cat.name"
          @click="changeTab(cat.name)"
          :class="(activeSubtab === cat.name) ? 'active' : ''">
        <template v-if="cat.name === 'comments' && taskItem._countComments"><span
          class="incNumber">{{ taskItem._countComments }}</span></template>
        <template v-if="cat.name === 'attachments' && taskItem._countAttachments "><span
          class="incNumber">{{ taskItem._countAttachments }}</span></template>
        <template v-if="cat.name === 'subtasks' && taskItem._countIssues"><span
          class="incNumber">{{ taskItem._countIssues }}</span></template>
        <template v-if="cat.name === 'timesheet' && taskItem.times && taskItem.times.sumTask"><span class="incNumber"
                                                                                                    style="width: auto;">{{
            taskItem.times.sumTask.toFixed(0)
          }}</span></template>
        <i class="fa-duotone" :class="cat.icon"></i>
        {{ cat.label }}
      </li>
    </ul>
    <div class="card-header card-header-inline" v-if="activeSubtab === 'details'">
      <div class="row">
        <div class="col-lg-8 col-12">
          <template
            v-if="getConfigs && getConfigs.params_values && taskItem && (taskItem.state_id === getConfigs.params_values.issue_default_close)">
            <button class="checkCircleBtn btn-primary btn btn-sm" @click="uncheckTask()"><i
              class="fa-duotone fa-check fa-xl"></i></button>
          </template>
          <template v-else>
            <button class="checkCircleBtn btn-outline-primary btn btn-sm" @click="checkTask(taskItem.id)"><i
              class="fa-duotone fa-check fa-xl"></i></button>
          </template>
          <h6 class="editLine" contenteditable @keypress.enter.prevent="editItem('title')"
              @blur="editItem('title')">{{ taskItem.title }}</h6></div>
        <div class="col-lg-4 col-12 text-end">
          <div class="btn-group float-end">
            <button class="backToParent btn-outline-primary btn btn-sm "
                    @click="$router.push({ name: 'TasksTabs', params: { id: taskItem.parent_issue, tab: 'subtasks' } })"
                    v-if="taskItem && taskItem.parent_issue"><span tooltip="Zum Haupttask"><i
              class="fa-duotone fa-share-from-square fa-flip-horizontal"></i> </span></button>
            <button v-if="taskItem && taskItem.parent_issue" type="button" class="btn btn-outline-primary btn-sm"
                    @click="changeToMaintask()"><span tooltip="Zu Haupttask umwandeln"><i
              class="fa-duotone fa-star"></i></span></button>
            <button type="button" class="btn btn-outline-secondary btn-sm" @click="deleteTask(taskItem.id)"
                    :tooltip="$t('tasks.deleteTask')"><i
              class="fa-duotone fa-trash"></i>
            </button>
            <button type="button" class="btn btn-outline-primary btn-sm " @click="copyTask"
                    :tooltip="$t('tasks.copyTask')"><i class="fa-duotone fa-copy"></i></button>
            <button type="button" class="btn btn-outline-primary btn-sm" @click="updateTask(); $store.dispatch('toastSuccess', 'Das Ticket wurde erfolgreich gespeichert.');;showEditorDescription=false;"><i
              class="fa-duotone fa-floppy-disk"></i> Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="activeSubtab === 'details'">
      <div class="taskDetails">
        <div class="infoBlock text-xs mb-2"><i
          class="fa-duotone fa-calendar-circle-plus"></i> {{ $t("tasks.details.created") }}
          {{ $filters.formatDate(taskItem.crm_created_date) }} |
          <i
            class="fa-duotone fa-calendar-circle-user"></i> {{ $t("tasks.details.processed") }}
          {{ $filters.formatDateTime(taskItem.crm_modified_date) }}
        </div>
        <div class="row mb-2">
          <div class="col-lg-9 col-xl-10 col-12">
            <div class="topInfos">
              <div class="row">
                <div class="col">
                  <label class="color">{{ $t("globals.customer") }}</label>
                  <button v-if="taskItem.customer && taskItem.customer.id" tooltip="Kunden- & Projektzuweisung aufheben"
                          class="btn btn-outline-primary btn-xs clearCustomer ms-2 float-end" @click="clearCustomer"><i
                    class="fa-duotone fa-xmark"></i></button>
                  <template v-if="taskItem.customer && taskItem.customer.id">
                    <template v-if="taskItem.customer.c_company">
                      {{ taskItem.customer.c_company }}
                      <br><span class="text-xs customerInfo"
                                v-if="taskItem.customer.c_title || taskItem.customer.c_firstname || taskItem.customer.c_lastname">
           <template v-if="taskItem.customer.c_title">{{ taskItem.customer.c_title }}&nbsp;</template>
          <template v-if="taskItem.customer.c_firstname">{{ taskItem.customer.c_firstname }}&nbsp;</template>
          <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
              </span>
                    </template>
                    <template v-else>
                      <template v-if="taskItem.customer.c_title">{{ taskItem.customer.c_title }}&nbsp;</template>
                      <template v-if="taskItem.customer.c_firstname">{{
                          taskItem.customer.c_firstname
                        }}&nbsp;
                      </template>
                      <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
                    </template>
                  </template>
                  <template v-else>
                    <customersearch v-model="taskItem.customer" @update:model-value='updateTask' class="small"/>
                  </template>
                </div>
                <div class="col">
                  <label class="color">{{ $t("globals.project") }}</label>
                  <template v-if="editProject">
                    <div style="position: relative;">
                      <button class="btn-close" @click="editProject = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                      <VueMultiselect class="small" :options="projects" v-model="taskItem.project_id" :multiple="false"
                                      :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                      ref="multiselect_project" noOptions="Keine Projekte bisher angelegt"
                                      :valueProp="'id'"
                                      :placeholder="this.$t('projects.chooseProject')" label="title" :track-by="'id'"
                                      :select-label="this.$t('projects.chooseProject')" :taggable="false"
                                      @update:model-value="correctId('project_id');"
                      ></VueMultiselect>
                    </div>
                  </template>
                  <template v-else>
                    <span v-if="taskItem.project" style="cursor: pointer;"
                          @click="editProject = true;$nextTick(() => $refs.multiselect_project.activate())"> {{
                        taskItem.project.title
                      }}</span>
                    <button v-else class="btn btn-sm btn-outline-primary"
                            @click="editProject = true;$nextTick(() => $refs.multiselect_project.activate())"><i
                      class="fa-duotone fa-plus"></i> {{ $t("tasks.details.add") }}
                    </button>
                  </template>
                </div>
                <div class="col">
                  <label class="color">{{ $t("globals.status") }}</label>
                  <template v-if="editState">
                    <div style="position: relative;">
                      <button class="btn-close" @click="editState = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                      <VueMultiselect class="small open" :options="Object.values(getConfigs.issue_states)"
                                      v-model="taskItem.state_id"
                                      :multiple="false" :valueProp="'id'" ref="multiselect_state"
                                      :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                      :isOpen="true" track-by="title" openDirection="bottom"
                                      :placeholder="this.$t('tasks.details.chooseStatus')" label="title"
                                      :allow-empty="false"
                                      :select-label="this.$t('tasks.details.chooseStatus')"
                                      @update:model-value="correctId('state_id')"
                      ></VueMultiselect>
                    </div>
                  </template>
                  <template v-else>
                  <span
                    @click="editState = true;$nextTick(() => $refs.multiselect_state.activate())"
                    style="cursor: pointer;"><i class="fa-duotone fa-edit"></i> {{
                      getTitleById(taskItem.state_id, "issue_states")
                    }}</span>
                  </template>
                  <template v-if="isRejectable(taskItem.state_id)">
                    <p>In <input type="number" @blur="updateTask" v-model="taskItem.repeatDays"
                                 class="form-control form-control-sm form-control-plaintext small"/> Tagen wiederholen
                    </p>
                  </template>
                </div>
                <div class="col">
                  <label class="color">{{ $t("globals.priority") }}</label>
                  <template v-if="editPrio">
                    <div style="position: relative;">
                      <button class="btn-close" @click="editPrio = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                      <VueMultiselect class="small open" :options="Object.values(getConfigs.issue_prios)"
                                      v-model="taskItem.priority_id"
                                      :multiple="false" :valueProp="'id'" ref="multiselect_prio"
                                      :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                      :isOpen="true" track-by="title" openDirection="bottom"
                                      :placeholder="this.$t('projects.choosePriority')" label="title"
                                      :allow-empty="false"
                                      :select-label="this.$t('projects.choosePriority')"
                                      @update:model-value="correctId('priority_id')"
                      ></VueMultiselect>
                    </div>
                  </template>
                  <template v-else>
                    <p>
                <span
                  @click="editPrio = true;$nextTick(() => $refs.multiselect_prio.activate())" style="cursor: pointer;">{{
                    getTitleById(taskItem.priority_id, "issue_prios")
                  }}</span>
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <hr>
            <div class="btn-group float-end">
              <!-- toggler for description public/hidden -->
              <button class="btn  btn-sm" :class="(taskItem.descVisible ==='1') ? 'btn-outline-primary':'btn-primary'" @click="toggleDescriptionVisibility()">
                <template v-if="taskItem.descVisible === '1'">
                  <span :tooltip="$t('globals.visible')"><i class="fa-duotone fa-eye"></i></span>
                </template>
                <template v-else>
                  <span :tooltip="$t('globals.hidden')"><i class="fa-duotone fa-eye-slash"></i></span>
                </template>
              </button>
            </div>
            <label class="color" style="display: inline-block;">{{ $t("globals.description") }}</label>
            <button tooltip="Beschreibung bearbeiten" type="button" class="btn ms-2 mb-2 btn-outline-primary btn-xs"
                    @click="showEditorDescription = !showEditorDescription;"><i class="fa-duotone fa-pen"></i></button>
            <div class="taskDesc" v-show="showEditorDescription">
              <Editor2 v-model="description"></Editor2>
              <p class="hintTask"><i class="fa-duotone fa-info-circle"></i> {{hints}}</p>
              <template v-if="taskItem.attachments && Object.values(taskItem.attachments).length > 0">
                <div class="row">
                  <div class="col-12">
                    <h6>Verfügbare Dateianhänge:</h6>
                    <template v-for="at in taskItem.attachments">
                      <div class="btn-group me-2 mb-2" v-if="at.contentType ==='application/pdf' || at.contentType === 'image/jpeg' || at.contentType === 'image/png' || at.contentType === 'image/svg' || at.contentType === 'image/jpg' || at.contentType === 'image/webp'">


                        <button class="btn btn-outline-primary btn-sm"  @click="openAttachment({detail: {id: at.id}})"><i class="fa-duotone fa-eye"></i></button>
                      <button class="btn btn-outline-primary btn-sm"  v-if="at.id"
                              @click="$store.dispatch('copyToClipboard','{attach:'+at.id+'}')"><i
                        class="fa-duotone fa-copy"></i> {{ at.id }}
                      </button>
                      </div> </template>
                  </div>
                </div>
              </template>
            </div>
            <template v-if="!showEditorDescription">
              <div v-html="modifiedHtml" class="taskDesc noEditor"></div>
            </template>
            <hr>
            <div class="row infoRow">
              <div class="col">
                <template v-if="!editStart">
                  <label class="color">{{ $t("globals.start") }}</label>
                  <span @click="editStart = true;"><i
                    class="fa-duotone fa-edit"></i> {{ $filters.formatDate(taskItem.start_date) }} </span>
                </template>
                <template v-else>
                  <label class="color">{{ $t("globals.start") }}</label>
                  <VueDatePicker class="small" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                 :placeholder="this.$t('tasks.details.chooseStart')" :auto-apply="true"
                                 :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X"
                                 :select-text="this.$t('globals.choose')"
                                 v-model="taskItem.start_date" @update:model-value="updateTask"></VueDatePicker>
                </template>
              </div>
              <div class="col">
                <template v-if="!editDue">
                  <label class="color">{{ $t("tasks.create.submissionDate") }}</label>
                  <span @click="editDue = true;"><i
                    class="fa-duotone fa-edit"></i>  {{ $filters.formatDate(taskItem.due_date) }}</span>
                </template>
                <template v-else>
                  <label class="color">{{ $t("tasks.create.submissionDate") }}</label>
                  <VueDatePicker class="small" model-type="yyyy-MM-dd" format="dd.MM.yyyy"
                                 :placeholder="this.$t('projects.chooseSubmission')" :auto-apply="true"
                                 :close-on-auto-apply="true"
                                 locale="de" :enable-time-picker="false" cancel-text="X"
                                 :select-text="this.$t('globals.choose')"
                                 v-model="taskItem.due_date" @update:model-value="updateTask"></VueDatePicker>
                </template>
              </div>
              <div class="col">
                <label class="color">{{ $t("tasks.details.timeExposure") }}</label>
                <input type="number" class="form-control hoverShowInput" v-model="taskItem.estimated_hours"
                       @update:model-value="updateTask"
                       style="width: 75px; display: inline-block; margin-left: 0; margin-right: 10px;"/>
              </div>
              <div class="externalResource mb-3 col">
                <label class="color">{{ $t("tasks.taskNr") }}</label>
                <span>{{ taskItem.id }}/{{ taskItem.hash }}</span>
                <template v-if="taskItem && taskItem.infos && taskItem.infos.publicLink"><a :href="taskItem.infos.publicLink" target="_blank" class="color-purple ms-2" tooltip="Öffentliche Ansicht"><i class="fa-duotone fa-external-link"></i></a></template>
                <span v-if="taskItem.external_ressource"><br>{{ taskItem.external_ressource }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-xl-2 col-12 rightCol">
            <label class="color">{{ $t("tasks.details.assignedTo") }}</label>
            <template v-if="!editAssignee">
              <p @click="editAssignee = true; $nextTick(() => $refs.multiselect.activate()) " style="cursor: pointer;">
                <img style="max-width: 40px"
                     v-if="getEmployees && getEmployees.length && taskItem && taskItem.employee_id"
                     :src="$filters.employeeIcon(getEmployees, taskItem.employee_id, 'id')"
                     class="sketchAvatar">
                <template v-if="taskItem && taskItem.employee_id">
                  &nbsp;{{ $filters.employeeName(getEmployees, taskItem.employee_id) }}
                </template>
                <template v-else>
                  -
                </template>
              </p>
            </template>
            <template v-else>
              <div style="position: relative;">
                <button class="btn-close" @click="editAssignee = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                <VueMultiselect class="small open" :options="getEmployees" v-model="taskItem.employee_id"
                                :multiple="false" :valueProp="'id'" ref="multiselect"
                                :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                track-by="e_name" :isOpen="true"
                                :placeholder="this.$t('tasks.details.chooseEditor')" label="e_name" :allow-empty="false"
                                :select-label="this.$t('tasks.details.chooseEditor')"
                                @update:model-value="correctId('employee_id')"
                ></VueMultiselect>
              </div>
            </template>
            <label class="color">{{ $t("tasks.details.createdBy") }}</label>
            <p v-if="taskItem.crm_created_employee_id"><img style="max-width: 40px"
                                                            :src="$filters.employeeIcon(getEmployees, taskItem.crm_created_employee_id, 'id')"
                                                            class="sketchAvatar">
              {{ $filters.employeeName(getEmployees, taskItem.crm_created_employee_id) }}</p>
            <p v-else>
              <template v-if="taskItem.author">
                <img style="max-width: 40px"
                     :src="$filters.generateSVGFromName(taskItem.author)"
                     class="sketchAvatar"> {{ taskItem.author }}
              </template>
              <template v-else>{{ $t("globals.customer") }}</template>
            </p>
            <label class="color">{{ $t("globals.tracker") }}</label>
            <template v-if="editTracker">
              <div style="position: relative;">
                <button class="btn-close" @click="editTracker = false;" style="
    z-index: 100000000000000000000;
    position: absolute;
    top: 4px;
    right: 4px;"></button>
                <VueMultiselect class="small open" :options="Object.values(getConfigs.issue_trackers)"
                                v-model="taskItem.tracker_id"
                                :multiple="false" :valueProp="'id'" ref="multiselect_tracker"
                                :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                                :isOpen="true" track-by="title" openDirection="bottom"
                                :placeholder="this.$t('tasks.details.chooseTracker')" label="title" :allow-empty="false"
                                :select-label="this.$t('tasks.details.chooseTracker')"
                                @update:model-value="correctId('tracker_id')"
                ></VueMultiselect>
              </div>
            </template>
            <template v-else>
              <template v-if="!taskItem.tracker_id">
                <button class="btn btn-outline-primary btn-sm mb-2 mt-2"
                        @click="editTracker = true;$nextTick(() => $refs.multiselect_tracker.activate())"><i
                  class="fa-duotone fa-plus"></i></button>
              </template>
              <p v-else
                 @click="editTracker = true;$nextTick(() => $refs.multiselect_tracker.activate())"
                 style="cursor: pointer;">{{
                  getTitleById(taskItem.tracker_id, "issue_trackers")
                }}</p>
            </template>
            <label class="color" @click="editExtFollower = true;" style="cursor: pointer;">{{ $t("tasks.details.contactPerson") }} <i class="fa-duotone fa-edit"></i></label>
            <p @click="editExtFollower = true;"
               style="cursor: pointer;">
              <template v-if="taskItem.followers && taskItem.followers.outer && taskItem.followers.outer.length">

                <template v-for="follower in taskItem.followers.outer">
                  <span style="display: block;">{{ follower._customerName }}</span>
                </template>
              </template>
              <template v-else>
                <template v-if="taskItem.customer">
                  <i class="fa-duotone fa-edit"
                     v-if="taskItem.customer.c_firstname && taskItem.customer.c_lastname"></i>&nbsp;
                  <template v-if="taskItem.customer.c_firstname">{{ taskItem.customer.c_firstname }}&nbsp;</template>
                  <template v-if="taskItem.customer.c_lastname">{{ taskItem.customer.c_lastname }}</template>
                </template>
              </template>
            </p>
            <label class="color" @click="editIntFollower = true"  style="cursor: pointer;">{{ $t("globals.employee") }} <i class="fa-duotone fa-edit"></i></label>
            <p @click="editIntFollower = true;"
               style="cursor: pointer;"
            >

              <template v-if="taskItem.followers && taskItem.followers.inner">
                <template v-for="follower in taskItem.followers.inner">
                  <span style="display: block;">{{ follower.e_name }}</span>
                </template>
              </template>
            </p>
            <p>
              <br>
              <a v-if="taskItem.linkPlanio" :href="taskItem.linkPlanio" target="_blank"
                 class="btn btn-outline-primary btn-sm"><i
                class="fa-duotone fa-square-arrow-up-right"></i>{{ $t("tasks.details.openExternal") }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="taskAttachments" v-if="activeSubtab === 'attachments'">
      <Attachments :attachments="taskItem.attachments" v-if="taskItem.attachments" @update="getSingleTask"
                   @checkTask="checkTask()" @uncheckTask="uncheckTask()"></Attachments>
    </div>
    <div class="subTasks" v-if="activeSubtab ==='subtasks'">
      <Subtasks :task="taskItem" v-if="taskItem" @saveTask="updateTask" @checkTask="checkTask()"
                @uncheckTask="uncheckTask()"></Subtasks>
    </div>
    <div class="taskComments" v-if="activeSubtab === 'comments'">
      <Comments :taskId="taskItem.id" v-if="taskItem.id" @checkTask="checkTask()" @uncheckTask="uncheckTask()"/>
    </div>
    <div class="timeSheet" v-if="activeSubtab === 'timesheet'">
      <Timesheet @update="getSingleTask" @checkTask="checkTask();" @uncheckTask="uncheckTask();"/>
    </div>
  </div>
  <Modal v-if="editExtFollower">
    <div class="modal fade show " tabindex="-1"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg purpleView">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ansprechpartner ändern</h5>
            <button type="button" class="btn-close" style="margin-left: 24px;" @click="
      this.updateTask();editExtFollower = false"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="follower in addresses">
                <div class="col-6"  v-if="follower.c_email">
                  <div class="prioSetter">
                    <!-- check if button is in array, if true then set it active with btn-primary -->
                    <template v-if="taskItem.followers.outer.some(item => item.id === follower.id)">
                      <button type="button" class="btn mb-2 w-100 btn-primary btn-sm  "
                              @click="setExtFollower(follower)"><span class="longName">{{ follower.c_title }} {{
                          follower.c_firstname
                        }} {{ follower.c_lastname }}<br><small>{{ follower.c_email }}</small></span></button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn mb-2 w-100 btn-outline-primary btn-sm  "
                              @click="setExtFollower(follower)"><span class="longName">{{ follower.c_title }} {{
                          follower.c_firstname
                        }} {{ follower.c_lastname }}<br><small>{{ follower.c_email }}</small></span></button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="editIntFollower">
    <div class="modal fade show " tabindex="-1"
         aria-hidden="true" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('tasks.details.chooseEmployee') }}</h5>
            <button type="button" class="btn-close" style="margin-left: 24px;" @click="
      this.updateTask();editIntFollower = false"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <template v-for="follower in getEmployees">
                <div class="col-3" v-if="follower.status === 'active' && follower.use_in_issues === 'yes'">
                  <div class="prioSetter">
                    <!-- check if button is in array, if true then set it active with btn-primary -->
                    <template v-if="taskItem.followers.inner.some(item => item.id === follower.id)">
                      <button type="button" class="btn mb-2 w-100 btn-primary btn-sm  "
                              :style="'border: 0;color:#fff; background-color:'+follower.color"
                              @click="setIntFollower(follower)"><img style="max-width: 40px"
                                                                     :src="$filters.employeeIcon(getEmployees, follower.id, 'id')"
                                                                     class="sketchAvatar" v-if="follower.id"> <span
                        class="shortName">{{ follower.e_name }}</span></button>
                    </template>
                    <template v-else>
                      <button type="button" class="btn mb-2 w-100 btn-outline-primary btn-sm  "
                              :style="'border-color: '+follower.color+';color:'+follower.color"
                              @click="setIntFollower(follower)"><img style="max-width: 40px"
                                                                     :src="$filters.employeeIcon(getEmployees, follower.id, 'id')"
                                                                     class="sketchAvatar" v-if="follower.id"> <span
                        class="shortName">{{ follower.e_name }}</span></button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="modalsList.previewFile">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-xl purpleView">
        <div class="modal-content" v-if="!loading">
          <template v-if="file">
            <div class="modal-header">
              {{ file.title }}
              <button type="button" class="btn-close" @click="closeFile()"></button>
            </div>
            <div class="modal-body">
              <template v-if="file.contentType ==='application/pdf'  ">
                <embed style="min-height: 80vh;" :src="'data:'+file.contentType+';base64,'+file.content">
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-12 text-center">
                    <div class="btn-group">
                      <button @click="zoomIn" class="btn btn-outline-primary btn-sm mb-2"><i
                        class="fa-duotone fa-magnifying-glass-plus"></i></button>
                      <button @click="zoomOut" class="btn btn-outline-primary btn-sm mb-2"><i
                        class="fa-duotone fa-magnifying-glass-minus"></i></button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <p class="mb-1"><small><i class="fa-duotone fa-computer-mouse-scrollwheel color-purple"></i> Das
                      Mausrad kann zum Zoomen verwendet werden</small>
                    </p></div>
                </div>
                <div class="row"  @wheel.prevent="handleWheel($event)">
                  <div class="col-12" style="overflow: hidden;">
                    <img :src="'data:'+file.contentType+';base64,'+file.content"
                         :style="{ transform: `scale(${zoomLevel})`, transformOrigin: transformOrigin }"
                         style="display: block; width: 100%; height: auto;">
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="modal-content" v-else>
          <div class="modal-header">
            <h5 class="modal-title">Datei wird geladen..</h5>
            <button type="button" class="btn-close" @click="closeFile()"></button>
          </div>
          <div class="modal-body">
            <preLoader type="inner" :loading="loading" height="300px" style="background: #fff;"/>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import Comments from "@/routing/core/Tasks/Comments.vue";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import Attachments from "@/routing/core/Tasks/Layouts/Attachments.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import VueMultiselect from 'vue-multiselect';
import Subtasks from "@/routing/core/Tasks/Layouts/Subtasks.vue";
import Timesheet from "@/routing/core/Tasks/Layouts/Timesheet.vue";
import customersearch from "@/filter/components/customersearch.vue";
import $ from "jquery";
import preLoader from "@/components/Loading.vue";

export default {
  name: "Details",
  components: {
    preLoader,
    Comments, Editor2, Attachments, VueDatePicker, VueMultiselect, Subtasks, Timesheet, customersearch
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getConfigs"]),
    modifiedHtml() {
      return this.description.replace(
        /\{attach:(.*?)\}/g,
        `<span onclick="handleClick('$1')" class="btn btn-outline-primary btn-sm">Datei öffnen $1</span>`
      ).replace(
        /\{ticket:(.*?)\}/g,
        `<span onclick="handleTicketLink('$1')" class="inlineTicketLink"><i class="fa-duotone fa-clipboard-list-check"></i> $1</span>`
      );
    }
  },
  data() {
    return {
      taskItem: {},
      file: '',
      loading: false,
      zoomLevel: 1,
      transformOrigin: 'center center',
      editorVisible: true,
      taskTabs: [
        {name: 'details', label: this.$t('tasks.labels.details'), icon: 'fa-memo-circle-info'},
        {name: 'subtasks', label: this.$t('tasks.labels.subtasks'), icon: 'fa-list-tree'},
        {name: 'attachments', label: this.$t('tasks.labels.attachments'), icon: 'fa-paperclip'},
        {name: 'comments', label: this.$t('tasks.labels.comments'), icon: 'fa-comments'},
        {name: 'timesheet', label: 'Zeitaufwand', icon: 'fa-stopwatch'},
      ],
      activeSubtab: 'details',
      description: '',
      descriptionPreview:'',
      editStart: false,
      editDue: false,
      editAssignee: false,
      editState: false,
      editPrio: false,
      editTracker: false,
      editExtFollower: false,
      editIntFollower: false,
      editProject: false,
      editCustomer: false,
      showEditorDescription: false,
      addresses: [],
      projects: [],
      channel: null,
      pusher: null,
      hints: 'Ticket verlinken mit {ticket:1234} | Anhang verlinken mit {attach:1234}',
    }
  },
  methods: {
    closeTask() {
      this.$store.dispatch("tasksFilter");
      this.$store.dispatch('setTask', null);
      this.$router.push({name: "Tasks"});
    },
    closeFile() {
      this.file = '';
      this.$store.commit('closeModal', 'previewFile');
    },
    changeToMaintask() {
      this.taskItem.parent_issue = null;
      this.updateTask();
    },
    toggleDescEditor() {
      this.showEditorDescription = !this.showEditorDescription;
    },
    setExtFollower(follower) {
      const index = this.taskItem.followers.outer.findIndex(item => item.id === follower.id);
      if (index !== -1) {
        // If the follower is already in the array, remove them
        this.taskItem.followers.outer.splice(index, 1);
      } else {
        // If the follower is not in the array, add them
        this.taskItem.followers.outer.push(follower);
      }
    },
    setIntFollower(follower) {
      const index = this.taskItem.followers.inner.findIndex(item => item.id === follower.id);
      if (index !== -1) {
        // If the follower is already in the array, remove them
        this.taskItem.followers.inner.splice(index, 1);
      } else {
        // If the follower is not in the array, add them
        this.taskItem.followers.inner.push(follower);
      }
    },
    isRejectable($id) {
      let a;
      for (a in this.getConfigs.issue_states) {
        if (this.getConfigs.issue_states[a].id === $id) {
          return this.getConfigs.issue_states[a].state === 'reject';
        }
      }
    },
    zoomIn() {
      this.zoomLevel += 0.1;
    },
    zoomOut() {
      if (this.zoomLevel > 0.8) this.zoomLevel -= 0.1;
    },
    handleWheel(event) {
      const target = event.target; // This should be your image or embed element
      const rect = target.getBoundingClientRect();
      // Calculate cursor position inside the element as a percentage
      const xPercent = ((event.clientX - rect.left) / rect.width) * 100;
      const yPercent = ((event.clientY - rect.top) / rect.height) * 100;
      // Update transformOrigin based on cursor position
      this.transformOrigin = `${xPercent}% ${yPercent}%`;
      // Zoom in or out
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },

    getSingleTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + 'issue/' + this.$route.params.id, {headers})
        .then(response => {
          this.taskItem = response.data.result.data;
          if (this.taskItem.description) {
            this.description = this.taskItem.description.replace(/\r\n/g, "<br>").replace(/\n/g, "<br>");
          } else {
            this.description = '';
          }
          this.$store.dispatch('setTask', this.taskItem);
          this.getAddresses();
          this.getProjects();
          this.scrollToTop();
        })
        .catch(e => {
          console.log(e);
        });
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    getProjects() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "project/search?filter_customer=" + this.taskItem.customer.id + "&filter_state=active", {headers}).then((r) => {
        this.projects = r.data.result.data;
      });
    },
    getTitleById(id, type) {
      if (this.getConfigs && this.getConfigs[type]) {
        return Object.values(this.getConfigs[type]).find(issueState => issueState.id === id)?.title || 'keine';
      }
    },
    clearCustomer() {
      this.taskItem.crm_customer_id = null;
      this.taskItem.customer = {};
      this.projects = [];
      this.taskItem.project = {};
      this.taskItem.project_id = null;
      this.taskItem.addresses = [];
    },
    getAddresses() {
      if (this.taskItem.crm_customer_id > 0) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.get(this.$store.state.apiUrl + "customer/" + this.taskItem.crm_customer_id + "/addresses", {headers}).then((r) => {
          this.addresses = r.data.result.data;
        });
      }
    },
    correctId(key) {
      this.taskItem[key] = this.taskItem[key].id;
      this.updateTask();
    },
    editItem(field) {
      const value = event.target.innerText;
      this.taskItem[field] = value;
      this.updateTask();
    },
    updateTask() {
      this.taskItem.description = this.description;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.taskItem.customer.hash;
      let postContent = {
        data: this.taskItem,
        hash: hash
      }
      axios.put(this.$store.state.apiUrl + 'issue/' + this.taskItem.id, postContent, {headers})
        .then(response => {
          this.editStart = false;
          this.editDue = false;
          this.editAssignee = false;
          this.editState = false;
          this.editPrio = false;
          this.editTracker = false;
          this.editExtFollower = false;
          this.editIntFollower = false;
          this.editProject = false;
          this.editCustomer = false;
          this.taskItem = response.data.result.data;
          this.description = this.taskItem.description;
          this.$store.dispatch('setTask', this.taskItem);
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
          window.dispatchEvent(new CustomEvent('task-unchecked'));
        });
    },
    copyTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {
        data: this.taskItem,
        hash: this.taskItem.customer.hash
      }
      postContent.data.id = null;
      postContent.data.title = this.taskItem.title + " (Kopie)";
      axios.post(this.$store.state.apiUrl + 'issue', postContent, {headers})
        .then(response => {
          this.closeTask();
          this.$store.dispatch("tasksFilter");
          this.$store.dispatch('toastSuccess', this.$t('toast.success.taskCopied'));
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
    checkTask() {

      let default_checked_id = parseInt(this.getConfigs.params_values.issue_default_close);
      this.taskItem.state_id = default_checked_id;
      window.dispatchEvent(new CustomEvent('task-checked'));
      this.updateTask();
    },
    uncheckTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.taskItem.customerHash;
      let postContent = {
        data: this.taskItem,
        hash: hash
      }
      let default_unchecked_id = parseInt(this.getConfigs.params_values.issue_default_open);
      this.taskItem.state_id = default_unchecked_id;
      window.dispatchEvent(new CustomEvent('task-unchecked'));
      this.updateTask()
    },
    deleteTask(id) {
      this.$confirm(this.$t('tasks.details.detailsTextDelete'), this.$t('tasks.deleteTask'), {
        confirmButtonText: this.$t('globals.yes'),
        cancelButtonText: this.$t('globals.no'),
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + 'issue/' + id, {headers})
          .then(response => {
            this.$store.dispatch('setTask', null);
            this.$store.dispatch("tasksFilter");
            this.$router.push({name: "Tasks"});
            this.$store.dispatch('toastSuccess', this.$t('toast.success.taskDeleted'));
          })
          .catch(e => {
            this.$store.dispatch("handleApiResponse", e.response);
          });
      }).catch(e => {
        console.log(e);
      });
    },
    setActiveSubtabFromRoute() {
      const {tab} = this.$route.params;
      if (tab && this.isValidTab(tab)) {
        this.activeSubtab = tab;
      } else {
        this.activeSubtab = 'details';
      }
    },
    isValidTab(tab) {
      return this.taskTabs.some((taskTab) => taskTab.name === tab);
    },
    changeTab(tabName) {
      this.activeSubtab = tabName;
      this.$router.push({name: 'TasksTabs', params: {id: this.$route.params.id, tab: tabName}});
    },
    hideEditor() {
      this.editorVisible = false;
    },
    showEditor() {
      this.editorVisible = true;
    },
    toggleDescriptionVisibility() {
      // set descVisible to string 0 or 1
      this.taskItem.descVisible = this.taskItem.descVisible === '1' ? '0' : '1';

      this.updateTask();
    },
    openAttachment(attachment) {

      const attachmentId = attachment.detail.id;
      const issueId = this.taskItem.id;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.loading = true;
      this.$store.commit('openModal', 'previewFile');
      axios.get(this.$store.state.apiUrl + "issue/" + issueId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          this.file = response.data.result.data;
          this.loading = false;
          // show in modal
        })
        .catch((error) => {
          this.$store.dispatch('handleApiResponse', error.response);
          this.loading = false;
          // close modal
          this.$store.commit('closeModal', 'previewFile');
        });
    },
    openTicket(ticket){
      const ticket_id = ticket.detail.id;
      this.$router.push({name: 'TasksTabs', params:{ id: ticket_id, tab: 'details'}});
    }
  },
  created() {
    this.getSingleTask();
    this.setActiveSubtabFromRoute();
    window.addEventListener("triggerReloadFiles", this.getSingleTask);
    window.addEventListener("triggerOpenAttachment", this.openAttachment);
    window.addEventListener("triggerOpenTicket", this.openTicket);
  },
  beforeUnmount() {
    if (this.channel) {
      this.channel.unbind('issue-' + this.$route.params.id);
      this.pusher.unsubscribe(this.$store.state.apiToken);
    }
    window.removeEventListener("triggerReloadFiles", this.getSingleTask);
    window.removeEventListener("triggerOpenAttachment", this.openAttachment);
    window.removeEventListener("triggerOpenTicket", this.openTicket);
  },
  mounted() {
    Pusher.logToConsole = false;
    this.pusher = new Pusher('d4e91a37c1c6462058ae', {cluster: 'eu'});
    let VueInner = this;
    this.channel = this.pusher.subscribe(this.$store.state.apiToken);
    this.channel.bind('issue-' + this.$route.params.id, function (data) {
      VueInner.getSingleTask();
    });
  },
  watch: {
    taskActive() {
    },
    $route(to) {
      if (to.params.tab) {
        this.activeSubtab = to.params.tab;
      } else {
        this.activeSubtab = 'details';
      }
      if (to.params.id) {
        this.getSingleTask();
      }
    }
  },
}
</script>
<style>
.multiselect.small.open .multiselect__select:before {
  display: none;
}

.col-lg-3.col-xl-2.col-12.rightCol p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
