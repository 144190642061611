<template>
  <div class="draftLineWrap" :class="($store.state.activeItems && $store.state.activeItems.ConfigWorkflowTracker && $store.state.activeItems.ConfigWorkflowTracker.id === draft.id) ? 'active' : ''" >
    <div class="lineitem l-title" @click="setConfig(draft)"   style="cursor: pointer;">
      {{ draft.title }}
    </div>
    <div class="lineitem l-state" style="font-size: 13px; width: 150px;" >
      {{ $t("states.issueTracker."+draft.state) }}
    </div>
    <div class="lineitem l-default">
      <template v-if="draft.official === 'official'">
        <i class="fa-duotone fa-check-circle" style="color: #3B8130"></i>
      </template>
      <template v-else>
        <i class="fa-duotone fa-xmark-circle" style="color: #b21f28"></i>
      </template>

    </div>


    <div class="lineitem l-number ml-auto">
      {{ draft.ordering }}
    </div>

    <div class="lineitem last">
      <template v-if="$store.state.activeItems && $store.state.activeItems.ConfigWorkflowTracker && $store.state.activeItems.ConfigWorkflowTracker.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigWorkflowTracker');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters([ "getEmployees" ])
  },
  methods: {
    setConfig(draft) {
      const newData = {data: draft, type:'ConfigWorkflowTracker'};
      this.$store.dispatch("setActiveConfig",  newData).then(() => {
        this.$router.push({name: 'ConfigWorkflowTrackerDetails', params: {id: draft.id}})
      });


    },
  }
}
</script>
