import axios from "axios";
import IndexedDbFunctions from "@/assets/js/indexedDbFunctions";

export const actions = {
  listGet({commit, getters, state, dispatch}, {resource, apiRoute, filterQuery}) {
    let meta = {
      counter: 0,
      complete: 0,
      emptyResult: true,
      data: [],
      [`complete${resource.charAt(0).toUpperCase()}${resource.slice(1)}`]: 0,
      loading: true,
      offset: 0,
      limit: 20,
      selected: {}
    };
    const resourcePlural = `${resource}s`;
    if (state.lists[resourcePlural].offset) {
      meta.offset = state.lists[resourcePlural].offset;
    }
    if(!state.lists[resourcePlural].loading){
      state.lists[resourcePlural].loading = true;
    }
    if (state.lists[resourcePlural].limit) {
      meta.limit = state.lists[resourcePlural].limit;
    }
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    let timestamp = Date.now();
    axios
      .get(
        getters.getApiUrl +
        apiRoute +
        "/search?offset=" +
        meta.offset +
        "&limit=" +
        meta.limit +
        filterQuery+"&ts="+timestamp,
        {headers}
      )
      .then((response) => {
        meta.data = response.data.result.data;

        // Use the new mutation to initialize the 'selected' state
        commit('initializeSelectedState', resourcePlural);

        meta.complete = response.data.result.complete;
        meta[`complete${resource.charAt(0).toUpperCase()}${resource.slice(1)}`] = meta.complete; // Set dynamic variable name
        meta.loading = false;
        meta.counter = response.data.result.counter;
        if (!meta.counter) {
          meta.emptyResult = true;
        }
        commit(`${resource}Set`, meta); // Generate dynamic commit name
        if(resource === 'customer'){
          if(getters.getConfigs  && getters.getConfigs.params_values && getters.getConfigs.params_values.open_customer_after_search ==='1'){
            if(getters.customerList && getters.customerList.data.length > 0 && state.filters.customers.search.value !== ''){

              dispatch("setActiveCustomer", getters.customerList.data[0].id);
            }}
        }
      }).catch((r) => { commit("handleApiResponse", r.response) });
  },
  async getEmployees({commit, getters}) {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    axios.get(getters.getApiUrl + "employee/search?limit=0", {headers}).then((r) => {
      commit("setEmployees", r.data.result.data);
    });
  },async getLanguageOverrides({commit, getters}) {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};

    axios.get(getters.getApiUrl + "config/overrides", {headers}).then((r) => {
      commit("setLanguageOverrides", r.data.result.data);
    });
    const style = document.createElement('style');
    style.type = 'text/css';
    if(getters && getters.getConfigs.params_values && getters.getConfigs.params_values.vue_css){
      style.textContent = getters.getConfigs.params_values.vue_css;
      document.head.appendChild(style);
    }

  },
  async loadConfig({getters, commit, state}, config) {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    let getUrl = getters.getApiUrl + "config/" + config.type;
    if (config.subType !== undefined) {
      getUrl = getUrl + "/" + config.subType;
    }
    getUrl = getUrl + "?limit=0";
    await axios.get(getUrl, {headers}).then(async (response) => {
      let shortResponse = response.data.result;
      commit("setConfigLoader", {loadPercent: config.percent, loadText: config.type, loadMessage: config.message});
      let key = config.type;
      if (config.subType !== undefined) {
        key = key + "_" + config.subType;
      }
      commit("setConfig", {key: key, data: shortResponse.data});
      await IndexedDbFunctions.addObject(state.dbName, state.dbVersion, 'config', {
        id: key,
        data: shortResponse.data
      });
    }).catch(function () {
    });
  },
  async loadProducts({getters, commit, state}) {
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    let getUrl = getters.getApiUrl + "product/search?limit=0";
    await axios.get(getUrl, {headers}).then(async (response) => {
      let shortResponse = response.data.result;
      commit("setProducts", shortResponse.data);
      commit("setAllProducts", shortResponse.data);
      let p;
      for (p in shortResponse.data) {
        await IndexedDbFunctions.addObject(state.dbName, state.dbVersion, 'products', {
          id: shortResponse.data[p].id,
          data: shortResponse.data[p]
        });
      }
    }).catch(function (r) {
      commit("handleApiResponse", r.response)
    });
  },
  async loadDrafts({getters, commit, state}, filterQuery) {
    let meta = {
      counter: 0,
      complete: 0,
      emptyResult: true,
      data: [],
      completeProducts: 0,
      loading: true,
      offset: 0,
      selected: {},
      limit: 5,
    }
    if (state.lists.drafts.offset) {
      meta.offset = state.lists.drafts.offset;
    }
    if (state.lists.drafts.limit) {
      meta.limit = state.lists.drafts.limit;
    }
    const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
    let getUrl = getters.getApiUrl + "draft/search?offset=" + meta.offset;
    if (filterQuery !== true) {
      getUrl = getUrl + "&limit=" + meta.limit + filterQuery;
    } else {
      getUrl = getUrl + "&limit=0";
    }
    await axios.get(getUrl, {headers}).then(async (response) => {
      let shortResponse = response.data.result;
      meta.loading = false;
      meta.data = shortResponse.data;
      meta.counter = response.data.result.counter;
      meta.complete = response.data.result.complete;
      if (response.data.result.complete > 0) {
        meta.emptyResult = false;
      }
      commit("setDrafts", meta);
      let p;
      for (p in shortResponse.data) {
        await IndexedDbFunctions.addObject(state.dbName, state.dbVersion, 'drafts', {
          id: shortResponse.data[p].id,
          data: shortResponse.data[p]
        });
      }
    }).catch(function (r) {
      commit("handleApiResponse", r.response)
    });
  },
}
