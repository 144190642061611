<template>
  <div class="card">
    <div class="card-header card-header-inline">
      <template v-if="getConfigs && getConfigs.params_values && (task.state_id === getConfigs.params_values.issue_default_close)">
        <button class="checkCircleBtn btn-primary btn btn-sm" @click="uncheckTask(task.id)"><i
          class="fa-duotone fa-check fa-xl"></i></button>
      </template>
      <template v-else>
        <button class="checkCircleBtn btn-outline-primary btn btn-sm" @click="checkTask(task.id)"><i
          class="fa-duotone fa-check fa-xl"></i></button>
      </template>
      <h6 class="editLine">{{
          task.title
        }}</h6>

      <div class="btn-group float-end">
        <button class="backToParent btn-outline-primary btn btn-sm " @click="$router.push({ name: 'TasksTabs', params: { id: task.parent_issue, tab: 'subtasks' } })" v-if="task && task.parent_issue"><span tooltip="Zurück zum Haupttask"><i
          class="fa-duotone fa-share-from-square fa-flip-horizontal"></i></span></button>

        <button class="btn btn-outline-primary btn-sm" @click="addSubtask"><i
          class="fa-duotone fa-list-tree"></i> Neuer Subtask
        </button>
      </div>
      <button class="btn btn-primary btn-sm float-end me-5" @click="toggleFilter" v-if="filter ==='all'" tooltip="Alle Tasks anzeigen"><i class="fa-duotone fa-circle-check"></i></button>
      <button v-else class="btn btn-outline-secondary btn-sm float-end me-5" @click="toggleFilter" tooltip="Nur offene Tasks anzeigen"><i class="fa-duotone fa-check"></i></button>
    </div>
    <div class="card-body">
      <template v-if="addSubTask">
        <h6 class="editLine subtask" ref="editMe" contenteditable @keypress.enter.prevent="editItem('title')"  @keydown.esc="addSubTask = false">{{
            subtask.title
          }}</h6>
      </template>
      <List :tasks="subtasks" />

    </div>
  </div>
</template>
<script>
import axios from "axios";
import List from "@/routing/core/Tasks/Sublist";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Subtasks",
  emits: ["checkTask", "uncheckTask"],
  props: {
    task: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["getConfigs"])
  },
  components: {
    List
  },
  data() {
    return {
      subtasks: [],
      addSubTask: false,
      filter: 'open',
      subtask: {
        title: ''
      }
    };
  },
  methods: {
    checkTask() {
      this.$emit('checkTask');
      window.dispatchEvent(new CustomEvent('task-checked'));
    },
    uncheckTask(task) {
      this.$emit('uncheckTask');
      window.dispatchEvent(new CustomEvent('task-unchecked'));
    },
    toggleFilter() {
      if(this.filter === 'all'){
        this.filter = 'open';
      }
      else{
        this.filter = 'all';
      }
      this.getSubtasks(this.task.id)
    },
    addSubtask() {
      this.addSubTask = true;
      // timeout
      this.$nextTick(() => {
        this.$refs.editMe.focus();
      });

    },
    editItem(field) {
      const value = event.target.innerText;
      this.subtask[field] = value;
      this.postSubTask();
    },

    postSubTask() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const hash = this.task.customer.hash;
      this.task.parent_issue = this.task.id;
      if (this.task.main_issue === undefined || !this.task.main_issue) {
        this.task.main_issue = this.task.parent_issue;

      }
      const task = Object.assign({}, this.task);
      task.title = this.subtask.title;

      task.state_id = '5';
      delete task.description;
      delete task.id;
      let postContent = {
        data: task,
        hash: hash
      }
      axios.post(this.$store.state.apiUrl + 'issue/', postContent, {headers})
        .then(response => {
          this.getSubtasks(this.task.id);
          this.addSubTask = false;
          this.subtask.title = '';
          this.addSubtask();
        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
    },
    getSubtasks(taskid){
      // if taskis is object
      if(typeof taskid === 'object')
      {
        taskid = taskid.detail;
      }
      if(taskid === undefined){
        taskid = this.$route.params.id;
      }
      let filter = '';
      if(this.filter === 'open'){
        filter = '&filter_state=open';
      }else {
        filter = '&filter_state=all';
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + 'issue/search?filter_issue=' + taskid + '&limit=0' + filter, {headers})
        .then(response => {
          this.subtasks = response.data.result.data;

        })
        .catch(e => {
          this.$store.dispatch("handleApiResponse", e.response);
        });
      return true;
    }
  },
  mounted() {
    this.getSubtasks(this.task.id);
  },
  created() {
    window.addEventListener('reloadSubtasks', this.getSubtasks);
  },
  beforeDestroy() {
    window.removeEventListener('reloadSubtasks', this.getSubtasks);
  },
watch:{
  task(){
    this.getSubtasks(this.task.id);
  }
}
}
</script>
<style scoped>
.editLine.subtask{
  border-bottom: 1px solid #333;
  width: 100%;
  min-height: 24px;
}
</style>
