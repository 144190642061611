<template>
  <div class="card" >
    <div class="card-header">
      <h6>Aufgaben diese Woche</h6>
    </div>

      <template v-if="tasks && tasks.length > 0">
        <ul class="list-group cursorlist" style="margin-left: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0;">
          <li v-for="task in tasks" class="list-group-item text-xs" @click="routeToTask(task.id)">
            <button class="checkCircleBtn btn-outline-primary btn btn-sm me-2" ><i class="fa-duotone fa-check fa-xl"></i></button> {{ task.title }}
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="card-body"><small>Keine Aufgaben gefunden.</small></div>
      </template>

  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'tasksWeekly',
  components: {
  },
  data() {
    return {
      activeEmployeeTab: 'contacts',
      tasks: [],
      loading: false,
    }
  },
  props: {
    filter: {
      type: String,
      default: ""
    },
    options: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["getMenu", "getConfigs"])
  },
  methods: {
    getTasksWeekly() {
      let VueInner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "statistic/tasks/weekly" + this.filter, {headers}).then((r) => {
        VueInner.tasks = r.data.result.data;
        VueInner.loading = false;
      });
    },
    routeToTask(id){
      this.$router.push({name: 'TaskDetails', params: {id: id}});
    }
  },
  watch: {
    filter: function (newVal, oldVal) {
    },

  },
  created() {
    this.getTasksWeekly();
  },
}
</script>
