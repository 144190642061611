<template>
  <div class="row" v-if="values">
<div class="col-12">
  <div class="oldSubmit">
    <h4>Ursprüngliche Eingabe</h4>
    <table>
      <tr v-if="values.calcedPoints">
        <td>Punkte errechnet</td>
        <td>{{values.calcedPoints}}</td>
      </tr>
      <tr v-if="values.answer_id">
        <td>Ausgewählte Frage</td>
        <td>#{{values.answer_id}}</td>
      </tr>
      <tr v-if="values.verein">
        <td>Verein</td>
        <td>{{values.verein}}</td>
      </tr>
      <tr v-if="values.altersklasse">
        <td>Altersklasse</td>
        <td>{{values.altersklasse}}</td>
      </tr>
      <tr v-if="values.liga">
        <td>Liga</td>
        <td>{{values.liga}}</td>
      </tr>
      <tr v-if="values.dateStart && values.dateStart !== '0000-00-00' ">
        <td>Beginn</td>
        <td>{{values.dateStart}}</td>
      </tr>
      <tr v-if="values.dateEnd && values.dateEnd !== '0000-00-00'">
        <td>Ende</td>
        <td>{{values.dateEnd}}</td>
      </tr>
      <tr v-if="values.saisons">
        <td>Saisons / Spiele</td>
        <td>{{values.saisons}}</td>
      </tr>

      <tr v-if="values.schwerpunkt">
        <td>Schwerpunkt</td>
        <td>{{values.schwerpunkt}}</td>
      </tr>
      <tr v-if="values.institution">
        <td>Institution</td>
        <td>{{values.institution}}</td>
      </tr>
      <tr v-if="values.verband">
        <td>Verband</td>
        <td>{{values.verband}}</td>
      </tr>
    </table>
  </div>
</div>
</div>
</template>
<script>
export default {
  name: 'OldSubmit',
  props: {
    values: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss">
.oldSubmit table {
  width: 100%;
  margin-bottom: 48px;
  tr td:first-child {
    width: 200px;
    text-align: right;
    padding-right: 24px;
  }
}
</style>