<template>
  <div class="appView products">
  <List/>
  </div>


</template>
<script>
import axios from "axios";
import Details from "./Details.vue";
import {mapGetters} from "vuex";
import List from "./List.vue";
export default {
  name: "ProductsOverview",
  components: {
    Details, List
  },
  computed: {
    ...mapGetters(["productList"])
  },
  methods: {
    setProduct(product) {
      this.activeProduct = product;
      this.$router.push({name: "Details", params: {id: product.id}});
    },
    filter() {
      this.filter_query = '';
      if (this.filter_search) {
        this.filter_query += '&search=' + this.filter_search;
      }
      if (this.filter_category_id) {
        this.filter_query += '&filter_category_id=' + this.filter_category_id;
      }
      if (this.filter_state) {
        this.filter_query += '&filter_state=' + this.filter_state;
      }
    },
  },
  data() {
    return {
      activeProduct: null,
      productCategories: [],
      filter_query: '',
      filter_search: '',
      filter_category_id: '',
      filter_state: '',
    };
  },
  created() {
    this.$store.dispatch("productsFilter");

  },
  watch: {
    '$route': function () {
      if (!this.$route.params.id) {
        this.activeProduct = null;
      }
    }}
};
</script>
<style lang="scss">
.product-item {
  cursor: pointer;
}
</style>
