<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pb-3">
            <h6><span class="badge badge-sm p-2 bg-secondary me-3">{{ d.data.info_field_0 }}</span> {{ d.data.number }}</h6>
          </div>
        </div>
      </div>
    </div>


    <div class="row mt-3">
      <div class="col-6">
        <div class="card">
          <div class="card-body pb-3">
            <h6>Empfänger</h6>
            <p class="text-sm text-secondary mb-0">{{ d.address.c_company }}</p>
            <p class="text-sm text-secondary mb-0">{{ d.address.c_title }} {{ d.address.c_firstname }} {{ d.address.c_lastname }}</p>
            <p class="text-sm text-secondary mb-0">{{ d.address.c_street }}</p>
            <p class="text-sm text-secondary mb-0">{{ d.address.c_zipcode }} {{ d.address.c_city }}</p>
          </div>
        </div>
      </div>
    </div>



    <div class="row mt-3">
      <div class="col-12">

        <div class="card">

          <div class="card-body pb-3">

            <div class="row mb-3">

              <div class="col-md-1">
                <p class="text-sm text-secondary mb-0">#</p>
              </div>

              <div class="col-md-7">
                <p class="text-sm text-secondary mb-0">Bezeichnung</p>
              </div>

              <div class="col-md-1">
                <p class="text-sm text-end text-secondary mb-0">Menge</p>
              </div>

              <div class="col-md-1">
                <p class="text-sm  text-secondary mb-0">Einheit</p>
              </div>

              <div class="col-md-1">
                <p class="text-sm text-end text-secondary mb-0">Preis</p>
              </div>

              <div class="col-md-1">
                <p class="text-sm text-end text-secondary mb-0">Betrag</p>
              </div>

            </div>

            <div class="document-items">
              <DocumentItem v-for="item in d.items" :key="item.id" :document-item="item" />
            </div>

            <hr>

            <div class="row mt-3">

              <div class="col-md-2 offset-md-8">

                <p class="text-sm mb-0 font-weight-bold mt-1">Gesamtsumme: </p>
                <p class="text-sm mb-0 font-weight-bold mt-1" v-for="(tax, group) in d.taxGroups" :key="tax.ust">zzgl. {{ group }} % Mwst:</p>
                <p class="text-sm mb-0 font-weight-bold mt-3">Gesamtsumme inkl. MwSt.:</p>

              </div>

              <div class="col-md-2">


                <p class="text-sm mb-0 mt-1 text-end">{{ $filters.priceEuro(d.sums.complete_netto) }}</p>
                <p class="text-sm mb-0 mt-1 text-end" v-for="(tax) in d.taxGroups" :key="tax.ust">{{ $filters.priceEuro(tax.ust) }}</p>
                <p class="text-sm mb-0 mt-3 text-end">{{ $filters.priceEuro(d.sums.complete_brutto) }}</p>

              </div>

            </div>

          </div>

        </div>

      </div>
    </div>


  </div>
</template>

<script>


import axios from "axios";

import DocumentItem from "./components/document/DocumentItem.vue";

export default {
  name: "Document",

  data() {
    return {
      currentDoc: null,
      d: {},
    };
  },

  components: {
    DocumentItem,
  },

  methods: {
    getDoc() {
    let Vueinner = this;
    this.loading = true;
    const headers = {"crmAuth":localStorage.getItem("sketchManagerToken")};
    axios.get(this.$store.state.apiUrl + "document/"+this.currentDoc, {headers}).then((r) => {
      Vueinner.d = r.data.result.document;
      Vueinner.loading = false;
    });
    }
  },

  created() {
    this.d = this.documents;
    console.log("asfa");
    this.currentDoc = this.$route.params.docId;
    this.getDoc();
  },

  watch : {
    '$route.params.docId' () {
      this.currentDoc = this.$route.params.docId;
      console.log("changed");
      this.getDoc();
    }
  },

};
</script>

<style>

/*
.document-item:nth-child(odd) {
  background-color: #f2f2f2;
}
*/

.document-item:nth-child(even) {
  background-color: #fff;
}

</style>
