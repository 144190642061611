<template>
  <div class="monthFilterWrap">
  <div class="row">
    <!-- add employees -->
    <div class="col">
      <div class="input-group">
      <div class="input-group-prepend">
        <label class="input-group-text" style="border-radius:  0.3rem 0 0 0.3rem " for="inputGroupSelect01">Mitarbeiter</label>
      </div>
      <select class="form-select small" id="inputGroupSelect01" v-model="employee"  @change="filterMonthYear">
        <option value="">Alle</option>
        <option v-for="option in getEmployees" :value="option.id">{{option.e_name}}</option>
      </select>
    </div>
    </div>

    <div class="col">
      <div class="input-group">
      <div class="input-group-prepend">
        <label class="input-group-text" style="border-radius:  0.3rem 0 0 0.3rem " for="inputGroupSelect01">Monat</label>
      </div>
      <select class="form-select small" id="inputGroupSelect01" v-model="month"  @change="filterMonthYear">
        <option v-for="option in monthOptions" :value="option.value">{{option.text}}</option>
      </select>
    </div>
    </div>
    <div class="col"><div class="input-group">
      <div class="input-group-prepend" >
        <label class="input-group-text" style="border-radius:  0.3rem 0 0 0.3rem " for="inputGroupSelect01">Jahr</label>
      </div>
      <select class="form-select small" id="inputGroupSelect01" v-model="year" @change="filterMonthYear">
        <option v-for="option in yearOptions" :value="option.value">{{option.text}}</option>
      </select>
    </div></div>
  </div>

  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "FilterMonthYear",
  emits: ['filterMonthYear'],
  data() {
    return {

      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      employee: '',
      monthOptions: [
        {value: 1, text: 'Januar'},
        {value: 2, text: 'Februar'},
        {value: 3, text: 'März'},
        {value: 4, text: 'April'},
        {value: 5, text: 'Mai'},
        {value: 6, text: 'Juni'},
        {value: 7, text: 'Juli'},
        {value: 8, text: 'August'},
        {value: 9, text: 'September'},
        {value: 10, text: 'Oktober'},
        {value: 11, text: 'November'},
        {value: 12, text: 'Dezember'},
      ],
      yearOptions: [

      ],
    };
  },
  computed: {
    ...mapGetters(["getEmployees", "getUser"])

  },
  methods:{
    createYearOptions(){
      let currentYear = new Date().getFullYear();
      let yearOptions = [];
      for(let i = 0; i < 8; i++){
        yearOptions.push({value: currentYear - i, text: currentYear - i});
      }
      this.yearOptions = yearOptions;
    },
    filterMonthYear(){
      this.$emit('filterMonthYear', {month: this.month, year: this.year, employee:this.employee});
    }
  },
  created() {
    this.createYearOptions();
    if(this.getUser && this.getUser._isEmployee){
      this.employee = this.getUser._isEmployee;
      this.filterMonthYear();

    }
  },
}
</script>
