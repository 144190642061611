<template>
  <div class="sketch_content_wrap">
    <div class="sketch-row">
      <div class="columns large-12 medium-12 small-12"><h2 class="blue">Einstellungen <span>Basis-Module</span></h2>
      </div>
    </div>
    <!--    <div class="sketch-row">-->
    <!--      <div class="columns xlarge-push-3 large-push-3 xlarge-6 large-6 medium-12 small-12">-->
    <!--        <div class="card mb-3 config-card">-->
    <!--          <router-link to="config/global" class="config-link">-->
    <!--            <div class="card-body text-center">-->
    <!--              <i class="fa-duotone fa-cog fa-4x mb-3"></i>-->
    <!--              <h3>Globale Einstellungen</h3>-->
    <!--            </div>-->
    <!--          </router-link>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="configWrap blue">
      <div class="sketch-row">
        <div class="columns large-12 medium-12 small-12"><h3><i class="fa-duotone fa-user-gear"></i> Kategorie für
          Kunden</h3></div>
      </div>
      <div class="sketch-row">
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customeracquisitions" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-handshake-simple fa-3x mb-3"></i>
                <h3>Akquise-Stand</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customerlead" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-folder-user fa-3x mb-3"></i>
                <h3>Lead-Quelle</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customerprio" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-user-tag fa-3x mb-3"></i>
                <h3>Prioritäten</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customertype" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-ranking-star fa-3x mb-3"></i>
                <h3>Kundentypen</h3>
              </div>
            </router-link>
          </div>
        </div>

      </div>
    </div>
    <div class="configWrap blue">
      <div class="sketch-row">
        <div class="columns large-12 medium-12 small-12"><h3><i class="fa-duotone fa-user-gear"></i> Eigene Strukturen</h3></div>
      </div>
      <div class="sketch-row">
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customerstructure/1" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-handshake-simple fa-3x mb-3"></i>
                <h3>{{ $t('edit.structure1') }}</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customerstructure/2" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-folder-user fa-3x mb-3"></i>
                <h3>{{ $t('edit.structure2') }}</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customerstructure/3" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-user-tag fa-3x mb-3"></i>
                <h3>{{ $t('edit.structure3') }}</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customerstructure/4" class="config-link">
              <div class="card-body text-center">
               <!-- icon -->
                <i class="fa-duotone fa-user-tag fa-3x mb-3"></i>
                <h3>{{ $t('edit.structure4') }}</h3>
              </div>
            </router-link>
          </div>
        </div>

      </div>
    </div>
    <div class="configWrap">
      <div class="sketch-row">
        <div class="columns large-12 medium-12 small-12"><h3><i class="fa-duotone fa-gears"></i> Weitere Einstellungen
        </h3></div>
      </div>
      <div class="sketch-row">
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customertags" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-tags fa-3x mb-3"></i>
                <h3>Tags</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/countryConfigs" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-earth-europe fa-3x mb-3"></i>
                <h3>Länder</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link to="/config/basic/customertitle" class="config-link">
              <div class="card-body text-center">
                <i class="fa-duotone fa-file-audio fa-3x mb-3"></i>
                <h3>Anreden</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link
              to="/config/basic/langoverrides" class="config-link"
            >
              <div class="card-body text-center">
                <i class="fa-duotone fa-language fa-3x mb-3"></i>
                <h3>Sprachoverrides</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="columns hd-2 xlarge-3 large-6 medium-12 small-12">
          <div class="card mb-3 config-card">
            <router-link
              to="/config/global" class="config-link"
            >
              <div class="card-body text-center">
                <i class="fa-duotone fa-cog fa-3x mb-3"></i>
                <h3>Globale Einstellungen</h3>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="sketch-row">-->
    <!--      <div class="columns large-12 medium-12 small-12">-->
    <!--        <div class="card mb-3">-->
    <!--          <div class="card-body text-center">-->
    <!--            <h3>Datenbank-Migration</h3>-->
    <!--            <router-link to="/addSchema" class="btn btn-primary">Schema anlegen und prüfen</router-link>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
import SidenavItem from "@/sublayouts/Sidenav/SidenavItem.vue";

export default {
  name: "ConfigBasic",
  components: {SidenavItem}
}
</script>

