<template>
  <div class="card-body draft-list project-list">
    <p class="text-sm text-secondary" v-if="!projectList || projectList.data.length === 0">Es wurden keine Projekte
      gefunden.</p>

    <Sort @sort="resortOrder($event)" />

    <div  class="draft-item" v-for="project in projectList.data"
          :key="project.id">
      <div class="draftLineWrap projectLine" :class="(project.prepared && project.prepared.typeString) ? project.prepared.typeString : ''">
        <div class="lineitem first l-checkbox">
          <div class="checkbox">
            <input type="checkbox"
                   :id="'id_'+project.id"
                   :value="project.id"
                   v-if="projectList && projectList.selected"
                   v-model="projectList.selected[project.id]"/>
            <label :for="'id_'+project.id"></label>
          </div>
        </div>
        <div class="lineitem l-title" @click="setDraft(project)">
          {{ project.title }}
        </div>
        <div class="lineitem l-state">
          <template v-if="project.state === 'deleted'">
            <span class="badge w-100 bg-danger badge-xs" v-if="project.state" :class="'state_'+project.state">{{$t('project.state.'+ project.state) }}</span>
          </template>
          <template v-else-if="project.state === 'prepare'">
            <span class="badge w-100 bg-warning badge-xs" v-if="project.state" :class="'state_'+project.state">{{$t('project.state.'+ project.state) }}</span>
          </template>
          <template v-else-if="project.state === 'order'">
            <span class="badge w-100 bg-success badge-xs" v-if="project.state" :class="'state_'+project.state">{{$t('project.state.'+ project.state) }}</span>
          </template>
          <template v-else>
          <span class="badge w-100 bg-primary badge-xs" v-if="project.state" :class="'state_'+project.state">{{$t('project.state.'+ project.state) }}</span>
            <span v-else>-</span>
          </template>



        </div>
        <div class="lineitem l-date" :tooltip="'erstellt'" :position="'left'"><i
          class="fa-duotone fa-calendar-circle-plus"></i> {{ $filters.formatDate(project.crm_created_date) }}
        </div>
        <div class="lineitem l-date" :tooltip="'bearbeitet'" :position="'left'"><i
          class="fa-duotone fa-calendar-circle-user"></i> {{ $filters.formatDate(project.crm_modified_date) }}
        </div>
        <Holzkraft :project="project" mode="listHeadline"  v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft" />
        <div class="lineitem l-avatar ml-auto">
          <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, project.employee_id, 'id')" class="sketchAvatar" v-if="project.employee_id">
        </div>

        <div class="lineitem last ms-2">
          <template v-if="projectActive && projectActive.id === project.id">
            <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeProject');"></i>
          </template>
          <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setDraft(project)"></i></template>
        </div>
      </div>
    </div>


    <pagination list="projects" listName="projectList" filterName="projectsFilter"  />

  </div>

</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Edit from "@/routing/core/Projects/Edit.vue";
import Details from "@/routing/core/Projects/Details.vue";
import Headline from "@/routing/core/Projects/Layouts/Headline.vue";
import Sort from "@/routing/core/Projects/Layouts/Sort.vue";
import Pagination from "@/filter/components/pagination.vue";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects.vue";

export default {
  name: "List",
  emits: ['refresh-project-list', 'new-project', 'project-active'],
  components: {Holzkraft, Pagination, Sort, Headline, Details, Edit},
  props: {

  },
  data() {
    return {
      newProjectItem: {

      },
      accessData: [],
      projects:[]

    }
  },
  computed: {
    ...mapGetters(["getEmployees",  "modalsList" , "projectList", "projectActive", "getPlugins"])
  },
  methods:{
    setProject(project) {
      this.$emit('new-project', project);
      this.$store.commit('openModal', 'project');
    },
    deleteProject(project) {
      this.$confirm('Soll der Eintrag wirklich gelöscht werden?', 'Projekt löschen', 'warning', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "project/" + project.id, {headers}).then((r) => {
          this.$emit('refresh-project-list');
        });
      });
    },
    toggleProject(id) {
      if (this.projectActive === id) {
        this.accessData = [];
        this.$emit('project-active', '');
      } else {
        this.getAccessData();
        this.$emit('project-active', id);
      }
    },
    setDraft(project) {
      this.$store.dispatch("setActiveProject", project);
      this.$router.push({name: 'ProjectDetails', params: {id: project.id}})

    },
    getAccessData() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/accessData", {headers}).then((r) => {
        this.accessData = r.data.result.data;
      });
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.projects.ordering.value = payload.type;
        this.$store.state.filters.projects.direction.value = payload.direction;
      } else {
        this.$store.state.filters.projects.ordering.value = payload.type
      }
      this.$store.dispatch("projectsFilter");
    },
  },
  created() {
    this.$store.dispatch("projectsFilter");
  },
  watch: {

  }
}
</script>

