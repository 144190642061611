<template>
  <Details v-if="aboActive && aboActive.id" :abonnement="aboActive"/>
  <div class="singleRequest" v-for="(abo,key) in abonnements" :key="abo.title">
    <div class="plugin-item">
      <Headline :abo="abo"  />
    </div>
  </div>

</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";
import Details from "@/routing/core/Subscriptions/Details.vue";
import Headline from "@/routing/core/Subscriptions/Layouts/Headline.vue";
import axios from "axios";

export default {
  name: 'LegalOverview',
  components: {Details, Headline},
  computed: {
    ...mapGetters(["getEmployees", "aboActive"]),
  },
  methods: {

    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    setAbonnement(abonnement) {
      this.activeAbonnement = abonnement;
      //this.$router.push({name: "AbonnementDetails", params: {id: product.id}});
    },
    getAbonnements() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "subscription/search?limit=0", {headers}).then((r) => {
        this.abonnements = r.data.result.data;
        this.loading = false;
      });
    }

  },
  data() {
    return {
      abonnements: [],
      activeAbonnement: null
    };
  },
  created() {
    this.getAbonnements();
  },
}
</script>
