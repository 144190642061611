
<template>
  <div class="draftLineWrap accountingKeys" :class="($store.state.activeItems && $store.state.activeItems.ConfigAccountingKeys && $store.state.activeItems.ConfigAccountingKeys.id === draft.id) ? 'active' : ''" >


    <div class="lineitem l-unit">
      {{ draft.key }}
    </div>

    <div class="lineitem l-title" @click="setConfig(draft)">
      {{ draft.title }}
    </div>


    <div class="lineitem l-number">
      {{ draft.budget }}
    </div>

    <div class="lineitem l-number">
      <template v-if="draft.einnahme ==='1'">
        <i class="fa-duotone fa-check"></i>
      </template>
      <template v-else>
        <i class="fa-duotone fa-times"></i>
      </template>
    </div>
    <div class="lineitem l-number">
      <template v-if="draft.type ==='aufwand'">
        <span class="badge" style="background: #b21f28;">{{ draft.type}}</span>
      </template>
      <template v-else>
        <span class="badge badge-success">{{ draft.type}}</span>
      </template>

    </div>
    <div class="lineitem l-description ml-auto">
      {{ draft.description }}
    </div>
    <div class="lineitem last ml-auto ">
      <template v-if="$store.state.activeItems && $store.state.activeItems.ConfigAccountingKeys && $store.state.activeItems.ConfigAccountingKeys.id === draft.id">
      <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigAccountingKeys');"></i>
    </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["getEmployees"])
  },
  methods: {
    setConfig(draft) {
      const newData = {data: draft, type:'ConfigAccountingKeys'};
      this.$store.dispatch("setActiveConfig",  newData).then(()=> {
      this.$router.push({name: 'ConfigAccountingKeysDetails', params: {id: draft.id}})
        // scroll to top
        $("html, body").animate({scrollTop: 0});
    });
    },
  }
}
</script>
