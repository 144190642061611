<template>
  <CodeEditor v-model="content" width="100%" height="500" :wrap="true"
              :languages="effectiveLanguages"> </CodeEditor>
</template>
<script>
import CodeEditor from 'simple-code-editor';

export default {
  name: 'EditorHtml',
  props: {
    value: String,
    languages: Array
  },
  data() {
    return {
      content: this.value,
      languages_fallback: [
        ['html', 'HTML'],
        ['javascript', 'JS'],
        ['php', 'PHP'],
        ['css', 'CSS']
      ]
    }
  },
  components: {
    CodeEditor
  },
  computed: {
    effectiveLanguages() {
      return this.languages || this.languages_fallback;
    }
  },
   methods:{
     beforeHighlight() {
       // Query only within this component to avoid affecting other elements in the document
       const elements = this.$el.querySelectorAll('[data-highlighted]');
       elements.forEach(el => {
         el.removeAttribute('data-highlighted');
       });
     }
   },
  created() {
    if (this.languages === undefined) {
      this.languages = this.languages_fallback;
    }
  },
  watch: {
    content(newVal,oldVal) {
      if (newVal !== oldVal) {
        this.beforeHighlight();
        // You may also want to trigger the actual highlighting here,
        // if it's not automatically done by the editor component.
      }
      this.$emit('update:value', newVal);
    },
    value(newVal) {
      if (newVal !== this.content) {
        this.content = newVal;
      }
    }
  },
}
</script>
