<template>
    <ul class="navbar-nav">
      <li class="filter-item" v-for="filter in $store.state.filters.costnumbers">
        <component :is="'sketchFilter'+filter.type" :type="'accountingKeys'" :filter="filter" v-if="filter"/>
      </li>
    </ul>
</template>
<script>
import sketchFilterdatetime from "@/filter/components/datetime.vue";
import sketchFilterinput from "@/filter/components/textinput.vue";
import sketchFilterlist from "@/filter/components/list.vue";
import sketchFiltermultilist from "@/filter/components/multilist.vue";

export default {
  name: "FiltersCostNumbers",
  components: {
    sketchFilterlist, sketchFiltermultilist, sketchFilterinput, sketchFilterdatetime
  }
};
</script>
