<template>
  <div class="documentLineWrap" style="display: block"
       :class="(document.prepared && document.prepared.typeString) ? document.prepared.typeString : ''">
    <div class="lineitems item-row" style="display: flex">
      <div class="lineitem first l-checkbox">
        <div class="checkbox">
          <input type="checkbox"
                 :id="'id_'+document.id"
                 :value="document.id"
                 v-if="documentList && documentList.selected"
                 v-model="documentList.selected[document.id]"/>
          <label :for="'id_'+document.id"></label>
        </div>
      </div>
      <div class="lineitem l-number" @click="$router.push({name: 'DocumentsDetails', params: {id: document.id}})">
        <template v-if="document.doc_number">
          {{ document.doc_number }}
        </template>
        <template v-else>{{
            document.category
          }}
        </template>
      </div>
      <div class="lineitem l-title"
           @click="$router.push({name: 'DocumentsDetails', params: {id: document.id}})">
        <span class="docinfo">{{ document.info_field_1 }}</span>
        <span class="text-xs documentInfo" v-if="!$store.state.filters.documents.category.value">
        {{ document.category }}
          <template v-if="document.invoice_type === '2'"><span class="sepaMandat" tooltip="SEPA" position="right"><i
            class="fa-wallet fa-duotone"></i></span></template>
      </span>
        <span class="text-xs documentInfo"
              v-if="document && document.prepared.typeString === 'contract' && document.contractDetails">
        <span v-if="document.contractDetails.contract_state" position="right"
              :tooltip="$t('contract.'+document.contractDetails.contract_state)">
        <template v-if="document.contractDetails.contract_state === 'accepted'">
          <i class="fa-duotone fa-check-circle" style="color: #3B8130;"></i>
        </template>
        <template v-if="document.contractDetails.contract_state === 'terminated'">
          <i class="fa-duotone fa-xmark-circle" style="color: #b21f28;"></i>
        </template>
        <template v-if="document.contractDetails.contract_state === 'waiting'">
          <i class="fa-duotone fa-question-circle" style="color: #fd7e14;"></i>
        </template>
        <template v-if="document.contractDetails.contract_state === 'died'">
          <i class="fa-duotone fa-tombstone" style="color: #333;"></i>
        </template>
          </span>
         <template v-if="document.contractDetails.next_invoice_sum"><span position="right"
                                                                          tooltip="Vertragssumme">&nbsp;{{
             $filters.priceEuro(document.contractDetails.next_invoice_sum)
           }}</span></template>
          <template
            v-if="document.contractDetails.next_invoice_date !== '0000-00-00' && document.contractDetails.contract_state !== 'terminated'"><span
            tooltip="Nächste Abrechnung" position="right">
            | {{ $filters.formatDate(document.contractDetails.next_invoice_date) }}</span></template>
        <template
          v-if="document.contractDetails.contract_invoice_period"><span tooltip="Turnus" position="right"> | {{
            $t('contract.' + document.contractDetails.contract_invoice_period)
          }}</span></template>
  <template
    v-if="document.contractDetails.contract_runtime_months"><span tooltip="Laufzeit" position="right"> | {{
      document.contractDetails.contract_runtime_months
    }} Monate</span></template>
<template
  v-if="document.contractDetails.next_termination_date !== '0000-00-00' && document.contractDetails.contract_termination_date === '0000-00-00'"><span
  tooltip="Nächster Kündigungszeitpunkt"> |
  {{ $filters.formatDate(document.contractDetails.next_termination_date) }}</span>
</template>


      <span class="infoitem"
            v-if="document.contractDetails.contract_state === 'died' || document.contractDetails.contract_state === 'terminated'">
        <span tooltip="Kündigungsdatum"> | {{
            $filters.formatDate(document.contractDetails.contract_termination_date)
          }}</span>
        <span v-if="document.contractDetails.contract_end"
              tooltip="Vertragsende"> | {{ $filters.formatDate(document.contractDetails.contract_end) }}</span>
      </span>
      </span>
      </div>
      <div class="lineitem l-company" @click="setCustomer(document.c_id)">
        <template v-if="document.prepared && document.prepared.address">
          <template v-if="document.prepared.address.c_company">
            <span class="docinfo">{{ document.prepared.address.c_company }}</span>
            <span class="text-xs documentInfo"
                  v-if="document.prepared.address.c_title || document.prepared.address.c_firstname || document.prepared.address.c_lastname">
          <template v-if="document.prepared.address.c_title">{{ document.prepared.address.c_title }}&nbsp;</template>
          <template v-if="document.prepared.address.c_firstname">{{
              document.prepared.address.c_firstname
            }}&nbsp;</template>
          <template v-if="document.prepared.address.c_lastname">{{ document.prepared.address.c_lastname }}</template>
        </span>
          </template>
          <template v-else>
          <span class="docinfo">
          <template v-if="document.prepared.address.c_title">{{ document.prepared.address.c_title }}&nbsp;</template>
          <template v-if="document.prepared.address.c_firstname">{{
              document.prepared.address.c_firstname
            }}&nbsp;
          </template>
          <template v-if="document.prepared.address.c_lastname">{{
              document.prepared.address.c_lastname
            }}</template></span>
          </template>
        </template>
      </div>
      <div class="lineitem l-date">
        <template v-if="document.doc_d_date">
          {{ $filters.formatDate(document.doc_d_date) }}
        </template>
      </div>
      <div class="lineitem l-netto"
           v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.document_brutto_netto !== 'brutto'">
        <template v-if="document.complete_netto">
          <template v-if="document.prepared && document.prepared.isStorno"><span
            :tooltip="'Storniert: '+document.prepared.stornoText" position="left"><i
            class="fa-duotone fa-rectangle-xmark"></i></span> <span
            style="text-decoration: line-through; color: #A80000;">{{
              $filters.priceEuro(document.complete_netto)
            }}</span></template>
          <template v-else>{{ $filters.priceEuro(document.complete_netto) }}</template>
        </template>
      </div>
      <div class="lineitem l-brutto" v-else>
        <template v-if="document.complete_brutto">
          <template v-if="document.prepared && document.prepared.isStorno"><span
            :tooltip="'Storniert: '+document.prepared.stornoText" position="left"><i
            class="fa-duotone fa-rectangle-xmark"></i></span> <span
            style="text-decoration: line-through; color: #A80000;">{{
              $filters.priceEuro(document.complete_brutto)
            }}</span></template>
          <template v-else>
            {{ $filters.priceEuro(document.complete_brutto) }}
          </template>
        </template>
      </div>
      <div class="lineitem actions" v-if="document && document.actions">
        <div class="btn-group me-2"
             v-if="document.actions.copy || document.actions.archived || document.actions.createOrder || document.actions.createInvoice ||document.actions.createDeliveryNote || document.actions.createInvoiceOrder || document.actions.createInvoiceFinal || document.actions.createContract || document.actions.cancellation">
        <span class="iconAction btn btn-sm btn-outline-primary" @click="putAction('copy')" v-if="document.actions.copy"
              tooltip="Dokument kopieren">
      <i :class="[document.actions.copy ? 'fas' : 'far', 'fa-copy fa-duotone']"></i>
    </span>
          <span class="iconAction btn btn-sm btn-outline-primary" @click="putAction('makeActive')"
                v-if="document.active_mode === 'prepare'"
                tooltip="Dokument aktivieren">
      <i :class="[document.actions.copy ? 'fas' : 'far', 'fa-fill fa-duotone']"></i>
    </span>
          <span class="iconAction btn btn-sm btn-outline-primary" @click="orderConfirmation = true"
                v-if="document.actions.createOrder"
                tooltip="Auftragsbestätigung erstellen">
      <i :class="[document.actions.createOrder ? 'fas' : 'far', 'fa-file fa-duotone']"></i>
    </span>
          <span class="iconAction btn btn-sm btn-outline-primary" @click="putAction('createInvoice')"
                v-if="document.actions.createInvoice"
                tooltip="Rechnung erstellen">
      <i :class="[document.actions.createInvoice ? 'fas' : 'far', 'fa-money-check-dollar-pen fa-duotone']"></i>
    </span>
          <!--        <span class="iconAction  btn btn-sm btn-outline-primary" @click="putAction('createDeliveryNote')"-->
          <!--              v-if="document.actions.createDeliveryNote" tooltip="Lieferschein erstellen">-->
          <!--      <i :class="[document.actions.createDeliveryNote ? 'fas' : 'far', 'fa-truck fa-duotone']"></i>-->
          <!--    </span>-->
          <span class="iconAction btn btn-sm btn-outline-primary" @click="putAction('createInvoiceOrder')"
                v-if="document.actions.createInvoiceOrder" tooltip="Abschlagsrechnung">
      <i :class="[document.actions.createInvoiceOrder ? 'fas' : 'far', 'fa-chart-pie-simple fa-duotone']"></i>
    </span>
          <span class="iconAction btn btn-sm btn-outline-primary" @click="putAction('createInvoiceFinal')"
                v-if="document.actions.createInvoiceFinal" tooltip="Abschlussrechnung erstellen">
      <i :class="[document.actions.createInvoiceFinal ? 'fas' : 'far', 'fa-file-check fa-duotone']"></i>
    </span>
          <span class="iconAction btn btn-sm btn-outline-primary" @click="putAction('createContract')"
                v-if="document.actions.createContract" tooltip="Vertrag erstellen">
      <i :class="[document.actions.createContract ? 'fas' : 'far', 'fa-file-signature fa-duotone']"></i>
        </span>
        </div>
      </div>
      <div class="lineitem l-actions ml-auto">
        <template v-if="document && document.active_mode !== 'prepare'">
          <div class="btn-group me-2" v-if="document.actions">
        <span class="iconAction btn btn-sm btn-outline-success" @click="putAction('archived')"
              v-if="document.actions.archived && document.doc_state !== '3'"
              tooltip="Als erledigt markieren">
      <i :class="[document.actions.archived ? 'fas' : 'far', 'fa-box-check fa-duotone']"></i>
    </span>
            <span class="iconAction btn btn-sm btn-outline-danger" @click="prepareStorno(document)"
                  v-if="document.actions.cancellation" tooltip="Stornieren">
      <i :class="[document.actions.cancellation ? 'fas' : 'far', 'fa-ban fa-duotone']"></i>
    </span>
            <span class="iconAction  btn btn-sm btn-outline-danger" @click="putAction('markAsSent')"
                  v-if="document.actions.markAsSent" tooltip="Als versendet markieren">
      <i :class="[document.actions.markAsSent ? 'fas' : 'far', 'fa-envelope-circle-check fa-duotone']"></i>
    </span>
            <span class="iconAction  btn btn-sm btn-outline-danger" @click="prepareSend(document)"
                  v-if="document.actions.sendDirectly" tooltip="Direkt verschicken">
      <i :class="[document.actions.sendDirectly ? 'fas' : 'far', 'fa-arrow-right-from-arc fa-duotone']"></i>
    </span>
            <span class="iconAction  btn btn-sm btn-outline-danger" @click="putAction('contractToInvoice')"
                  v-if="document.prepared.typeString ==='contract'" tooltip="Nächsten Zeitraum verlängern">
      <i :class="[document.prepared.typeString ==='contract' ? 'fas' : 'far', 'fa-rotate-right fa-duotone']"></i>
    </span>
            <span class="iconAction  btn btn-sm btn-outline-success" @click="previewAction('preview')"
                  v-if="document.actions.preview" tooltip="PDF zum Druck">
      <i :class="[document.actions.preview ? 'fas' : 'far', 'fa-print fa-duotone']"></i>
    </span>
            <span class="iconAction  btn btn-sm btn-outline-success" @click="previewAction('previewMail')"
                  v-if="document.actions.previewMail"
                  tooltip="PDF für E-Mail">
      <i :class="[document.actions.previewMail ? 'fas' : 'far', 'fa-file-pdf fa-duotone']"></i>
    </span>
          </div>
        </template>
        <template v-else>
          <div class="btn-group me-2" v-if="document.actions">

        <span class="iconAction  btn btn-sm btn-outline-success" @click="previewAction('preview')"
              v-if="document.actions.preview" tooltip="PDF zum Druck">
      <i :class="[document.actions.preview ? 'fas' : 'far', 'fa-print fa-duotone']"></i>
    </span>
            <span class="iconAction  btn btn-sm btn-outline-success" @click="previewAction('previewMail')"
                  v-if="document.actions.previewMail"
                  tooltip="PDF für E-Mail">
      <i :class="[document.actions.previewMail ? 'fas' : 'far', 'fa-file-pdf fa-duotone']"></i>
    </span>
          </div>
        </template>
      </div>
      <div class="lineitem l-actions purple-actions">
        <div class="btn-group" v-if="document.actions">
          <span class="iconAction btn btn-sm btn-outline-secondary"
                @click="createIssues(document.id)"
                v-if="document.actions.issues"
                tooltip="Aufgaben erstellen">
      <i :class="[document.actions.issues ? 'fas' : 'far', 'fa-duotone fa-clipboard-list-check']"></i>
          </span>
          <span class="iconAction btn btn-sm btn-outline-secondary"
                @click="getNotices();addDocNotice = true; notice.document_id = document.id"
                v-if="document.actions.notice"
                tooltip="Notiz erstellen">
      <i :class="[document.actions.notice ? 'fas' : 'far', 'fa-note-sticky fa-duotone']"></i><span v-if="document._countNotices > 0"> <span class="incNumber">{{
              document._countNotices
            }}</span></span>
    </span>
          <span class="iconAction btn btn-sm btn-outline-secondary"
                @click="showProcess(document.process_id);"
                tooltip="Prozess anzeigen">
      <i :class="'fa-timeline-arrow fa-duotone'"></i>
    </span>
        </div>
      </div>
      <div class="lineitem l-avatar" v-if="!$store.state.filters.documents.owner.value"><img
        :src="$filters.employeeIcon(getEmployees, document.d_created_author, 'u_id')"
        class="sketchAvatar"></div>
      <div class="lineitem last  ">
        <template v-if="documentActive && documentActive.id === document.id">
          <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeDocument')"></i>
        </template>
        <template v-else><i class="fa-duotone fa-chevrons-up"
                            @click="$router.push({name: 'DocumentsDetails', params: {id: document.id}})"></i></template>
      </div>
    </div>
  </div>
  <modal v-if="modalContent">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="card">
          <div class="card-body">
            <button class="btn btn-close float-end" @click="modalContent = false; notice.document_id = null;"></button>
            <iframe :src="modalContent" type="" style="min-height: 80vh; width: 100%;"/>
          </div>
        </div>
      </div>
    </div>
  </modal>
  <modal v-if="addDocNotice">
    <div class="modal fade show" tabindex="-1"
         style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("globals.newNote") }}
            </h5>
            <button type="button" style="margin-left: auto;" class="btn btn-outline-primary" @click="postNotice()"><i
              class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
            </button>
            <button type="button" class="btn-close ms-3"
                    @click="addDocNotice = false; notice.document_id = null;"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>{{ $t("globals.note") }}</label>
              <Editor2 v-model="notice.notice_message"/>
            </div>
            <template v-if="notices && notices.length > 0">
              <div class="noticesContainer">
                <template v-for="n in notices">
                  <div class="noticeItem" v-if="n.notice_type ==='document' && n.affect_id === document.id">
                    <div class="noticeHeader mb-2">
                      <img :src="$filters.employeeIcon(getEmployees, n.notice_author, 'u_id')" class="sketchAvatar me-2">
                      <span class="noticeDate"><small>{{ $filters.formatDateTime(n.notice_date) }}</small></span>
                    </div>
                    <div class="noticeMessage" v-html="n.notice_message"></div>
                    <hr>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </modal>
  <modal v-if="orderConfirmation">
    <div class="modal fade show" tabindex="-1"
         style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("globals.newNote") }}
            </h5>
            <button type="button" style="margin-left: auto;" class="btn btn-outline-primary " @click="saveConfirmOrder">
              <i
                class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
            </button>
            <button type="button" class="btn-close ms-3"
                    @click="orderConfirmation = false; orderConfirmationText = '';"></button>
          </div>
          <div class="modal-body">
            <p>Bitte den rechtsverbindliche Quelle des Auftrags angeben, z.B. Annahme per E-Mail vom 01.01.2023 durch
              Max
              Mustermann</p>
            <Editor2 v-model="orderConfirmationText"/>
          </div>
        </div>
      </div>
    </div>
  </modal>
  <modal v-if="sendContent">
    <div class="modal fade show" tabindex="-1"
         style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Per E-Mail versenden
            </h5>
            <button type="button" style="margin-left: auto;" class="btn btn-outline-primary " @click="saveContentText">
              <i
                class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.send") }}
            </button>
            <button type="button" class="btn-close ms-3" @click="sendContent = false; sendContentText = '';"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Betreff</label>
              <input class="form-control" type="text" v-model="sendContentTitle">
            </div>
            <div class="form-group">
              <label>E-Mail Text</label>
              <Editor2 v-model="sendContentText"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
  <modal v-if="orderCancelation">
    <div class="modal fade show" tabindex="-1"
         style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Stornieren
            </h5>
            <button type="button" style="margin-left: auto;" class="btn btn-outline-primary "
                    @click="saveContentCancel"><i
              class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
            </button>
            <button type="button" class="btn-close ms-3"
                    @click="orderCancelation = false; sendContentText = '';"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Betreff</label>
              <input class="form-control" type="text" v-model="storno.title">
            </div>
            <div class="form-group">
              <label>E-Mail Text</label>
              <Editor2 v-model="storno.message"/>
            </div>
            <div class="form-group">
              <label>Grund</label>
              <Editor2 v-model="storno.reason"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
  <modal v-if="modalCustomerEdit">
    <div class="modal fade show" tabindex="-1"
         style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <EditCustomer comefrom="documents" @close="modalCustomerEdit = false;"/>
        </div>
      </div>
    </div>
  </modal>
  <modal v-if="currentProcess">
    <div class="modal fade show" tabindex="-1"
         style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            Prozess
            <button class="btn btn-close" @click="currentProcess = null"></button>
          </div>
          <div class="container">
            <table class="table table-hover table-sm processTable">
              <tbody>
              <tr v-for="(type, doc) in currentProcess.invoices">
                <td class="p-0">
                  <table class="mb-0">
                    <tbody>
                    <tr v-for="p in type.items" :class="doc">
                      <td style="width: 150px;"><span class="text-xs">{{ p.info_field_0 }}</span></td>
                      <td style="width: 150px;"><span class="text-xs"><span class="btn btn-sm btn-outline-primary"
                                                                            @click="$router.push('/documents/'+p.id);currentProcess = null;"><i
                        class="fa-duotone fa-file"></i> {{ p.doc_number }}</span></span></td>
                      <td style="width: 350px;">{{ p.info_field_1 }}</td>
                      <td>{{ p.completeFormatted }}</td>
                      <td>{{ $filters.formatDate(p.doc_d_date) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </td>
                <td class="text-end" style="width: 100px;">{{ $filters.priceEuro(type.sum) }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td class="text-end">Offener Betrag:</td>
                <td class="text-end"> {{ $filters.priceEuro(currentProcess.openSum) }}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import EditCustomer from "@/routing/core/Customers/Edit.vue";
import $ from "jquery";
import getDocumentElement from "@popperjs/core/lib/dom-utils/getDocumentElement";

export default {
  name: "Headline",
  data() {
    return {
      currentProcess: null,
      modalContent: null,
      addDocNotice: false,
      orderConfirmation: false,
      orderCancelation: false,
      sendContent: false,
      sendContentTitle: '',
      sendContentText: '',
      orderConfirmationText: '',
      toast: useToast(),
      address: {},
      modalCustomerEdit: false,
      notice: {
        document_id: null,
        notice_message: ''
      },
      storno: {
        title: '',
        message: '',
        reason: ''
      },
      notices: [],
    }
  },
  props: {
    document: Object,
  },
  components: {
    Editor2, EditCustomer
  },
  computed: {
    ...mapGetters(["documentActive", "getEmployees", "documentList", "getConfigs"]),
  },
  methods: {
    createIssues(document_id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.getters.getApiUrl + "document/" + document_id + "/issues", {}, {headers}).then((r) => {
        this.toast.success('Aufgaben wurden erfolgreich erstellt');
      }).catch((e) => {
        this.toast.error(e.response.data.result.message);
      });
    },
    getDocumentElement,
    showProcess(process_id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "process/" + process_id, {headers}).then((r) => {
        this.currentProcess = r.data.result.data;
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    setCustomer(customer_id) {
      let VueInner = this;
      this.activeCustomer = customer_id;
      this.$store.dispatch("setActiveCustomer", customer_id).then(function () {
        VueInner.modalCustomerEdit = true;
        VueInner.scrollToTop();
      });
    },
    prepareStorno(document) {
      this.orderCancelation = true;
      this.storno.title = document.prepared.custom_fields.document_sending.subject;
      this.storno.message = document.prepared.custom_fields.document_sending.body;
    },
    prepareSend(document) {
      this.sendContent = true;
      this.sendContentTitle = document.prepared.custom_fields.document_sending.subject
      this.sendContentText = document.prepared.custom_fields.document_sending.body
    },
    getNotices() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "customer/" + this.document.c_id + "/notices", {headers}).then((r) => {
        this.notices = r.data.result.data;
      }).catch((e) => {
        this.toast.error(e.response.data.result.message);
      });
    },
    saveContentCancel() {
      this.putAction('cancellation', {
        title: this.storno.title,
        message: this.storno.message,
        reason: this.storno.reason
      });
      this.orderCancelation = false;
    },
    saveContentText() {
      this.postAction('sendPDF', {title: this.sendContentTitle, message: this.sendContentText});
      this.sendContent = false;
    },
    saveConfirmOrder() {
      this.putAction('createOrder', {message: this.orderConfirmationText});
      this.orderConfirmation = false;
    },
    postAction(action, payload) {
      if (payload === undefined) {
        payload = {};
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.getters.getApiUrl + "documents/" + this.document.id + "/" + action, payload, {headers}).then((r) => {
        this.$store.dispatch("documentsFilter");
        this.toast.success("Vorgang erfolgreich");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    putAction(action, payload) {
      if (payload === undefined) {
        payload = {};
      }
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.getters.getApiUrl + "documents/" + this.document.id + "/" + action, payload, {headers}).then((r) => {
        this.$store.dispatch("documentsFilter");
        this.toast.success("Vorgang erfolgreich");
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    previewAction(action) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "documents/" + this.document.id + "/" + action, {headers}).then((r) => {
        // open Modal with PDF Base64
        const base64Response = r.data.result.data; // Base64 encoded data
        const mime = "application/pdf"
        const byteCharacters = atob(base64Response);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, {type: mime});
        this.modalContent = URL.createObjectURL(blob); // Create a URL for the Blob
        this.loading = false;
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    makeClassSave(str) {
      if (str) {
        return str.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      }
    },
    postNotice() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {message: this.notice.notice_message}
      axios.post(this.$store.state.apiUrl + "document/" + this.notice.document_id + "/notice/", postContent, {headers}).then((r) => {
        this.addDocNotice = false;
        this.notice.notice_message = '';
        this.notice.document_id = null;
        this.toast.success('Notiz wurde erfolgreich gespeichert');
      }).catch((e) => {
        this.toast.error(e.response.data.result.message);
      });
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0}, "slow");
    }
  },
}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}
</style>
